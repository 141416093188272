<template>
    <v-container v-if="paciente">
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-btn class="mx-2 float-left" fab small dark color="accent" @click="$router.go(-1)">
                <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto" md="12">
            <span class="primary--text display-2">DATOS DEL PACIENTE</span><br>
        </v-col>
        <v-col cols="12" md="12">
              <v-card class="md-12">
                  <v-col cols="12" sm="12" md="12" v-if="paciente">
                      <v-row no-gutters>
                          <v-col cols="12" sm="12" md="4">
                              <label class="primary--text">NOMBRE: </label>
                              {{paciente.tblConfPersonasnaturales[0].copnNombre1 + ' ' + paciente.tblConfPersonasnaturales[0].copnNombre2 + ' ' + paciente.tblConfPersonasnaturales[0].copnApellido1 + ' ' + paciente.tblConfPersonasnaturales[0].copnApellido2}}
                          </v-col>
                          <v-col cols="12" sm="12" md="4">
                              <label class="primary--text">IDENTIFICACION: </label>
                              {{(((paciente.tipoidentificacion)?paciente.tipoidentificacion.comaNombrecorto:'') +' - '+ paciente.copeIdentificacion)}}
                          </v-col>
                          <v-col cols="12" sm="12" md="4" v-if="paciente.coppFechanacimiento">
                              <label class="primary--text">EDAD: </label>
                              {{ calcularEdad(paciente.coppFechanacimiento)}}
                          </v-col>
                          <v-col cols="12" sm="12" md="4">
                              <label class="primary--text">PAIS: </label>
                              {{((paciente.pais)?paciente.pais.comaNombrelargo:'')}}
                          </v-col>
                          <v-col cols="12" sm="12" md="4">
                              <label class="primary--text">DEPARTAMENTO: </label>
                              {{((paciente.departamento)?paciente.departamento.comaNombrelargo:'')}}
                          </v-col>
                          <v-col cols="12" sm="12" md="4">
                              <label class="primary--text">MUNICIPIO: </label>
                              {{((paciente.municipio)?paciente.municipio.comaNombrelargo:'')}}
                          </v-col>
                          <v-col cols="12" sm="12" md="4">
                              <label class="primary--text">FECHA DE NACIMIENTO: </label>
                              {{paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento}}
                          </v-col>
                          <v-col cols="12" sm="12" md="4">
                              <label class="primary--text">DIRECCION: </label>
                              {{paciente.copeDireccion}}
                          </v-col>
                         
                          <v-col cols="12" sm="12" md="4">
                              <label class="primary--text">TELEFONO: </label>
                              {{paciente.copeTelefono}}
                          </v-col>
                      </v-row>
                  </v-col>
              </v-card>
          </v-col>
        <v-col cols="auto" md="12" v-if="$route.params.tipo == 'admision'">
            <Admisiones :copeId="parseInt($route.params.copeId)" :coppId="paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId" ></Admisiones>
        </v-col>
        <v-col cols="auto" md="12" v-if="$route.params.tipo == 'asistencial'">
           <Asistencial :copeIdentificacion="paciente.copeIdentificacion" :copeId="parseInt($route.params.copeId)" :coppId="paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId" ></Asistencial>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import Admisiones from '../citas/Admisiones.vue'
import Asistencial from '../asistencial/Asistencial.vue'
export default {
  components: {
      Admisiones,
      Asistencial
  },
  data() {
    return {
       paciente:null
    };
  },
  created: async function() {
    this.paciente =  await this.$apiService.index("sistema/personas/view/" + this.$route.params.copeId + "/pacientes").then(data => data);
  },
  methods: {
  }
};

</script>

<style>

</style>
