<style>

</style>

<template>
    <div>
        <v-container lighten-5>
            <v-data-iterator :items="lista" :page.sync="page" :items-per-page="10" item-key="citaId" single-expand hide-default-footer sort-desc sort-by="citaFecha" class="elevation-1">
                <template v-slot:header>
                    <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                    <v-toolbar dark color="primary darken-3" class="mb-1">
                        <v-toolbar-title class="white--text">Entregas externas</v-toolbar-title>
                        <v-btn class=" mx-2 primary--text" tile color="white" @click.stop="dialog = true">
                            agregar
                        </v-btn>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <div class="flex-grow-1"></div>
                    </v-toolbar>
                </template>
                <template v-slot:default="{ items, isExpanded, expand }">
                    <v-row align="center" justify="center">
                        <v-col v-for="item in items" :key="item.citaId" cols="12" sm="12" md="10" lg="10">
                            <v-card>
                                <v-card-title class="subtitle-1 font-weight-bold">
                                    <v-row no-gutters>

                                        <v-col cols="9" sm="9" md="9" lg="9">
                                            <v-row no-gutters>

                                                <v-col cols="12" sm="5" md="5" lg="5">
                                                    PACIENTE <span class="primary--text">
                                                        {{ item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1 + " " +item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1 }}
                                                    </span>
                                                </v-col>
                                                <v-col cols="12" sm="7" md="7" lg="7">
                                                    FECHA <span class="green--text"> {{item.citaFecha}}</span>
                                                </v-col>

                                                <v-col cols="12" sm="5" md="5" lg="5">
                                                    IDENT. <span class="primary--text">
                                                        {{item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}}

                                                    </span>
                                                </v-col>
                                                <v-col cols="12" sm="7" md="7" lg="7">
                                                    EDAD <span class="primary--text">{{ calcularEdad(item.tblConfPersonaspacientes[0].coppFechanacimiento) }}
                                                    </span>
                                                </v-col>
                                                <v-col cols="12" sm="5" md="5" lg="5">
                                                    ESTADO <template v-if="item.citaTipo == 4">
                                                        <span :class="((getEstado(item.tblCitaRecetarios)<=30 )?'red':(getEstado(item.tblCitaRecetarios)<=80 )?'yellow':(getEstado(item.tblCitaRecetarios)>80 )?'green':'grey')+'--text'">{{getEstado(item.tblCitaRecetarios)}} %</span>
                                                    </template>
                                                    <template v-else>
                                                        <span :class="((getEstado(item.tblCitaFacturaciones[0].tblCitaRecetarios)<=30 )?'red':(getEstado(item.tblCitaFacturaciones[0].tblCitaRecetarios)<=80 )?'yellow':(getEstado(item.tblCitaFacturaciones[0].tblCitaRecetarios)>80 )?'green':'grey')+'--text'">{{getEstado(item.tblCitaFacturaciones[0].tblCitaRecetarios)}} %</span>
                                                    </template>
                                                </v-col>

                                            </v-row>
                                        </v-col>
                                        <v-col cols="3" sm="3" md="3" lg="3">
                                            <v-tooltip top v-if="item.estado.comaNombrecorto != 'CANCELADA'">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn outlined color="success" class="mr-2" small v-on="on" :to="{ name: 'Entregafactura',params: { cita: item} }">
                                                        <v-icon>mdi-desktop-mac-dashboard</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Facturar</span>
                                            </v-tooltip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn outlined small color="error" v-on="on" @click="eliminar(item.citaId)">
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Eliminar</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text>
                                    <v-row no-gutters>

                                          <v-col cols="12" sm="12" md="3" lg="3">
                                        EPS <span class="primary--text">
                                            <template v-if="!item.cocoId && item.tblConfBasedatospacientes.length > 0 &&  item.tblConfBasedatospacientes[0].tblConfBasedato">
                                                {{ (item.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemPrincipal == 1)?'PARTICULAR':item.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemNombre }}
                                            </template>
                                            <template v-if="item.cocoId">
                                                {{ (item.tblConfContrato.tblConfPersonasempresas[0].coemPrincipal == 1)?'PARTICULAR':item.tblConfContrato.tblConfPersonasempresas[0].coemNombre }}
                                            </template>
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4" lg="4" v-if="item.tblConfPersonasempleados[0]">
                                        EMPLEADO
                                        <span class="primary--text"> {{ item.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + " " +item.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1 }} </span>
                                        <span class="">{{item.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}}</span>
                                    </v-col>

                                        <v-col cols="12" sm="12" md="12" lg="12">
                                            <v-row>
                                                <v-col cols="1" sm="1" md="1" lg="1">
                                                    <v-btn color="grey" icon outlined @click="() => expand(item, !isExpanded(item))">
                                                        <v-icon v-if="!isExpanded(item)">mdi-chevron-down</v-icon>
                                                        <v-icon v-else>mdi-chevron-up</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="11" sm="11" md="11" lg="11">
                                                    <span class="headline primary--text">MEDICAMENTOS</span>
                                                </v-col>
                                            </v-row>
                                 

                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-text v-if="isExpanded(item)">
                                    <template v-for="items in (item.citaTipo)?item.tblCitaRecetarios:item.tblCitaFacturaciones[0].tblCitaRecetarios ">

                                        <v-chip class="ma-2" :key="items.cireId" small outlined label>{{ 'Medicamento: '+items.tblMediMedicamento.mediNombre +' - ' + items.tblMediMedicamento.mediConcentracion + ' ' +items.tblMediMedicamento.unidad.comaNombrecorto + ' - Cada '+ items.cireFrecuenciahoras+' horas - Durante '+ items.cireDuraciondias+' dias - Cantidad: '+items.cireCantidadprescrita + ' - Entragados: ' + items.cireEntregado + ' - Pendientes: '+(items.cirePendiente) }}</v-chip>

                                    </template>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:no-data>
                    <v-row class="mt-2" align="center" justify="center">
                        <v-col cols="10" sm="10" md="10" lg="10">
                            <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                No se encontro nada.
                            </v-alert>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:footer>
                    <v-row class="mt-2 pb-5" align="center" justify="center">
                        <span class="mr-4 grey--text">
                            Pagina {{ page }} de {{ pageCount }}, Total resultados: {{(Count)}}
                        </span>
                        <v-pagination circle v-model="page" @input="buscar(filtros)" @next="buscar(filtros)" @previous="buscar(filtros)" :total-visible="10" :length="pageCount"></v-pagination>

                    </v-row>
                </template>
            </v-data-iterator>

            <v-form @submit.prevent="agregar">
                <v-dialog v-model="dialog" persistent width="1300px" max-width="1300px">
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-container>
                                <template v-if="form.coppId && tipo == 'CAPITADO'">
                                    <v-alert text prominent dense type="success" v-if="bd.length > 0">
                                        El paciente se encuentra en las base de datos de <template v-for="b in bd"> {{ ','+ b.cocoNombre  }}</template>
                                    </v-alert>
                                    <v-alert text prominent dense type="error" v-else>
                                        El paciente no se encuentra en ninguna base de datos para el mes de {{ meses[ new Date().getMonth()]}}.
                                    </v-alert>
                                </template>
                                <v-row>
                                    <v-col cols="12" sm="12" md="9">
                                        <v-autocomplete outlined :rules="nameRules" :error="$v.form.coppId.$error" v-model="form.coppId" :items="pacientes" :loading="isLoading" :search-input.sync="searchP" @keypress.enter="searchPaciente" @change="vaciarpaciente(null)" no-filter hide-no-data hide-selected item-text="tblConfPersonasnaturales[0].copnNombre1" item-value="tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId" item-color="primary" label="Paciente" clearable>
                                            <template v-slot:selection="data">
                                                {{data.item.tblConfPersonasnaturales[0].copnNombre1 + " "+data.item.tblConfPersonasnaturales[0].copnNombre2 + " "+data.item.tblConfPersonasnaturales[0].copnApellido1 + " "+data.item.tblConfPersonasnaturales[0].copnApellido2}}
                                            </template>
                                            <template v-slot:item="data">
                                                <template v-if="(typeof data.item !== 'object')">
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                </template>
                                                <template v-else>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.tblConfPersonasnaturales[0].copnNombre1 + ' '+data.item.tblConfPersonasnaturales[0].copnNombre2 + ' '+data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' +data.item.tblConfPersonasnaturales[0].copnApellido2"></v-list-item-title>
                                                        <v-list-item-subtitle v-html="data.item.copeIdentificacion">

                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="2">
                                        <v-switch v-model="tipo" true-value="EVENTO" false-value="CAPITADO" :label="tipo"></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" v-if="tipo=='EVENTO'">
                                        <v-autocomplete outlined v-model="form.cocoId" :items="contratos" item-text="cocoNombre" item-value="cocoId" item-color="primary" label="Contrato" clearable>
                                            <template v-slot:selection="data">
                                                {{data.item.cocoNombre +" - "+data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}
                                            </template>
                                            <template v-slot:item="data">
                                                <template v-if="(typeof data.item !== 'object')">
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                </template>
                                                <template v-else>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ data.item.cocoNombre}}</v-list-item-title>
                                                        <v-list-item-subtitle> {{data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4" v-else-if="bd.length > 0 && guardopaciente">
                                        <v-autocomplete outlined v-model="form.cbdpId" :items="bd" item-text="cocoNombre" item-value="cbdpId" item-color="primary" label="EPS contrada" clearable>
                                            <template v-slot:item="data">
                                                <template v-if="(typeof data.item !== 'object')">
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                </template>
                                                <template v-else>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{data.item.cocoNombre}}</v-list-item-title>
                                                        <v-list-item-subtitle> {{data.item.coemNombre}}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" v-if="form.coppId && guardopaciente">
                                        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field :rules="nameRules" required outlined :error="$v.form.citaFecha.$error" v-model="form.citaFecha" label="Fecha de la cita" prepend-icon="event" readonly v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker locale="ES"  v-model="form.citaFecha" @input="menu = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="3" v-if="form.coppId &&  guardopaciente">
                                        <v-autocomplete outlined :rules="nameRules" :error="$v.form.citaTipoEstado.$error" required v-model="form.citaTipoEstado" :items="tipoestado.filter(e=>e.comaNombrecorto == 'ACTIVA')" :input="tipoestado.find(e=>e.comaNombrecorto == 'ACTIVA').comaId" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Estado" clearable></v-autocomplete>
                                    </v-col>

                                </v-row>
                            </v-container>
                            <template v-if="datosVaciarPaciente && !guardopaciente">
                                <Createpaciente v-on:validar="vaciarpaciente($event)" v-bind:datosVaciarPaciente="datosVaciarPaciente" />
                            </template>
                            <v-container v-if="form.coppId &&  guardopaciente">
                                <v-row>
                                    <v-col cols="auto" md="12">
                                        <v-row justify="space-around">
                                            <v-col cols="auto" md="12">
                                                <v-toolbar flat>
                                                    <v-toolbar-title>Diagnosticos</v-toolbar-title>
                                                    <v-divider class="mx-4" inset vertical></v-divider>
                                                    <v-btn text @click="agregarDiagnostico">Agregar nuevo</v-btn>
                                                    <v-divider class="mx-4" inset vertical></v-divider>

                                                    <div class="flex-grow-1"></div>
                                                </v-toolbar>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <template v-for="(items,index) in formDiagnosticos">
                                            <v-row :key="index" v-if="formDiagnosticos.length > 0">
                                                <v-col cols="12" sm="12" md="12" v-if="items.tipocie10">
                                                    <v-autocomplete outlined required v-model="items.cidiTipoCie10" :loading="items.tipocie10.isLoading" :search-input.sync="items.tipocie10.search" hide-no-data hide-selected no-filter :items="items.tipocie10.tipocie10" @keypress.enter="searchCIE10(items.tipocie10)" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de CIE 10" clearable>
                                                        <template v-slot:selection="data">
                                                            <v-list-item-content>
                                                                <v-list-item-title v-html="data.item.comaNombrelargo"></v-list-item-title>
                                                                <v-list-item-subtitle v-html="data.item.comaNombrecorto"></v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </template>

                                                        <template v-slot:item="data">
                                                            <template v-if="(typeof data.item !== 'object')">
                                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                                            </template>
                                                            <template v-else>
                                                                <v-list-item-content>
                                                                    <v-list-item-title v-html="data.item.comaNombrelargo"></v-list-item-title>
                                                                    <v-list-item-subtitle v-html="data.item.comaNombrecorto"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="6">
                                                    <v-autocomplete outlined required v-model="items.cidiTipoDiagnostico" :items="tipodiagnostico" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de Diagnostico" clearable></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="4">
                                                    <v-autocomplete outlined required v-model="items.cidiTipoClase" :items="tipoclasedx" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de Clase" clearable></v-autocomplete>
                                                </v-col>
                                                <v-col cols="auto" md="1" v-if="index>0">
                                                    <v-btn text icon color="error" @click="eliminarDiagnostico(items)">
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="auto" md="12">
                                        <v-row justify="space-around">
                                            <v-col cols="auto" md="12">
                                                <v-toolbar flat>
                                                    <v-toolbar-title>Recetarios</v-toolbar-title>
                                                    <v-divider class="mx-4" inset vertical></v-divider>
                                                    <v-btn text @click="agregarRecetario">Agregar nuevo</v-btn>
                                                    <v-divider class="mx-4" inset vertical></v-divider>

                                                    <div class="flex-grow-1"></div>
                                                </v-toolbar>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <template v-for="(items,index) in formRecetarios">
                                            <v-row :key="items.cireId" v-if="formRecetarios.length > 0">
                                                <v-col cols="12" sm="12" md="3">
                                                    <v-text-field outlined required :error="$v.formRecetarios.$each[index].cireFrecuenciahoras.$error" @keyup="calcularCantidad(items)" v-model="items.cireFrecuenciahoras" label="Frecuencia de horas" number></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="3">
                                                    <v-text-field outlined required :error="$v.formRecetarios.$each[index].cireDuraciondias.$error" @keyup="calcularCantidad(items)" v-model="items.cireDuraciondias" label="Duracion en dias" number></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="3">
                                                    <v-text-field outlined required :error="$v.formRecetarios.$each[index].cireCantidadprescrita.$error" v-model="items.cireCantidadprescrita" label="Cantidad"></v-text-field>
                                                </v-col>

                                                <v-col cols="12" sm="12" md="12">
                                                    <v-autocomplete outlined required :error="$v.formRecetarios.$each[index].mediId.$error" v-model="items.mediId" :items="medicinas" item-text="mediNombre" item-value="mediId" item-color="primary" label="Medicamento" clearable>
                                                        <template v-slot:selection="data">
                                                            <v-list-item-content>
                                                                <v-list-item-title><span class="primary--text">{{data.item.mediNombre}}</span>
                                                                </v-list-item-title>
                                                                <v-list-item-title><span class="red--text">{{ 'STOCK: '+data.item.mediStock}}</span>
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle>
                                                                    Via {{ data.item.via.comaNombrelargo}},
                                                                    Concentracion {{ data.item.mediConcentracion}},
                                                                    Forma farmaceutica {{ data.item.mediFormafarmaceutica}}
                                                                    Presentacion {{data.item.presentacion.comaNombrelargo}} Unidad: {{data.item.unidad.comaNombrelargo}}
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template v-if="(typeof data.item !== 'object')">
                                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                                            </template>
                                                            <template v-else>
                                                                <v-list-item-content>
                                                                    <v-list-item-title><span class="primary--text">{{data.item.mediNombre}}</span>
                                                                    </v-list-item-title>
                                                                    <v-list-item-title><span class="red--text">{{ 'STOCK: '+data.item.mediStock}}</span>
                                                                    </v-list-item-title>
                                                                    <v-list-item-subtitle>
                                                                        Via {{ data.item.via.comaNombrelargo}},
                                                                        Concentracion {{ data.item.mediConcentracion}},
                                                                        Forma farmaceutica {{ data.item.mediFormafarmaceutica}}
                                                                        Presentacion {{data.item.presentacion.comaNombrelargo}} Unidad: {{data.item.unidad.comaNombrelargo}}
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="auto" md="1" v-if="index>0">
                                                    <v-btn text icon color="error" @click="eliminarRecetario(items)">
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-textarea outlined required v-model="items.cireDetalles" label="Detalles de la formula"></v-textarea>
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-col>
                                </v-row>
                            </v-container>

                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <div class="flex-grow-1"></div>
                            <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                            <v-btn color="primary darken-1" dark @click="agregar" v-if="form.coppId">Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-form>
        </v-container>

    </div>
</template>
<script>
import {
  required
}
from 'vuelidate/lib/validators'
export default {
  components: {
     Createpaciente: () => import("../sistema/pacientes/Create"),
  },
  data() {
    return {
      guardopaciente: false,
      menu: false,
      tab: "tab-1",
      tipo: 'CAPITADO',
      Count: 0,
      page: 1,
      pageCount: 0,
      dialog: false,
      isNew: true,
      copeId: null,
      cambio: null,
      isLoading: false,
      searchP: null,
      meses: ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"],
      bd: [],
      url: "cita/citas/",
      user: this.$cookies.get("user"),
      datosVaciarPaciente:null,
      nameRules: [v => !!v || "Campo requirido"],
      pacientes: [],
      tipoestado: [],
      paciente: null,
      cups: {
        search: null,
        isLoading: false,
        cups: []
      },
      form: {
        citaFecha: null,
        citaTipoEstado: null,
        citaTipo: 4,
        coppId: null,
        cbdpId: null,
        cocoId: null,
        cocuId: null,
      },
      formRecetarios: [{
        cireFrecuenciahoras: null,
        cireDetalles: null,
        cireDuraciondias: null,
        cireCantidadprescrita: null,
        mediId: null,
        cifaId: null,
      }],
      formDiagnosticos: [{
        cidiTipoCie10: null,
        cidiTipoDiagnostico: null,
        cidiTipoClase: null,
        cifaId: null,
        tipocie10: {
          search: null,
          isLoading: false,
          tipocie10: []
        }
      }],
      filtros: [{
          text: "Fecha",
          value: null,
          tipo: "fecha",
          menu: false,
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
        },
        {
          text: "Estado",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [],
          listatext: "comaNombrelargo",
          listavalue: "comaId"
        }, {
          text: "Paciente",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [],
          listatext: "tblConfPersonasnaturales[0].copnNombre1",
          listavalue: "tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId",
          buscar: 'paciente',
          isLoading: false,
          search: null,
          //filter: (item, queryText, itemText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
          default: true,
          defaultselect: (data) => {
            return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
          },
          defaultbody: (data) => {
            return {
              title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
              subtitle: data.item.copeIdentificacion
            }
          },
        },

      ],
      headers: [{
        text: "Fecha",
        value: "citaFecha"
      }, {
        text: "Estado entrega",
        value: "estado"
      }, {
        text: "Paciente",
        value: "coppId"
      }, {
        text: "Edad",
        value: "tblConfPersonaspacientes[0].coppFechanacimiento"
      }, {
        text: "Eps",
        value: "cbdpId"
      }, {
        text: "Acciones",
        value: "action",
        sortable: false
      }],
      lista: [],
      contratos: [],
      listaCopia: [],
      tipodiagnostico: [],
      tipoclasedx: [],
      medicinas: []
    };
  },
  validations: {
    formRecetarios: {
      $each: {
        cireFrecuenciahoras: {
          required,
        },

        cireDuraciondias: {
          required,
        },

        cireCantidadprescrita: {
          required,
        },
        mediId: {
          required,
        },
      }
    },
    form: {
      citaFecha: {
        required,
      },
      citaTipoEstado: {
        required,
      },
      coppId: {
        required,
      },

    },
  },
  computed: {
    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created: async function() {
    this.$store.commit('setCargaDatos', true);
    this.filtros[1].lista = this.tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_CITAS").then(data => data);
    await this.buscar(this.filtros);
    this.contratos = await this.$apiService.index("sistema/contratos/index").then(data => data);
    this.tipodiagnostico = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSDIAGNOSTICOS").then(data => data);
    this.tipoclasedx = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSCLASEDX").then(data => data);
    this.tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_CITAS").then(data => data);
    this.medicinas = await this.$apiService.index("sistema/medicinas/index").then(data => data);
    this.form.citaFecha = this.currentDate(new Date());
    this.form.citaTipoEstado = this.tipoestado.find(e => e.comaNombrecorto == 'ACTIVA').comaId;
    this.$store.commit('setCargaDatos', false);
  },

  methods: {
    getEstado(recetarios) {
      let porcentaje = 0;
      recetarios.forEach(recetario => {
        porcentaje = porcentaje + parseFloat(recetario.cireEstadoentrega);
      });
      return ((porcentaje / recetarios.length) * 100);
    },
    calcularEdad(fecha) {
      // Si la fecha es correcta, calculamos la edad

      var values = fecha.split("-");
      var dia = parseInt(values[2]);
      var mes = parseInt(values[1]);
      var ano = parseInt(values[0]);

      // cogemos los valores actuales

      var fecha_hoy = new Date();
      var ahora_ano = fecha_hoy.getFullYear();
      var ahora_mes = fecha_hoy.getMonth() + 1;
      var ahora_dia = fecha_hoy.getDate();

      // realizamos el calculo
      var edad = (ahora_ano + 1900) - ano;
      if (ahora_mes < mes) {
        edad--;
      }
      if ((mes == ahora_mes) && (ahora_dia < dia)) {
        edad--;
      }
      if (edad > 1900) {
        edad -= 1900;
      }

      // calculamos los meses
      var meses = 0;

      if (ahora_mes > mes && dia > ahora_dia)
        meses = ahora_mes - mes - 1;
      else if (ahora_mes > mes)
        meses = ahora_mes - mes
      if (ahora_mes < mes && dia < ahora_dia)
        meses = 12 - (mes - ahora_mes);
      else if (ahora_mes < mes)
        meses = 12 - (mes - ahora_mes + 1);
      if (ahora_mes == mes && dia > ahora_dia)
        meses = 11;

      // calculamos los dias
      var dias = 0;
      if (ahora_dia > dia)
        dias = ahora_dia - dia;
      if (ahora_dia < dia) {
        var ultimoDiaMes = new Date(ahora_ano, ahora_mes - 1, 0);
        dias = ultimoDiaMes.getDate() - (dia - ahora_dia);
      }
      return edad + " años, " + meses + " meses y " + dias + " días";
    },
    allowedDates: val => new Date(val.replace(/-+/g, '/')).getDay() != 0 && new Date(val.replace(/-+/g, '/')).getDay() != 6,
    currentDate(date) {
      const fecha = date;
      if (this.allowedDates(fecha.yyyymmdd())) {
        return fecha.yyyymmdd()
      } else {
        fecha.setDate(fecha.getDate() + 1);
        return this.currentDate(fecha);

      }
    },
    getPersona(coppId) {
      return this.pacientes.filter(e => e.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId == coppId)[0].copeId;
    },
    async searchCIE10(tipocie10) {
      tipocie10.isLoading = true;
      if (tipocie10.search.length > 0 && tipocie10.search.toString().replace(/ /g, "") != "") {
        tipocie10.tipocie10 = await this.$apiService.index(`sistema/maestra/buscar3/TBL_TIPOSCIE10?comaNombrecorto=${tipocie10.search}&comaNombrelargo=${tipocie10.search}`).then(data => data);
      }
      tipocie10.isLoading = false;
    },
    async searchPaciente() {
      this.isLoading = true;
      if (this.searchP.length > 0 && this.searchP.toString().replace(/ /g, "") != "") {
        this.$apiService.index("sistema/personas/buscar2/pacientes/" + this.searchP.toUpperCase()).then(data => {
          this.isLoading = false;
          this.pacientes = data;
          this.guardopaciente = false;
        });
      }

    },
    async cargarEps() {
      this.$apiService.index("cita/basedatospacientes/index/" + this.form.coppId + "/" + new Date().yyyymmdd()).then(data => {
        if (data.length > 0) {
          this.bd = data;
        } else {
          this.bd = [];
        }

      });
    },
    async vaciarpaciente(respuesta) {
      this.cambio = null;
      this.datosVaciarPaciente = null;
      if(respuesta == null){
        this.$nextTick().then(() => {
          if (this.form.coppId) {
            this.cambio = this.pacientes.filter(e => e.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId == this.form.coppId)[0].copeId;
            this.datosVaciarPaciente={};
            this.datosVaciarPaciente.copeId = this.cambio;
            this.datosVaciarPaciente.nuevo = false ;
          }
        });
      }else{
        this.guardopaciente = true;
      }
      if (this.form.coppId) {
        await this.cargarEps();
      }
    },
    getMaestra(id, maestra) {
      if (id != null) {

        if (maestra.length > 0) {
          var ma = maestra.filter(e => e.comaId == id);
          if (ma.length > 0) {
            var m = ma[0];
            return m.comaNombrelargo;
          }
        }
      }

    },
    async searchCita(searchP) {
      this.$store.commit('setCargaDatos', true);
      const data = await this.$apiService.index(`cita/citas/buscar/4?${searchP}tipo=MEDICINA`).then(data => data);
      if (data) {
        this.lista = this.listaCopia = data.rows;
        this.Count = data.count;
        this.pageCount = Math.ceil((data.count) / 10);
      }
      this.$store.commit('setCargaDatos', false);
    },
    async buscar(filtros) {
      this.filtros = filtros;
      this.lista = this.listaCopia;
      var search = `offset=${(this.page-1)*10}&`;

      if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
        search = search + `citaFecha=${this.filtros[0].value}&`;
      }

      if (this.filtros[1].value != null && this.filtros[1].value.toString().replace(/ /g, "") != "") {
        search = search + `citaTipoEstado=${this.filtros[1].value}&`;

      }
      if (this.filtros[2].value != null && this.filtros[2].value.toString().replace(/ /g, "") != "") {
        search = search + `coppId=${this.filtros[2].value}&`;

      }

      if (search != "") {
        await this.searchCita(search);
      }

    },
    calcularCantidad(items) {
      items.cireCantidadprescrita = (parseInt(items.cireDuraciondias) * 24) / parseInt(items.cireFrecuenciahoras);
    },
    async agregar() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        try {
          if (this.isNew) {
            let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
            if (data) {
              this.formRecetarios.forEach((d) => d.citaId = data.citaId);
              this.$apiService.create("cita/asistenciales/atender/create/recetario?tipo=CITA", this.formRecetarios).then(data => data);
              this.formDiagnosticos.forEach((d) => d.citaId = data.citaId);
              this.$apiService.create("cita/asistenciales/atender/create/diagnostico?tipo=CITA", this.formDiagnosticos).then(data => data);
              await this.buscar(this.filtros);
              // this.listaCopia = this.lista.push(data);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          } else {
            var id = this.form.citaId;
            let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);
            if (data) {
              this.formRecetarios.forEach((d) => d.cifaId = data.cifaId);
              this.$apiService.create("cita/asistenciales/atender/update/recetario/" + data.citaId + "?tipo=CITA", this.formRecetarios).then(data => data);
              this.formDiagnosticos.forEach((d) => d.cifaId = data.cifaId);
              this.$apiService.create("cita/asistenciales/atender/update/diagnostico/" + data.citaId + "?tipo=CITA", this.formDiagnosticos).then(data => data);
              this.lista = this.lista.filter(e => e.citaId != id);
              this.listaCopia = this.lista.push(data);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          }
          this.close();
        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
      }

    },
    agregarDiagnostico() {
      this.formDiagnosticos.push({
        cidiTipoCie10: null,
        cidiTipoDiagnostico: null,
        cidiTipoClase: null,
        citaId: null,
        tipocie10: {
          search: null,
          isLoading: false,
          tipocie10: []
        }
      });
    },
    eliminarDiagnostico(item) {
      this.formDiagnosticos = this.formDiagnosticos.filter(
        e =>
        this.formDiagnosticos.indexOf(e) !=
        this.formDiagnosticos.indexOf(item)
      );
    },
    agregarRecetario() {
      this.formRecetarios.push({
        cireFrecuenciahoras: null,
        cireDetalles: null,
        cireDuraciondias: null,
        cireCantidadprescrita: null,
        mediId: null,
        citaId: null,
      });
    },

    eliminarRecetario(item) {
      this.formRecetarios = this.formRecetarios.filter(
        e =>
        this.formRecetarios.indexOf(e) !=
        this.formRecetarios.indexOf(item)
      );
    },
    async cancelar(id) {

      try {

        let data = await this.$apiService.update(this.url + "cancelar/" + id + "/", null).then(data => data);

        if (data) {
          this.lista = this.lista.filter(e => e.citaId != id);
          this.listaCopia = this.lista.push(data);
          this.$swal.fire({
            title: "Completado!",
            text: "Cita cancelada",
            icon: "success",
            confirmButtonText: "Ok"
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async eliminar(id) {
      try {
        var result = await this.$swal.fire({
          title: "Estas seguro?",
          text: "No podras revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, eliminar!"
        }).then(result => result.value)
        if (result) {
          let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
          if (data) {
            this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/diagnostico?tipo=CITA");
            this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/recetario?tipo=CITA");
            this.listaCopia = this.lista = this.lista.filter(
              e => e.citaId != id
            );
            this.$swal.fire(
              "Eliminado!",
              "ha sido eliminado con exito.",
              "success"
            );
          }
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async editar(id) {
      try {
        this.form = await this.$apiService.index(this.url + "view/" + id).then(data => data);
        this.isNew = false;
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },

    limpiar() {
      this.form = {
        citaFecha: this.currentDate(new Date()),
        cbdpId: null,
        citaTipoEstado: (this.tipoestado.find(e => e.comaNombrecorto == 'ACTIVA')) ? this.tipoestado.find(e => e.comaNombrecorto == 'ACTIVA').comaId : null,
        citaTipo: 4,
        coppId: null,
        cocoId: null,
      };
      this.formDiagnosticos = [{
        cidiTipoCie10: null,
        cidiTipoDiagnostico: null,
        cidiTipoClase: null,
        cifaId: null,
        tipocie10: {
          search: null,
          isLoading: false,
          tipocie10: []
        }
      }];
      this.formRecetarios = [{
        cireFrecuenciahoras: null,
        cireDetalles: null,
        cireDuraciondias: null,
        cireCantidadprescrita: null,
        mediId: null,
        cifaId: null,

      }]
    },
    close() {
      this.cambio = null;
      this.guardopaciente = false;
      this.isNew = true;
      this.dialog = false;
      this.limpiar();
    }
  }
};

</script>
