<style>

</style>

<template>
<div>
    <v-container lighten-5>
        <v-data-iterator :items="lista" :page.sync="page" :items-per-page="10" hide-default-footer sort-desc sort-by="cifaFecha" class="elevation-1">
            <template v-slot:header>
                <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                <v-toolbar dark color="primary darken-3" class="mb-1">
                    <v-toolbar-title class="white--text">Procedimientos asignados</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <div class="flex-grow-1"></div>
                </v-toolbar>

            </template>

            <template v-slot:default="props">
                <v-row align="center" justify="center">
                    <v-col v-for="item in props.items" :key="item.citaId" cols="12" sm="12" md="10" lg="10">
                        <v-card>
                            <v-card-title class="subtitle-1 font-weight-bold">
                                <v-row no-gutters>

                                    <v-col cols="9" sm="9" md="9" lg="9">
                                        <v-row no-gutters>

                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                PACIENTE <span class="primary--text">
                                                    {{ item.tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1 + " " +item.tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1 }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="7" md="7" lg="7">
                                                FECHA <span class="green--text"> {{item.cifaFecha}}</span>
                                            </v-col>

                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                IDENT. <span class="primary--text">
                                                    {{item.tblCitaCita.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}}

                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="7" md="7" lg="7">
                                                EDAD <span class="primary--text">{{ calcularEdad(item.tblCitaCita.tblConfPersonaspacientes[0].coppFechanacimiento)}}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                ESTADO <span :class="(item.cifaEstadoProc == 'TRAMITADO')?'green--text':'red--text'">{{item.cifaEstadoProc}}</span>

                                            </v-col>

                                        </v-row>
                                    </v-col>
                                    <v-col cols="3" sm="3" md="3" lg="3">
                                        <v-tooltip top v-if="item.cifaEstadoProc == 'TRAMITADO'">
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined :loading="pdfLoading" color="info" class="mr-2" v-on="on" small @click="pdf(item)">
                                                    <v-icon>mdi-file-pdf-box</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Generar pdf</span>
                                        </v-tooltip>
                                        <v-tooltip top v-if="item.estado.comaNombrecorto != 'ACTIVA' || user.role.indexOf('ADMIN') > -1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined color="primary" class="mr-2" small v-on="on" :to="{ name: 'Tramitarvacuna',params: { coppId:item.tblCitaCita.coppId,id: item.cifaId,servicio:item.tblCitaCita.tblConfCup.cocuId,titulo:item.tblCitaCita.tblConfCup.cocuNombre} }">
                                                    <v-icon>mdi-ballot-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Tramitar procedimiento</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn small outlined color="error" v-on="on" @click="eliminar(item.cifaId)">
                                                    <v-icon>delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row no-gutters>
                                    <!-- <v-col cols="12" sm="12" md="3" lg="3">
                                                EPS <span class="primary--text">
                                                    <template v-if="item.tblConfBasedatospacientes.length > 0">
                                                        {{ item.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemNombre }}
                                                    </template>
                                                    <template v-else-if="item.cocoId">
                                                        {{ item.tblConfContrato.tblConfPersonasempresas[0].coemNombre }}
                                                    </template>
                                                </span>
                                            </v-col> -->
                                    <v-col cols="12" sm="12" md="4" lg="4" v-if="item.tblCitaCita.tblConfPersonasempleados[0]">
                                        EMPLEADO
                                        <span class="primary--text"> {{ item.tblCitaCita.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + " " +item.tblCitaCita.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1 }} </span>
                                        <span class="">{{item.tblCitaCita.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}}</span>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="5" lg="5" v-if="item.tblCitaCita.tblConfCup">
                                        CUPS <span class="primary--text"> {{ item.tblCitaCita.tblConfCup.cocuNombre}}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
                <v-row class="mt-2" align="center" justify="center">
                    <v-col cols="10" sm="10" md="10" lg="10">
                        <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                            No se encontro nada.
                        </v-alert>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:footer>
                <v-row class="mt-2 pb-5" align="center" justify="center">
                    <span class="mr-4 grey--text">
                        Pagina {{ page }} de {{ pageCount }}, Total resultados: {{(Count)}}
                    </span>
                    <v-pagination circle v-model="page" @input="buscar(filtros)" @next="buscar(filtros)" @previous="buscar(filtros)" :total-visible="10" :length="pageCount"></v-pagination>

                </v-row>
            </template>
        </v-data-iterator>
    </v-container>
</div>
</template>

<script>
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import {
    documentos
}
from "../../utils/documentos";
import generales from '../../mixins/generales.js';
export default {
    mixins: [generales],
    data() {
        return {
            singleExpand: false,
            pdfLoading: false,
            url: "cita/facturaciones/",
            filtros: [{
                    text: "Fecha",
                    value: null,
                    tipo: "fecha",
                    menu: false,
                    searchNormal: true,
                    isLoading: false,
                    /*si es tipo lista descomentar esta parte*/
                    /* lista: [],
                    listatext: "nombre del campo que se mostrara",
                    listavalue: "nombre del valor que se mandara"*/
                 },
                // {
                //     text: "Paciente",
                //     value: null,
                //     tipo: "lista",
                //     /*si es tipo lista descomentar esta parte*/
                //     lista: [],
                //     listatext: "tblConfPersonasnaturales[0].copnNombre1",
                //     listavalue: "tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId",
                //     buscar: 'paciente',
                //     isLoading: false,
                //     search: null,
                //     //filter: (item, queryText, itemText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
                //     default: true,
                //     defaultselect: (data) => {
                //         return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
                //     },
                //     defaultbody: (data) => {
                //         return {
                //             title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
                //             subtitle: data.item.copeIdentificacion
                //         }
                //     },
                // },

                // {
                //     text: "Empleado",
                //     value: null,
                //     tipo: "lista",
                //     /*si es tipo lista descomentar esta parte*/
                //     searchNormal: true,
                //     isLoading: false,
                //     lista: [],
                //     listatext: "tblConfPersonasnaturales[0].copnNombre1",
                //     listavalue: "tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId",
                //     filter: (item, queryText, itemText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
                //     default: true,
                //     defaultselect: (data) => {
                //         return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
                //     },
                //     defaultbody: (data) => {
                //         return {
                //             title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
                //             subtitle: data.item.copeIdentificacion
                //         }
                //     },
                // },
            ],
            secciones: [],
            campos: [],
            diagnosticos: [],
            Historia: [],
            historial: [],
            empleados: [],
            listasParametros: []
        };
    },

    async created() {
        this.$store.commit('setCargaDatos', true);
        if (this.$route.params.coppId) {
            await this.buscar(this.filtros);
            //this.filtros[2].lista = this.empleados = await this.$apiService.index("sistema/personas/index/empleados").then(data => data);
        }
        this.$store.commit('setCargaDatos', false);
    },

    methods: {
        async search(searchP) {
            this.$store.commit('setCargaDatos', true);
            const data = await this.$apiService.index(`cita/facturaciones/proceso/?${searchP}`).then(data => data);
            if (data) {
                this.lista = this.listaCopia = data.rows;
                this.Count = data.count;
                this.pageCount = Math.ceil((data.count) / 10);
            }
            this.$store.commit('setCargaDatos', false);
        },

        async buscar(filtros) {
            this.filtros = filtros;
            this.lista = this.listaCopia;
            var search = `offset=${(this.page-1)*10}&`;

            if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
                search = search + `cifaFecha=${this.filtros[0].value}&`;
            }

            search = search + `coppId=${this.$route.params.coppId}&`;

            // if (this.user.role.indexOf('ADMIN') > -1) {
            //     if (this.filtros[2].value != null && this.filtros[2].value.toString().replace(/ /g, "") != "") {
            //         search = search + `coprId=${this.filtros[2].value}&`;

            //     }
            // } else {
            search = search + `coprId=${(this.user.empleado)?this.user.empleado:''}&`;

            // }

            if (search != "") {
                await this.search(search);
            }

        },
        async pdf(item) {
            if(this.getPermiso("verProcedimientos")){
                await this.dibujarH(item);
            }
        },
        async dibujarH(item) {
            this.pdfLoading = true;
            this.Historia = [];
            this.secciones = [];
            this.campos = [];
            this.diagnosticos = [];

            var datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + item.citaId).then(data => data);
            this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + item.cifaId + "/diagnostico").then(data => data);
            this.secciones = await this.$apiService.index("sistema/formulariosecciones/secciones/" + item.tblCitaCita.cocuId + "?PROCEDIMIENTO").then(data => data);
            this.Historia = await this.$apiService.index("sistema/formulariosfacturaciones/index/" + item.cifaId + "?tipo=factura").then(data => data);

            if (this.Historia.length > 0) {

                if (this.Historia[0].empleado) {
                    for (var i = 0; i < this.secciones.length; i++) {
                        const data = this.secciones[i].campos;
                        this.campos.push({
                            foseId: data[0].foseIdDependencia,
                            campos: data
                        });

                        for (var j = 0; j < data.length; j++) {
                            if (data[j].foseConsulta) {
                                let lista = await this.$apiService.create("reporte/reportes/consultar/", { consulta: data[j].foseConsulta }).then(data => data);
                                this.listasParametros.push({ foseId: data[j].foseId, lista: lista });

                            }
                            if (
                                this.Historia.filter(e => e.foseId == data[j].foseId).length == 0
                            ) {
                                this.Historia.push({
                                    ciffId: null,

                                    ciffValor: null,

                                    foseId: data[j].foseId
                                });

                            }

                        }

                    }
                    if (typeof window !== 'undefined') {
                        let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("version"));
                        let pdf = new jsPDF();
                        documento.getDocumento(pdf, datosPaciente, this.Historia, this.secciones, this.diagnosticos, this.listasParametros);
                    }
                } else {
                    this.$swal.fire({
                        title: "Historia mal tramitada!",
                        text: "la historia debe ser tramitada con un usuario que tenga asignado un empleado",
                        icon: "warning"
                    });
                }

            } else {
                this.$swal.fire({
                    title: "Historia no tramitada!",
                    text: "primero debe ser llenado la historia",
                    icon: "error"
                });
            }
            this.pdfLoading = false;

        },
        async eliminar(id) {

            try {
                if (this.getPermiso("eliminarProcedimientos")) {
                    var result = await this.$swal.fire({
                        title: "Estas seguro?",
                        text: "No podras revertir esto!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Si, eliminar!"
                    }).then(result => result.value);
                    if (result) {
                        let data = await this.$apiService.delete("sistema/formulariosfacturaciones/delete/" + id + "?tipo=factura").then(data => data);
                        if (data) {
                            await this.buscar(this.filtros);
                            this.$swal.fire(
                                "Eliminado!",
                                "ha sido eliminado con exito.",
                                "success"
                            );
                        }

                    }
                }
            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },

    }
};
</script>
