<style>

</style>

<template>
    <v-container lighten-5>
        <v-data-iterator :items="lista" :page.sync="page" :items-per-page="10" hide-default-footer sort-desc sort-by="mediId" class="elevation-1">
            <template v-slot:header>
                <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                <v-toolbar dark color="primary darken-3" class="mb-1">
                    <v-toolbar-title class="white--text">Medicamentos</v-toolbar-title>
                    <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true" @click="agregar(null,'medicamento')">
                        agregar
                    </v-btn>
                    <v-form @submit.prevent="subida" enctype="multipart/form-data">
                        <v-dialog v-model="dialogmasivo" persistent width="500">
                            <template v-slot:activator="{ on }">
                                <v-btn color="red lighten-2" dark v-on="on">
                                    Carga masiva
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-title class="headline grey lighten-2" primary-title>
                                    Cargar excel
                                </v-card-title>
                                <v-card-text>
                                    <v-row v-if="!loader">
                                        <v-col cols="12" sm="12" md="12">
                                            <v-file-input outlined :rules="nameRules" v-model="file" show-size label="Excel" @change="onFileChange"></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-col cols="12" sm="12" md="12" class="text-center">
                                            <span class="headline">CARGANDO MEDICAMENTOS....</span>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" class="text-center">
                                            <v-progress-circular :width="7" :size="150" color="primary" indeterminate></v-progress-circular>
                                        </v-col>
                                    </v-row>

                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                    <v-btn color="primary" text target="_blank" :href="plantilla">
                                        Descargar formato
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" v-if="!loader" text @click="dialogmasivo = false">
                                        cerrar
                                    </v-btn>
                                    <v-btn color="primary" v-if="!loader" text @click="subida">
                                        cargar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-form>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <div class="flex-grow-1"></div>
                </v-toolbar>

            </template>

            <template v-slot:default="props">
                <v-row align="center" justify="center">
                    <v-col v-for="item in props.items" :key="item.citaId" cols="12" sm="12" md="10" lg="10">
                        <v-card>
                            <v-card-title class="subtitle-1 font-weight-bold">
                                <v-row no-gutters>

                                    <v-col cols="8" sm="8" md="8" lg="8">
                                        <v-row no-gutters>

                                            <v-col cols="12" sm="7" md="7" lg="7">
                                                MEDICAMENTO <span class="primary--text">
                                                    {{ item.mediNombre }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                STOCK <span class="red--text" dark v-if="item.mediEstadostock== 'AGOTADO'">{{item.mediStock}}</span>
                                                <span class="yellow--text" dark v-else-if="item.mediEstadostock=='PRONTO A AGOTARCE'">{{item.mediStock}}</span>
                                                <span class="green--text" dark v-else>{{item.mediStock}}</span>
                                            </v-col>

                                            <v-col cols="12" sm="7" md="7" lg="7">
                                                CUM <span class="primary--text">
                                                    {{item.mediCum}}

                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="7" md="7" lg="7">
                                                ATC <span class="primary--text">
                                                    {{item.mediCodigoAtc}}

                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                ESTADO <span class="red--text" dark v-if="item.mediEstadostock== 'AGOTADO'">{{item.mediEstadostock}}</span>
                                                <span class="yellow--text" dark v-else-if="item.mediEstadostock=='PRONTO A AGOTARCE'">{{item.mediEstadostock}}</span>
                                                <span class="green--text" dark v-else>{{item.mediEstadostock}}</span>
                                            </v-col>
                                            <v-col cols="12" sm="7" md="7" lg="7">
                                                <span class="green--text" dark v-if="item.mediActivo">ACTIVO</span>
                                                <span class="red--text" dark v-else>INACTIVO</span>
                                            </v-col>

                                        </v-row>
                                    </v-col>
                                    <v-col cols="4" sm="4" md="4" lg="4">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined color="indigo" class="mr-2" v-on="on" small :to="{ name: 'Medipanel',params: { id: item.mediId} }">
                                                    <v-icon>mdi-desktop-mac-dashboard</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Ver en panel</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined color="success" class="mr-2" v-on="on" small @click.stop="dialog = true" @click="agregar(item.mediId,'entrada')">
                                                    <v-icon>mdi-briefcase-plus-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Agregar</span>
                                        </v-tooltip>
                                        <!-- <v-btn icon fab color="info" class="mr-2" x-small @click.stop="dialog = true" @click="agregar(item.mediId,'salida')">
                        <v-icon>mdi-briefcase-minus-outline</v-icon>
                    </v-btn> -->
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined color="info" class="mr-2" v-on="on" small @click.stop="dialog = true" @click="editar(item.mediId)">
                                                    <v-icon>create</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>

                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined small color="error" v-on="on" @click="eliminar(item.mediId)">
                                                    <v-icon>delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                        <v-tooltip top v-if="item.estadoTramite > 0">
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined :loading="pdfLoading" color="info" class="mr-2" v-on="on" small @click="pdf(item)">
                                                    <v-icon>mdi-file-pdf-box</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Generar pdf</span>
                                        </v-tooltip>

                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row no-gutters>
                                    <v-col cols="12" sm="1" md="1" lg="1">
                                        POS <span class="green--text" dark v-if="item.mediPos">Si</span>
                                        <span class="red--text" dark v-else>No</span>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2" lg="2">
                                        VIA <span class="primary--text">
                                            {{item.via.comaNombrecorto}}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3" lg="3">
                                        PRESENTACION <span class="primary--text">
                                            {{item.presentacion.comaNombrelargo}}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3" lg="3">
                                        FORMA FARMACEUTICA <span class="primary--text">
                                            {{item.mediFormafarmaceutica}}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2" lg="2">
                                        CONCENTRACION <span class="primary--text">
                                            {{item.mediConcentracion}}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2" lg="2">
                                        UNIDAD <span class="primary--text">
                                            {{item.unidad.comaNombrelargo}}
                                        </span>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
                <v-row class="mt-2" align="center" justify="center">
                    <v-col cols="10" sm="10" md="10" lg="10">
                        <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                            No se encontro nada.
                        </v-alert>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:footer>
                <v-row class="mt-2 pb-5" align="center" justify="center">
                    <span class="mr-4 grey--text">
                        Pagina {{ page }} de {{ pageCount }}, Total resultados: {{(Count)}}
                    </span>
                    <v-pagination circle v-model="page" @input="buscar(filtros)" @next="buscar(filtros)" @previous="buscar(filtros)" :total-visible="10" :length="pageCount"></v-pagination>

                </v-row>
            </template>
        </v-data-iterator>
        <v-form @submit.prevent="enviar">
            <v-dialog v-model="dialog" width="800px" max-width="800px">
                <v-card v-if="tipo=='medicamento'">
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>

                                <v-col cols="12" sm="12" md="8">
                                    <v-text-field outlined required label="Nombre" v-model="form.mediNombre" v-on:keyup="$data.form.mediNombre = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined required label="Stock" v-model="form.mediStock" v-on:keyup="$data.form.mediStock = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined required label="Cum" v-model="form.mediCum" v-on:keyup="$data.form.mediCum = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined required label="Codigo atc" v-model="form.mediCodigoAtc" v-on:keyup="$data.form.mediCodigoAtc = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <v-text-field outlined required label="Forma farmaceutica" v-model="form.mediFormafarmaceutica" v-on:keyup="$data.form.mediFormafarmaceutica = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="2">
                                    <v-text-field outlined required label="Concentracion" v-model="form.mediConcentracion" v-on:keyup="$data.form.mediConcentracion = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete outlined required v-model="form.mediTipoUnidad" :items="tipounidad" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de unidad" clearable>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete outlined required v-model="form.mediTipoVia" :items="tipovia" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de Via" clearable>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-autocomplete outlined required v-model="form.mediTipoPresentacion" :items="tipopresentacion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Presentacion" clearable>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-switch v-model="form.mediPos" label="Tipo pos"></v-switch>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-switch v-model="form.mediActivo" label="Activo"></v-switch>
                                </v-col>

                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="enviar">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
                <v-card v-if="tipo=='entrada'">
                    <v-card-title>
                        <span class="headline">Entrada</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>

                            <v-row>

                                <v-col cols="12" sm="12" md="6">
                                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field required outlined :error="$v.formEntradas.meenFechaMovimiento.$error" v-model="formEntradas.meenFechaMovimiento" label="Fecha Movimiento" append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker locale="ES" v-model="formEntradas.meenFechaMovimiento" @input="menu = false"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" sm="12" md="6">
                                    <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field required outlined :error="$v.formEntradas.meenFechaVencimiento.$error" v-model="formEntradas.meenFechaVencimiento" label="Fecha Vencimiento" append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker locale="ES" v-model="formEntradas.meenFechaVencimiento" @input="menu2 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field outlined required label="Proveedor" v-model="form.meenProveedor" v-on:keyup="$data.form.meenProveedor = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field outlined required label="Laboratorio" v-model="formEntradas.meenLaboratorio" v-on:keyup="$data.formEntradas.meenLaboratorio = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field outlined required label="Lote" v-model="formEntradas.meenLote" v-on:keyup="$data.formEntradas.meenLote = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field outlined required label="Invima" v-model="formEntradas.meenInvima" v-on:keyup="$data.formEntradas.meenInvima = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field outlined type="number" required label="Cantidad" v-model="formEntradas.meenCantidad" v-on:keyup="$data.formEntradas.meenCantidad = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field outlined required label="Existencia" v-model="formEntradas.meenExistencia" v-on:keyup="$data.formEntradas.meenExistencia = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field outlined type="number" required label="Precio compra" v-model="form.meenPreciocompra"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field outlined type="number" required label="Precio venta" v-model="form.meenPrecioventa"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider>
                    </v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="enviar">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
                <v-card v-if="tipo=='salida'">
                    <v-card-title>
                        <span class="headline">Salida</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>

                                <v-col cols="auto" md="12">
                                    <v-row justify="space-around">

                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field outlined required label="mesaFecha" v-model="formSalidas.mesaFecha" v-on:keyup="$data.formSalidas.mesaFecha = $event.target.value.toUpperCase()"></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field outlined required label="mesaCantidadentregada" v-model="formSalidas.mesaCantidadentregada" v-on:keyup="$data.formSalidasmesaCantidadentregada = $event.target.value.toUpperCase()"></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field outlined required label="mesaCoopago" v-model="formSalidas.mesaCoopago" v-on:keyup="$data.formSalidas.mesaCoopago = $event.target.value.toUpperCase()"></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field outlined required label="mereDireccionentrega" v-model="formSalidas.mereDireccionentrega" v-on:keyup="$data.formSalidas.mereDireccionentrega = $event.target.value.toUpperCase()"></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="12">
                                            <v-autocomplete outlined required v-model="formSalidas.mereTipoEntrega" :items="tipoentregas" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="mereTipoEntrega" clearable>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field outlined required label="cireId" v-model="formSalidas.cireId" v-on:keyup="$data.formSalidas.cireId = $event.target.value.toUpperCase()"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="enviar">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>

    </v-container>
</template>

<script>
import plantilla from '@/assets/files/plantilla_medicamento.xlsx';
import {
  required
}
from 'vuelidate/lib/validators';
export default {
  components: {

  },
  data() {
    return {
      plantilla: null,
      dialogmasivo: false,
      dialog: false,
      loader: false,
      tipo: "medicamento",
      menu: false,
      menu2: false,
      page: 1,
      pageCount: 0,
      Count: 0,
      file: null,
      isNew: true,
      url: "sistema/medicinas/",
      token: this.$cookies.get("token"),

      nameRules: [v => !!v || "Campo requirido"],

      formEntradas: {
        meenId: null,
        meenFechaMovimiento: null,
        meenFechaVencimiento: null,
        meenLote: null,
        meenInvima: null,
        meenLaboratorio: null,
        meenCantidad: null,
        meenExistencia: null,
        meenProveedor: null,
        meenPreciocompra: 0,
        meenPrecioventa: 0
      },
      formSalidas: {
        mesaId: null,
        mesaFecha: null,
        mesaCantidadentregada: null,
        mesaCoopago: null,
        mereDireccionentrega: null,
        mereTipoEntrega: null,
        cireId: null,
      },
      form: {
        mediId: null,

        mediNombre: null,

        mediStock: null,

        mediCum: null,
        mediCodigoAtc:null,
        mediTipoVia: null,

        mediTipoPresentacion: null,
        mediPos: 0,
        mediFormafarmaceutica: null,
        mediConcentracion: null,
        mediTipoUnidad: null,
        mediActivo: 1,

      },
      filtros: [{
        text: "Nombre",
        value: null,
        tipo: "text",
        search: true,
        isLoading: false,
        /*si es tipo lista descomentar esta parte*/
        /* lista: [],
        listatext: "nombre del campo que se mostrara",
        listavalue: "nombre del valor que se mandara"*/
        //para select y items personalizados
        /*  default: true,
        defaulttext: "tblConfPersonasnaturales",
        defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
        defaultsubtext: "copeIdentificacion",*/
      }, {
        text: "Stock",
        value: null,
        tipo: "text",
        search: true,
        isLoading: false,
        /*si es tipo lista descomentar esta parte*/
        /* lista: [],
        listatext: "nombre del campo que se mostrara",
        listavalue: "nombre del valor que se mandara"*/
        //para select y items personalizados
        /*  default: true,
        defaulttext: "tblConfPersonasnaturales",
        defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
        defaultsubtext: "copeIdentificacion",*/
      }, {
        text: "Cum",
        value: null,
        tipo: "text",
        search: true,
        isLoading: false,
        /*si es tipo lista descomentar esta parte*/
        /* lista: [],
        listatext: "nombre del campo que se mostrara",
        listavalue: "nombre del valor que se mandara"*/
        //para select y items personalizados
        /*  default: true,
        defaulttext: "tblConfPersonasnaturales",
        defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
        defaultsubtext: "copeIdentificacion",*/
      }, {
        text: "Tipo de Via",
        value: null,
        tipo: "lista",
        /*si es tipo lista descomentar esta parte*/
        lista: [],
        listatext: "comaNombrelargo",
        listavalue: "comaId"
        //para select y items personalizados
        /*  default: true,
        defaulttext: "tblConfPersonasnaturales",
        defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
        defaultsubtext: "copeIdentificacion",*/
      }, {
        text: "Presentacion",
        value: null,
        tipo: "lista",
        /*si es tipo lista descomentar esta parte*/
        lista: [],
        listatext: "comaNombrelargo",
        listavalue: "comaId"
        //para select y items personalizados
        /*  default: true,
        defaulttext: "tblConfPersonasnaturales",
        defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
        defaultsubtext: "copeIdentificacion",*/
      }, ],
      lista: [],
      listaCopia: [],
      tipovia: [],
      tipopresentacion: [],
      tipounidad: [],
      tipoentregas: [],
    };
  },
  validations: {
    formEntradas: {
      meenFechaMovimiento: {
        required,
      },
      meenFechaVencimiento: {
        required,
      },
      meenLote: {
        required,
      },
      meenInvima: {
        required,
      },
      meenLaboratorio: {
        required,
      },
      meenCantidad: {
        required,
      },
      meenExistencia: {
        required,
      },
      meenPreciocompra: {
        required,
      },
      meenPrecioventa: {
        required,
      },
    },
    formSalidas: {
      mesaFecha: {
        required,
      },
      mesaCantidadentregada: {
        required,
      },
      mesaCoopago: {
        required,
      },
      mereTipoEntrega: {
        required,
      },
      cireId: {
        required,
      },
    },
    form: {

      mediNombre: {
        required,
      },

      mediStock: {
        required,
      },

      mediTipoVia: {
        required,
      },

      mediTipoPresentacion: {
        required,
      },
      mediFormafarmaceutica: {
        required,
      },
      mediConcentracion: {
        required,
      },
      mediTipoUnidad: {
        required,
      },

    },
  },
  computed: {
    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created: async function() {
    this.plantilla = plantilla;
    this.$store.commit('setCargaDatos', true);
    //this.listaCopia = this.lista = await this.$apiService.index(this.url + "index").then(data => data);
    this.filtros[3].lista = this.tipovia = await this.$apiService.index("sistema/maestra/index/TBL_TIPOVIASMED").then(data => data);
    this.filtros[4].lista = this.tipopresentacion = await this.$apiService.index("sistema/maestra/index/TBL_TIPOPRESENTACIONMED").then(data => data);
    this.tipoentregas = await this.$apiService.index("sistema/maestra/index/TBL_TIPOENTREGAS").then(data => data);
    this.tipounidad = await this.$apiService.index("sistema/maestra/index/TBL_TIPOUNIDADES").then(data => data);
    await this.buscar(this.filtros);
    this.$store.commit('setCargaDatos', false);
    this.limpiar();
  },

  methods: {
    onFileChange(file) {
      this.file = file;
    },
    getMaestra(id, maestra) {
      if (id != null) {

        if (maestra.length > 0) {
          var ma = maestra.filter(e => e.comaId == id);
          if (ma.length > 0) {
            var m = ma[0];
            return m.comaNombrelargo;
          }
        }
      }

    },
    async searchMedicamento(searchP) {
      this.$store.commit('setCargaDatos', true);
      const data = await this.$apiService.index(`${this.url}buscar?${searchP}`).then(data => data);
      if (data) {
        this.lista = this.listaCopia = data.rows;
        this.Count = data.count;
        this.pageCount = Math.ceil((data.count) / 10);
      }
      this.$store.commit('setCargaDatos', false);
    },

    async buscar(filtros) {
      this.filtros = filtros;
      this.lista = this.listaCopia;
      var search = `offset=${(this.page-1)*10}&`;
      if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
        search = search + `mediNombre=${this.filtros[0].value}&`;
      }
      if (this.filtros[1].value != null && this.filtros[1].value.toString().replace(/ /g, "") != "") {
        search = search + `mediStock=${this.filtros[1].value}&`;
      }
      if (this.filtros[2].value != null && this.filtros[2].value.toString().replace(/ /g, "") != "") {
        search = search + `mediCum=${this.filtros[2].value}&`;
      }
      if (this.filtros[3].value != null && this.filtros[3].value.toString().replace(/ /g, "") != "") {
        search = search + `mediTipoVia=${this.filtros[3].value}&`;
      }
      if (this.filtros[4].value != null && this.filtros[4].value.toString().replace(/ /g, "") != "") {
        search = search + `mediTipoPresentacion=${this.filtros[4].value}&`;
      }
      if (search != "") {
        await this.searchMedicamento(search);
      }

    },
    async subida() {
      try {
        if (this.file != null) {
          let formData = new FormData();
          formData.append('file', this.file);
          this.loader = true;
          let data = await this.$apiService.createFile(this.url + "masiva", formData).then(data => data);
          if (data) {
            this.loader = false;
            this.file = null;
            this.$store.commit('setCargaDatos', true);
            await this.buscar(this.filtros);
            this.$store.commit('setCargaDatos', false);
            this.$swal.fire({
              title: "Completado!",
              text: "Subida exitosa",
              icon: "success",
              confirmButtonText: "Ok"
            });
          }

        } else {
          this.loader = false;
          this.$swal.fire({
            title: "Cuidado!",
            text: "Selecciona un archivo primero",
            type: "warnning",
            confirmButtonText: "Ok"
          });
        }

      } catch (error) {
        this.loader = false;
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
      this.dialogmasivo = false;

    },
    agregar(med, tipo) {
      switch (tipo) {
        case 'medicamento':
          this.tipo = tipo;
          break;
        case 'entrada':
          this.formEntradas.mediId = med;
          this.tipo = tipo;
          break;
        case 'salida':
          this.formSalidas.mediId = med;
          this.tipo = tipo;
          break;

      }
    },
    async enviar() {
      if (this.tipo == 'medicamento') {
        this.$v.form.$touch();
        if (!this.$v.form.$error) {
          try {
            if (this.isNew) {
              let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
              if (data) {

                this.$store.commit('setCargaDatos', true);
                await this.buscar(this.filtros);
                this.$store.commit('setCargaDatos', false);
                this.$swal.fire({
                  title: "Completado!",
                  text: "Registro exitoso",
                  icon: "success",
                  confirmButtonText: "Ok"
                });
              }

            } else {
              var id = this.form.mediId;
              let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);
              if (data) {
                this.$store.commit('setCargaDatos', true);
                await this.buscar(this.filtros);
                this.$store.commit('setCargaDatos', false);
                this.$swal.fire({
                  title: "Completado!",
                  text: "Registro exitoso",
                  icon: "success",
                  confirmButtonText: "Ok"
                });
              }

            }
          } catch (error) {
            this.$swal.fire({
              title: "Algo sucedio!",
              text: "contacta con el administrador",
              icon: "error"
            });
          }

        }
      }
      if (this.tipo == 'entrada') {
        this.$v.formEntradas.$touch();
        if (!this.$v.formEntradas.$error) {
          try {
            let data = await this.$apiService.create(this.url + "entradas/create", this.formEntradas).then(data => data);
            if (data) {
              this.$store.commit('setCargaDatos', true);
              await this.buscar(this.filtros);
              this.$store.commit('setCargaDatos', false);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          } catch (error) {
            this.$swal.fire({
              title: "Algo sucedio!",
              text: "contacta con el administrador",
              icon: "error"
            });
          }

        }
      }
      if (this.tipo == 'salida') {
        this.$v.formSalidas.$touch();
        if (!this.$v.formSalidas.$error) {
          try {
            let data = await this.$apiService.create(this.url + "salidas/create", this.formSalidas).then(data => data);
            if (data) {
              this.$store.commit('setCargaDatos', true);
              await this.buscar(this.filtros);
              this.$store.commit('setCargaDatos', false);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          } catch (error) {
            this.$swal.fire({
              title: "Algo sucedio!",
              text: "contacta con el administrador",
              icon: "error"
            });
          }

        }
      }
      this.close();
    },
    async eliminar(id) {
      try {
        var result = await this.$swal.fire({
          title: "Estas seguro?",
          text: "No podras revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, eliminar!"
        }).then(result => result.value);
        if (result) {
          let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
          if (data) {
            this.$store.commit('setCargaDatos', true);
            await this.buscar(this.filtros);
            this.$store.commit('setCargaDatos', false);
            this.$swal.fire(
              "Eliminado!",
              "ha sido eliminado con exito.",
              "success"
            );
          }
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async editar(id) {
      try {
        this.form = await this.$apiService.index(this.url + "view/" + id).then(data => data);

        this.isNew = false;
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },

    limpiar() {
      this.form = {
        mediId: null,

        mediNombre: null,

        mediStock: null,

        mediCum: null,

        mediTipoVia: null,

        mediTipoPresentacion: null,
        mediPos: 0,
        mediFormafarmaceutica: null,
        mediConcentracion: null,
        mediTipoUnidad: null,
        tblMediEntradas: [],
        tblMediSalidas: [],
        mediActivo: 1,

      };
      this.formEntradas = {
        meenId: null,
        meenFechaMovimiento: null,
        meenFechaVencimiento: null,
        meenLote: null,
        meenInvima: null,
        meenLaboratorio: null,
        meenCantidad: null,
        meenExistencia: null,
        mediId: null,
        meenPreciocompra: 0,
        meenPrecioventa: 0
      };
      this.formSalidas = {
        mesaId: null,
        mesaFecha: null,
        mesaCantidadentregada: null,
        mesaCoopago: null,
        mereDireccionentrega: null,
        mereTipoEntrega: null,
        cireId: null,
        mediId: null
      };
    },
    close() {
      this.isNew = true;
      this.dialog = false;
      this.limpiar();
    }
  }
};

</script>