<style>
</style>
<template>
    <v-container lighten-5>
        <v-data-iterator :items="lista" :items-per-page="10" sort-desc sort-by="insaId" class="elevation-1">
            <template v-slot:header>
                <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                <v-toolbar dark color="primary darken-3" class="mb-1">
                    <v-toolbar-title class="white--text">Insumos</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <div class="flex-grow-1"></div>
                </v-toolbar>
            </template>
            <template v-slot:default="props">
                <v-row align="center" justify="center">
                    <v-col v-for="item in props.items" :key="item.insaId" cols="12" sm="12" md="10" lg="10">
                        <v-card>
                            <v-card-title class="subtitle-1 font-weight-bold">
                                <v-row no-gutters>
                                    <v-col cols="8" sm="8" md="8" lg="8">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="6" md="6" lg="6">
                                                FECHA <span class="primary--text">
                                                    {{ item.insaFecha }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6" lg="6">
                                                CANTIDAD ENTREGADA <span class="primary--text">
                                                    {{ item.InsaCantidad }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row no-gutters>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
                <v-row class="mt-2" align="center" justify="center">
                    <v-col cols="10" sm="10" md="10" lg="10">
                        <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                            No se encontro nada.
                        </v-alert>
                    </v-col>
                </v-row>
            </template>
        </v-data-iterator>
    </v-container>
</template>
<script>
export default {
  components: {

  },
  props: {
    insumo: Object,
  },
  data() {
    return {
      tipo: "insumo",
      menu: false,
      menu2: false,
      dialog: false,
      isNew: true,
      url: "sistema/insumos/salidas/",
      token: this.$cookies.get("token"),
      nameRules: [v => !!v || "Campo requirido"],
      formSalidas: {
        insaId: null,
        insaFecha: null,
        InsaCantidad: null,
        cifaId: null,
      },
      filtros: [{
          text: "Fecha",
          value: null,
          tipo: "fecha",
          menu: false
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
          //para select y items personalizados
          /*  default: true,
          defaulttext: "tblConfPersonasnaturales",
          defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
          defaultsubtext: "copeIdentificacion",*/
        }
      ],
      lista: [],
      listaCopia: []
    };
  },

  computed: {
    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created: async function() {
    this.listaCopia = this.lista = await this.$apiService.index(this.url + "index/" + this.insumo.mediId).then(data => data);
    this.limpiar();
  },
  methods: {
    buscar(filtros) {
      this.filtros = filtros;
      this.lista = this.listaCopia;
      if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
        this.lista = this.lista.filter(
          e => e.insaFecha == this.filtros[0].value
        );
      }
    },

    async eliminar(id) {
      try {
        var result = await this.$swal.fire({
          title: "Estas seguro?",
          text: "No podras revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, eliminar!"
        }).then(result => result.value);
        if (result.value) {
          let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
          if (data) {
            this.listaCopia = this.lista = this.lista.filter(
              e => e.insaId != id
            );
            this.$swal.fire(
              "Eliminado!",
              "ha sido eliminado con exito.",
              "success"
            );
          }
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async editar(id) {
      try {
        this.form = await this.$apiService.index(this.url + "view/" + id).then(data => data);
        this.isNew = false;
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    limpiar() {
      this.formSalidas = {
        insaId: null,
        insaFecha: null,
        InsaCantidad: null,
        cifaId: null,
      };
    },
    close() {
      this.isNew = true;
      this.dialog = false;
      this.limpiar();
    }
  }
};

</script>
