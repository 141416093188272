<template>
    <div>
        <v-toolbar dense flat class="light-blue darken-4" dark>

            <v-toolbar-title>Panel de medicamentos</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn text class="white" color="black" :to="{name:'Medicamentos'}">
                <v-icon>mdi-chevron-left</v-icon>
                regresar
            </v-btn>
        </v-toolbar>

        <v-tabs v-model="tab" background-color="grey darken-3 accent-4" centered dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
                Entradas
                <v-icon>mdi-briefcase-plus-outline</v-icon>
            </v-tab>
            <v-tab href="#tab-2">
                Salidas
                <v-icon>mdi-briefcase-minus-outline</v-icon>
            </v-tab>
        </v-tabs>
        <v-card class="pa-2" outlined tile>
            <v-container>

                <v-row class="caption" no-gutters>
                    <v-col cols="12" sm="12" md="2">
                        <label class="primary--text">NOMBRE: </label>

                        <label class="font-weight-black">{{ form.mediNombre }}</label>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                        <label class="primary--text">STOCK: </label>

                        <label class="font-weight-black">{{ form.mediStock }}</label>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                        <label class="primary--text">CUM: </label>

                        <label class="font-weight-black">{{form.mediCum}}</label>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                        <label class="primary--text">TIPO VIA: </label>

                        <label class="font-weight-black">{{getMaestra(form.mediTipoVia,tipovia)}}</label>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                        <label class="primary--text">PRESENTACION: </label>

                        <label class="font-weight-black">{{getMaestra(form.mediTipoPresentacion,tipopresentacion)}}</label>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-tabs-items v-model="tab">

            <v-tab-item value="tab-1">
                <medientradas v-on:aumentar="form.mediStock = $event" v-bind:medicamento="form" v-if="form.mediId" />
            </v-tab-item>
            <v-tab-item value="tab-2">
                <medisalidas v-bind:medicamento="form" v-if="form.mediId" />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import Medisalidas from "./Medisalidas";
import Medientradas from "./Medientradas";
export default {
  components: {
    Medientradas,
    Medisalidas
  },
  data() {
    return {
      tab: "tab-1",
      user: null,
      url: "sistema/medicinas/",
      token: this.$cookies.get("token"),
      form: {
        mediId: null,

        mediNombre: null,

        mediStock: null,

        mediCum: null,

        mediTipoVia: null,

        mediTipoPresentacion: null,

      },
      tipovia: [],
      tipopresentacion: []
    }
  },

  created: async function() {

    if (this.$route.params.id) {
      this.tipovia = await this.$apiService.index("sistema/maestra/index/TBL_TIPOVIASMED").then(data => data);
      this.tipopresentacion = await this.$apiService.index("sistema/maestra/index/TBL_TIPOPRESENTACIONMED").then(data => data);
      this.editar(this.$route.params.id);
    }

  },

  methods: {
    getMaestra(id, maestra) {
      if (id != null) {

        if (maestra.length > 0) {
          var ma = maestra.filter(e => e.comaId == id);
          if (ma.length > 0) {
            var m = ma[0];
            return m.comaNombrelargo;
          }
        }
      }

    },
    async editar(id) {
      try {
        this.$store.commit('setCargaDatos', true);
        this.form = await this.$apiService.index(this.url + "view/" + id).then(data => data);
        this.$store.commit('setCargaDatos', false);
        this.isNew = false;
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },

    limpiar() {
      this.form = {
        mediId: null,

        mediNombre: null,

        mediStock: null,

        mediCum: null,

        mediTipoVia: null,

        mediTipoPresentacion: null,

      };
    },
  }
}

</script>
