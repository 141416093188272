<style>

</style>

<template>
    <div>

        <v-toolbar dense flat class="light-blue darken-4" dark>
            <v-btn text class="white" color="black" :to="{name:'Entrega de Medicamentos'}">
                <v-icon>mdi-chevron-left</v-icon>
                regresar
            </v-btn>
            <div class="flex-grow-1"></div>
            <v-toolbar-title>Panel de entrega de medicamentos</v-toolbar-title>
        </v-toolbar>

        <v-card class="pa-2 " outlined dense tile>

            <v-container lighten-5>
                <v-data-iterator :items="lista" hide-default-footer sort-desc sort-by="cifaFecha" class="elevation-1">
                    <template v-slot:header>
                        <v-toolbar dark color="primary darken-3" class="mb-1">
                            <v-toolbar-title class="white--text">Facturaciones</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true">
                                agregar
                            </v-btn>
                            <div class="flex-grow-1"></div>
                        </v-toolbar>

                    </template>

                    <template v-slot:default="props">
                        <v-row align="center" justify="center">
                            <v-col v-for="item in props.items" :key="item.cifaId" cols="12" sm="12" md="10" lg="10">
                                <v-card>
                                    <v-card-title class="subtitle-1 font-weight-bold">
                                        <v-row no-gutters>

                                            <v-col cols="8" sm="8" md="8" lg="8">
                                                <v-row no-gutters>

                                                    <v-col cols="12" sm="5" md="5" lg="5">
                                                        NUM. FACTURA <span class="primary--text">
                                                            {{ item.cifaNumFactura }}
                                                        </span>
                                                    </v-col>
                                                    <v-col cols="12" sm="7" md="7" lg="7">
                                                        FECHA <span class="green--text"> {{item.cifaFecha}}</span>
                                                    </v-col>

                                                    <v-col cols="12" sm="5" md="5" lg="5">
                                                        NUM. AUTORIZACION <span class="primary--text">
                                                            {{(item.cifaNumeroAutorizacion)?item.cifaNumeroAutorizacion:'NO TIENE'}}

                                                        </span>
                                                    </v-col>
                                                    <v-col cols="12" sm="7" md="7" lg="7">
                                                        MES <span class="primary--text">{{item.mes.comaNombrelargo }}
                                                        </span>
                                                    </v-col>

                                                </v-row>
                                            </v-col>
                                            <v-col cols="4" sm="4" md="4" lg="4">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn outlined dense color="purple" class="mr-2" v-on="on" small @click="Gpdf(item.cifaId)">
                                                            <v-icon>mdi-file-pdf-box</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Generar pdf de factura</span>
                                                </v-tooltip>
                                                <!-- <v-tooltip top v-if="item.estado.comaNombrecorto=='FACTURADA' || item.estado.comaNombrecorto=='ACTIVA'">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon fab color="info" class="mr-2" v-on="on" x-small @click.stop="dialog = true" @click="editar(item.cifaId)">
                                        <v-icon>create</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar</span>
                            </v-tooltip> -->
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn outlined dense small color="error" v-on="on" @click="eliminar(item.cifaId)">
                                                            <v-icon>delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Eliminar</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="3" lg="3">
                                                EPS <span class="primary--text">
                                                    <template v-if="item.tblConfBasedatospacientes.length > 0">
                                                        {{ item.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemNombre }}
                                                    </template>
                                                    <template v-else-if="item.cocoId">
                                                        {{ item.tblConfContrato.tblConfPersonasempresas[0].coemNombre }}
                                                    </template>
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="4" lg="4">
                                                COPAGO
                                                <span class="primary--text">
                                                    <v-icon style="color:#2196F3;font-size: 0.875rem;">mdi-currency-usd</v-icon>{{ item.cifaCopago }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="4" lg="5">
                                                VALE
                                                <span class="primary--text"> {{ (item.cifaVale)?item.cifaVale:'NO TIENE' }} </span>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:no-data>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="10" sm="10" md="10" lg="10">
                                <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                    No se encontro nada.
                                </v-alert>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:footer>
                        <v-card class="pa-2 " color="grey lighten-2" outlined dense tile v-if="cita">
                            <v-row class="white">
                                <v-col cols="auto" md="12">
                                    <v-row justify="space-around">
                                        <v-col cols="auto" md="12">
                                            <v-toolbar flat>
                                                <v-toolbar-title>Recetarios</v-toolbar-title>
                                                <v-divider class="mx-4" inset vertical></v-divider>
                                                <v-btn class="mx-2 primary--text" tile color="white" @click="agregarRecetario">Agregar nuevo</v-btn>
                                                <v-divider class="mx-4" inset vertical></v-divider>
                                                <v-btn class="mx-2 primary--text" tile color="white" @click="agregarRecetarios">Guardar</v-btn>
                                                <div class="flex-grow-1"></div>
                                            </v-toolbar>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <template v-for="(items,index) in formRecetarios">
                                        <v-row :key="items.cireId" v-if="formRecetarios.length > 0">
                                            <v-col cols="12" sm="12" md="2">
                                                <v-text-field outlined dense :rules="nameRules" required :error="$v.formRecetarios.$each[index].cireFrecuenciahoras.$error" @keyup="calcularCantidad(items)" v-model="items.cireFrecuenciahoras" label="Frecuencia de horas" number></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="2">
                                                <v-text-field outlined dense :rules="nameRules" required :error="$v.formRecetarios.$each[index].cireDuraciondias.$error" @keyup="calcularCantidad(items)" v-model="items.cireDuraciondias" label="Duracion en dias" number></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="2">
                                                <v-text-field outlined dense :rules="nameRules" required :error="$v.formRecetarios.$each[index].cireCantidadprescrita.$error" v-model="items.cireCantidadprescrita" label="Cantidad"></v-text-field>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="4">
                                                <v-autocomplete outlined dense :rules="nameRules" required :error="$v.formRecetarios.$each[index].mediId.$error" v-model="items.mediId" :items="medicinas" item-text="mediNombre" item-value="mediId" item-color="primary" label="Medicamento" clearable>
                                                    <template v-slot:selection="data">
                                                        <v-list-item-content>
                                                            <v-list-item-title><span class="primary--text">{{data.item.mediNombre}}</span>
                                                            </v-list-item-title>
                                                            <v-list-item-title v-if="VERSION != 'semic'">STOCK: <strong> <span class="red--text" dark v-if="data.item.mediEstadostock== 'AGOTADO'">{{data.item.mediStock}} {{data.item.mediEstadostock}}</span>
                                                                    <span class="yellow--text" dark v-else-if="data.item.mediEstadostock=='PRONTO A AGOTARCE'">{{data.item.mediStock}} {{data.item.mediEstadostock}}</span>
                                                                    <span class="green--text" dark v-else>{{data.item.mediStock}} {{data.item.mediEstadostock}}</span></strong>
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle>
                                                                Via {{ data.item.via.comaNombrelargo}},
                                                                Concentracion {{ data.item.mediConcentracion}},
                                                                Forma farmaceutica {{ data.item.mediFormafarmaceutica}}
                                                                Presentacion {{data.item.presentacion.comaNombrelargo}} Unidad: {{data.item.unidad.comaNombrelargo}}
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                    <template v-slot:item="data">
                                                        <template v-if="(typeof data.item !== 'object')">
                                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                                        </template>
                                                        <template v-else>
                                                            <v-list-item-content>
                                                                <v-list-item-title><span class="primary--text">{{data.item.mediNombre}}</span>
                                                                </v-list-item-title>
                                                                <v-list-item-title v-if="VERSION != 'semic'">STOCK: <strong><span class="red--text" dark v-if="data.item.mediEstadostock== 'AGOTADO'">{{data.item.mediStock}} {{data.item.mediEstadostock}}</span>
                                                                        <span class="yellow--text" dark v-else-if="data.item.mediEstadostock=='PRONTO A AGOTARCE'">{{data.item.mediStock}} {{data.item.mediEstadostock}}</span>
                                                                        <span class="green--text" dark v-else>{{data.item.mediStock}} {{data.item.mediEstadostock}}</span></strong>
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle>
                                                                    Via {{ data.item.via.comaNombrelargo}},
                                                                    Concentracion {{ data.item.mediConcentracion}},
                                                                    Forma farmaceutica {{ data.item.mediFormafarmaceutica}}
                                                                    Presentacion {{data.item.presentacion.comaNombrelargo}} Unidad: {{data.item.unidad.comaNombrelargo}}
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </template>
                                                    </template>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="auto" md="1">
                                                <v-btn text icon color="error" @click="eliminarRecetario(items)">
                                                    <v-icon>delete</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-textarea outlined dense required v-model="items.cireDetalles" label="Detalles de la formula"></v-textarea>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-col>
                            </v-row>
                            <v-row justify="space-around">
                                <v-col cols="12" sm="12" md="12" style="min-heigth:80px" v-for="(item, i) in (cita.citaTipo == 4)?cita.tblCitaRecetarios:cita.tblCitaFacturaciones[0].tblCitaRecetarios" :key="i">
                                    <v-sheet min-height="100%" width="100%">
                                        <v-container fluid class="pa-0">
                                            <v-row align="center" no-gutters class="text-center" style="min-height:80px">

                                                <v-col cols="12" sm="12" md="1">
                                                    <span>
                                                        <v-icon>mdi-pill</v-icon>
                                                    </span>
                                                </v-col>
                                                <v-divider light class="mx-1" vertical></v-divider>
                                                <v-col cols="12" sm="12" md="8">
                                                    <span>{{item.tblMediMedicamento.mediNombre +' - ' + item.tblMediMedicamento.mediConcentracion + ' ' +item.tblMediMedicamento.unidad.comaNombrecorto+' cada '+ item.cireFrecuenciahoras+' horas, durante '+ item.cireDuraciondias+' dias - Cantidad: '+item.cireCantidadprescrita + ' - Entregados: ' + item.cireEntregado + ' - Pendientes: '+(item.cirePendiente)}}</span>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="2">
                                                    <span :class="(((parseFloat(item.cireEstadoentrega) * 100) <=30 )?'red':((parseFloat(item.cireEstadoentrega) * 100)<=99 )?'yellow':((parseFloat(item.cireEstadoentrega) * 100)==100 )?'green':'grey')+'--text'">{{(parseFloat(item.cireEstadoentrega) * 100)}} %</span>
                                                </v-col>

                                            </v-row>
                                        </v-container>
                                    </v-sheet>
                                </v-col>
                            </v-row>
                        </v-card>
                    </template>
                </v-data-iterator>
            </v-container>
        </v-card>

        <v-form @submit.prevent="agregar">
            <v-dialog v-model="dialog" width="1200px" max-width="1200px" v-if="cita">
                <v-card>
                    <v-card-title>
                        <span class="headline">Entrega de medicamento</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <template v-if="form.cocoId == null">
                            <v-alert text prominent dense type="success" v-if="bd.length > 0">
                                El paciente se encuentra en las base de datos de <template v-for="b in bd"> {{ ','+ b.cocoNombre  }}</template>
                            </v-alert>
                            <v-alert text prominent dense type="error" v-else>
                                El paciente no se encuentra en ninguna base de datos para el mes de {{ meses[ new Date().getMonth()]}}.
                            </v-alert>
                        </template>
                        <v-container fluid v-if="bd.length > 0 || form.cocoId">
                            <v-row>
                                <v-col cols="12" sm="12" md="3">
                                  <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field outlined dense v-model="form.cifaFecha" :error="$v.form.cifaFecha.$error" label="Fecha" append-icon="event" readonly v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker locale="ES" v-model="form.cifaFecha" @input="menu = false"></v-date-picker>
                                        </v-menu>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field outlined dense required label="Numero de la factura" readonly :error="$v.form.cifaNumFactura.$error" v-model="form.cifaNumFactura"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field readonly outlined dense label="Copago" :error="$v.form.cifaCopago.$error" v-model="form.cifaCopago"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="cita.cocoId">
                                    <v-autocomplete outlined dense v-model="form.cocoId" :items="contratos" item-text="cocoNombre" item-value="cocoId" item-color="primary" label="Contrato" clearable>
                                        <template v-slot:selection="data">
                                            {{data.item.cocoNombre +" - "+data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}
                                        </template>
                                        <template v-slot:item="data">
                                            <template v-if="(typeof data.item !== 'object')">
                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                            </template>
                                            <template v-else>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ data.item.cocoNombre}}</v-list-item-title>
                                                    <v-list-item-subtitle> {{data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-else>
                                    <v-autocomplete outlined dense v-model="form.cbdpId" :items="bd" item-text="cocoNombre" item-value="cbdpId" item-color="primary" label="EPS contrada" clearable>
                                        <template v-slot:item="data">
                                            <template v-if="(typeof data.item !== 'object')">
                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                            </template>
                                            <template v-else>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{data.item.cocoNombre}}</v-list-item-title>
                                                    <v-list-item-subtitle> {{data.item.coemNombre}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text v-if="form.cocoId || form.cbdpId">
                        <v-container fluid>
                            <v-row>
                                <v-col cols="auto" md="12">
                                    <v-row justify="space-around">
                                        <v-col cols="auto" sm="12" md="12">
                                            <v-toolbar flat>

                                                <v-btn color="info" text @click="agregarEntrega">Agregar nuevo</v-btn>

                                                <div class="flex-grow-1"></div>
                                            </v-toolbar>

                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="auto" sm="12" md="12" v-if="cita">
                                    <v-container>
                                        <template v-for="(item,i) in formSalidas">
                                            <v-row justify="start" class="text-center" :key="i">
                                                <v-col cols="12" sm="12" md="9">
                                                    <v-autocomplete outlined dense required :error="$v.formSalidas.$each[i].cireId.$error" v-model="item.cireId" :items="(cita.citaTipo == 4)?cita.tblCitaRecetarios.filter( e => e.cirePendiente > 0):cita.tblCitaFacturaciones[0].tblCitaRecetarios.filter( e => e.cirePendiente > 0)" item-text="cireId" item-value="cireId" item-color="primary" label="Recetario" clearable @change="cargar(item)">
                                                        <template v-slot:selection="data">
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    <span class="primary--text">{{data.item.tblMediMedicamento.mediNombre +' - ' + data.item.tblMediMedicamento.mediConcentracion + ' ' +data.item.tblMediMedicamento.unidad.comaNombrecorto}}</span>

                                                                </v-list-item-title>
                                                                <v-list-item-title>
                                                                    <span class="primary--text">{{'Cantidad: '+data.item.cireCantidadprescrita}}</span>

                                                                </v-list-item-title>
                                                                <v-list-item-subtitle>
                                                                    <span class="">
                                                                        {{'Entragados: ' + data.item.cireEntregado + ' - Pendientes: '+(data.item.cirePendiente)}}
                                                                    </span>
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template v-if="(typeof data.item !== 'object')">
                                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                                            </template>
                                                            <template v-else>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>
                                                                        <span class="primary--text">{{data.item.tblMediMedicamento.mediNombre +' - ' + data.item.tblMediMedicamento.mediConcentracion + ' ' +data.item.tblMediMedicamento.unidad.comaNombrecorto}}</span>

                                                                    </v-list-item-title>
                                                                    <v-list-item-title>
                                                                        <span class="primary--text">{{'Cantidad: '+data.item.cireCantidadprescrita}}</span>

                                                                    </v-list-item-title>
                                                                    <v-list-item-subtitle>
                                                                        <span class="">
                                                                            {{'Entragados: ' + data.item.cireEntregado + ' - Pendientes: '+(data.item.cirePendiente)}}
                                                                        </span>
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="1">
                                                    <v-btn text icon color="error" v-if="i>0" @click="eliminarEntrega(item)">
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-alert text prominent dense type="error" v-if="item.entradas.length == 0 && item.cireId">
                                                    No hay existencias para este medicamento.
                                                </v-alert>
                                                <v-col cols="12" sm="12" md="3" v-if="item.entradas.length > 0 && item.cireId">
                                                    <v-text-field outlined dense required :error="$v.formSalidas.$each[i].mesaFecha.$error" label="Fecha" v-model="item.mesaFecha" readonly></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="7" v-if="item.entradas.length > 0 && item.cireId">
                                                    <v-autocomplete outlined dense required :error="$v.formSalidas.$each[i].meenId.$error" v-model="item.meenId" :items="item.entradas" item-text="meenLote" item-value="meenId" item-color="primary" label="Lote" clearable @change="cambiarCantidad(item)">
                                                        <template v-slot:selection="data">
                                                            <v-list-item-content>
                                                                <v-list-item-title><span class="primary--text">{{data.item.tblMediMedicamento.mediNombre}}</span>
                                                                </v-list-item-title>
                                                                <v-list-item-title><span class="red--text">{{ (data.item.meenFechaVencimiento)?data.item.meenVigencia + ' ' +data.item.meenFechaVencimiento:'FV: NO TIENE'}}</span>
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle>
                                                                    Lote {{ data.item.meenLote}},
                                                                    Laboratorio {{ data.item.meenLaboratorio}},
                                                                    En existencia {{ data.item.meenExistencia}}
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template v-if="(typeof data.item !== 'object')">
                                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                                            </template>
                                                            <template v-else>
                                                                <v-list-item-content>
                                                                    <v-list-item-title><span class="primary--text">{{data.item.tblMediMedicamento.mediNombre}}</span>
                                                                    </v-list-item-title>
                                                                    <v-list-item-title><span class="red--text">{{ (data.item.meenFechaVencimiento)?data.item.meenVigencia:'FV: NO TIENE'}}</span>
                                                                    </v-list-item-title>
                                                                    <v-list-item-subtitle>
                                                                        Lote {{ data.item.meenLote}},
                                                                        Laboratorio {{ data.item.meenLaboratorio}},
                                                                        En existencia {{ data.item.meenExistencia}}
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>

                                                </v-col>
                                                <v-col cols="12" sm="12" md="2" v-if="item.meenId && item.entradas.length > 0 && item.cireId">
                                                    <v-text-field outlined dense required label="Cantidad entregada" :error="$v.formSalidas.$each[i].mesaCantidadentregada.$error" v-model="item.mesaCantidadentregada" @input="cambiarCantidad(item)"></v-text-field>
                                                </v-col>

                                                <v-col cols="12" sm="12" md="4" v-if="item.meenId && item.entradas.length > 0 && item.cireId">
                                                    <v-text-field outlined dense required label="Coopago" :error="$v.formSalidas.$each[i].mesaCoopago.$error" v-model="item.mesaCoopago" @input="sumarCopago()"></v-text-field>
                                                </v-col>

                                                <v-col cols="12" sm="12" md="4" v-if="item.meenId && item.entradas.length > 0 && item.cireId">
                                                    <v-text-field outlined dense required label="Direccion de entrega" v-model="item.mereDireccionentrega" v-on:keyup="$data.formSalidas.mereDireccionentrega = $event.target.value.toUpperCase()"></v-text-field>
                                                </v-col>

                                                <v-col cols="12" sm="12" md="4" v-if="item.meenId && item.entradas.length > 0 && item.cireId">
                                                    <v-autocomplete outlined dense required v-model="item.mereTipoEntrega" :error="$v.formSalidas.$each[i].mereTipoEntrega.$error" :items="tipoentregas" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de entrega" clearable>
                                                    </v-autocomplete>
                                                </v-col>

                                            </v-row>

                                        </template>

                                    </v-container>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="agregar">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>

        <v-snackbar v-model="notificacion.show" color="warnning" :multi-line="true">
            {{ notificacion.mensaje }}
        </v-snackbar>
    </div>
</template>

<script>
import {
  required
}
from 'vuelidate/lib/validators'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
  documentos
}
from "../../utils/documentos";

export default {
  components: {

  },

  data() {
    return {
      menu: false,
      menu2: false,
      tab: "tab-1",
      loadingFactura: false,
      dialog: false,
      isNew: true,
      VERSION: this.$cookies.get("VERSION"),
      tipo: 'CAPITADO',
      url: "cita/facturaciones/",
      user: this.$cookies.get("user"),
      token: this.$cookies.get("token"),

      nameRules: [v => !!v || "Campo requirido"],

      salario: null,
      cita: null,
      bd: [],
      meses: ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"],
      formRecetarios: [],
      form: {

        cifaFecha: new Date().yyyymmdd(),
        cifaMedicoremitente: null,
        cifaCopago: 0,
        cifaCodigoVale: null,
        cocoId: null,
        citaTipoMes: null,
        cifaNumFactura: null,
        citaId: null,
        cifaTipoFinalidad: null,
        cbdpId: null,
        cifaNumeroAutorizacion: null,
        cifaFechareclamo: new Date().yyyymmdd(),
        cifaFacturarecetario: null

      },
      formSalidas: [{
        mesaFecha: null,
        mesaCantidadentregada: null,
        mesaCoopago: null,
        mereDireccionentrega: null,
        mereTipoEntrega: null,
        cireId: null,
        meenId: null,
        entradas: [],
        mesaFacturarecetario: null
      }],

      headers: [{
          text: "Id",
          value: "cifaId"
        }, {
          text: "Fecha",
          value: "cifaFecha"
        },
        {
          text: "Numero de factura",
          value: "cifaNumFactura"
        },
        {
          text: "Numero de autorizacion",
          value: "cifaNumeroAutorizacion"
        }, {
          text: "Co-pago",
          value: "cifaCopago"
        },
        {
          text: "Codigo vale",
          value: "cifaCodigoVale"
        },
        {
          text: "Mes",
          value: "mes.comaNombrelargo"
        }, {
          text: "Base de datos",
          value: "tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].cocoNombre"
        }, {
          text: "Acciones",
          value: "action",
          sortable: false
        }
      ],
      notificacion: {
        show: false,
        mensaje: ''
      },
      lista: [],
      listaCopia: [],
      tipomes: [],

      tipoestado: [],
      datosdian: [],
      contratos: [],
      tipoentregas: [],
      entradas: [],
      medicinas: [],
      isNewRecetario: true

    };
  },
  validations: {
    formRecetarios: {
      $each: {
        cireFrecuenciahoras: {
          required,
        },

        cireDuraciondias: {
          required,
        },

        cireCantidadprescrita: {
          required,
        },
        mediId: {
          required,
        },
      }
    },
    form: {
      cifaFecha: {
        required,
      },

      cifaCopago: {
        required,
      },
      cifaNumFactura: {
        required,
      },

      citaTipoMes: {
        required,
      },

    },
    formSalidas: {
      $each: {
        mesaFecha: {
          required,
        },
        mesaCantidadentregada: {
          required,
        },
        mesaCoopago: {
          required,
        },
        mereTipoEntrega: {
          required,
        },
        cireId: {
          required,
        },
        meenId: {
          required,
        },
      }

    },

  },

  computed: {

    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created: async function() {
    this.$store.commit('setCargaDatos', true);
    if (this.$route.params.cita) {
      this.cita = this.$route.params.cita;
      this.listaCopia = this.lista = await this.$apiService.index(this.url + "index/" + ((this.cita.citaTipo == 4) ? this.cita.citaId : this.cita.tblCitaFacturaciones[0].cifaId + '?tipo=NORMAL')).then(data => data);
      this.tipoentregas = await this.$apiService.index("sistema/maestra/index/TBL_TIPOENTREGAS").then(data => data);
      this.medicinas = await this.$apiService.index("sistema/medicinas/index").then(data => data);
      this.medicinas = this.medicinas.filter(e => e.mediActivo != 0);
      this.contratos = await this.$apiService.index("sistema/contratos/index").then(data => data);
      this.tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_HISTORIAS").then(data => data);
      this.tipomes = await this.$apiService.index("sistema/maestra/index/TBL_TIPOMES").then(data => data);
      this.$apiService.index("cita/basedatospacientes/index/" + this.$route.params.cita.coppId + "/" + new Date().yyyymmdd()).then(data => {
        if (data.length > 0) {
          this.bd = data;
        } else {
          this.bd = [];
        }

      });
      this.formRecetarios = await this.$apiService.index("cita/asistenciales/atender/index/" + ((this.cita.citaTipo == 4) ? this.cita.citaId : this.cita.tblCitaFacturaciones[0].cifaId) + "/recetario" + ((this.cita.citaTipo == 4) ? '?tipo=CITA' : '')).then(data => data);

      if (this.formRecetarios.length > 0) {
        this.isNewRecetario = false;
      } else {
        this.isNewRecetario = true;
        //this.agregarRecetario();
      }

      this.form.cifaFecha = this.currentDate(new Date());
      this.form.citaTipoMes = this.tipomes.filter(e => e.comaNombrelargo.indexOf(this.meses[new Date(this.cita.citaFecha.replace(/-+/g, '/')).getMonth()]) != -1)[0].comaId;

    }
    this.$store.commit('setCargaDatos', false);
    this.limpiar();
  },

  methods: {
    sumarCopago() {
      let suma = 0;
      this.formSalidas.forEach((salida) => {
        suma = suma + parseFloat(salida.mesaCoopago);

      });
      this.form.cifaCopago = suma;
    },
    async cargar(item) {
      let recetario = (this.cita.citaTipo == 4) ? this.cita.tblCitaRecetarios.find(e => e.cirePendiente > 0 && e.cireId == item.cireId) : this.cita.tblCitaFacturaciones[0].tblCitaRecetarios.find(e => e.cirePendiente > 0 && e.cireId == item.cireId)

      item.entradas = await this.$apiService.index("sistema/medicinas/entradas/index/" + recetario.mediId).then(data => data);
      item.entradas = item.entradas.filter(e => /*e.meenVigencia != 'VENCIDO' && */ e.meenExistencia > 0);

    },
    cambiarCantidad(item) {
      let recetario = (this.cita.citaTipo == 4) ? this.cita.tblCitaRecetarios.find(e => e.cirePendiente > 0 && e.cireId == item.cireId) : this.cita.tblCitaFacturaciones[0].tblCitaRecetarios.find(e => e.cirePendiente > 0 && e.cireId == item.cireId)
      let actual = recetario.cirePendiente;
      if (item.mesaCantidadentregada != null) {
        if (item.meenId) {
          let existencia = item.entradas.find(e => e.meenId == item.meenId).meenExistencia;

          if (item.mesaCantidadentregada > existencia) {
            item.mesaCantidadentregada = existencia;
            this.notificacion.mensaje = "cantidad excedida,  de la existencia en lote";
            this.notificacion.show = true;
          }

        } else {
          this.notificacion.mensaje = "escoja un lote primero";
          this.notificacion.show = true;
        }
      } else {
        if (item.meenId) {
          let existencia = item.entradas.find(e => e.meenId == item.meenId).meenExistencia;
          if (actual < existencia) {
            item.mesaCantidadentregada = actual;
            //this.actual = 0;
          } else {
            item.mesaCantidadentregada = existencia;

          }

        }
      }
      /*  let suma = 0;
        for (let i = 0; i < this.formSalidas.length; i++) {
            if (this.formSalidas[i].mesaCantidadentregada != null) {
                suma = suma + this.formSalidas[i].mesaCantidadentregada;
            }

        }
        this.actual = this.cantidad - (this.cantidadPendiente + suma);*/
    },
    calcularCantidad(items) {
      items.cireCantidadprescrita = (parseInt(items.cireDuraciondias) * 24) / parseInt(items.cireFrecuenciahoras);
    },
    allowedDates: val => new Date(val.replace(/-+/g, '/')).getDay() != 0 && new Date(val.replace(/-+/g, '/')).getDay() != 6,
    currentDate(date) {
      const fecha = date;
      if (this.allowedDates(fecha.yyyymmdd())) {
        return fecha.yyyymmdd()
      } else {
        fecha.setDate(fecha.getDate() + 1);
        return this.currentDate(fecha);

      }
    },

    async agregar() {
      this.$v.formSalidas.$touch();
      if (!this.$v.formSalidas.$error) {
        let valido = true;
        let recetarios = (this.cita.citaTipo == 4) ? this.cita.tblCitaRecetarios.filter(e => e.cirePendiente > 0) : this.cita.tblCitaFacturaciones[0].tblCitaRecetarios.filter(e => e.cirePendiente > 0);
        recetarios.forEach((recetario) => {
          let suma = 0;
          this.formSalidas.forEach((salida) => {

            if (recetario.cireId == salida.cireId) {
              suma = suma + salida.mesaCantidadentregada;
            }

          });
          if (recetario.cirePendiente < (suma)) {
            valido = false;
            this.notificacion.mensaje = "cantidad excedida para el medicamento: " + recetario.tblMediMedicamento.mediNombre;
            this.notificacion.show = true;
          }
        });
        if (valido) {
          this.loadingFactura = true;
          try {
            if (this.cita.citaTipo == 0) {
              this.form.cifaFacturarecetario = this.cita.tblCitaFacturaciones[0].cifaId;
            } else {
              this.form.citaId = this.cita.citaId;
            }
            if (this.isNew) {

              this.form.cifaTipoEstado = this.tipoestado.filter(e => e.comaNombrelargo == 'HISTORIA ABIERTA PERMITE EDITAR')[0].comaId;
              this.form.citaTipoMes = this.tipomes.filter(e => e.comaNombrelargo.indexOf(this.meses[new Date(this.cita.citaFecha.replace(/-+/g, '/')).getMonth()]) != -1)[0].comaId;
              let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
              if (data) {
                this.formSalidas.forEach((salida) => {
                  salida.mesaFacturarecetario = data.cifaId;
                });

                this.$apiService.create("sistema/medicinas/salidas/create", this.formSalidas).then(data => data);
                if (this.cita.citaTipo == 0) {
                  this.cita = await this.$apiService.index("cita/citas/viewentregas/" + this.cita.citaId + '?tipo=NORMAL').then(data => data);
                } else {
                  this.cita = await this.$apiService.index("cita/citas/viewentregas/" + this.cita.citaId).then(data => data);

                }
                this.listaCopia = this.lista.push(data);
                this.$swal.fire({
                  title: "Completado!",
                  text: "Registro exitoso",
                  icon: "success",
                  confirmButtonText: "Ok"
                });
              }
            } else {

              var id = this.form.cifaId;
              let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);
              if (data) {
                this.formSalidas.forEach((salida) => {
                  salida.mesaFacturarecetario = data.cifaId;
                });
                this.$apiService.update("sistema/medicinas/salidas/update/" + id, this.formSalidas).then(data => data);
                if (this.cita.citaTipo == 0) {
                  this.cita = await this.$apiService.index("cita/citas/viewentregas/" + this.cita.citaId + '?tipo=NORMAL').then(data => data);
                } else {
                  this.cita = await this.$apiService.index("cita/citas/viewentregas/" + this.cita.citaId).then(data => data);

                }
                this.lista = this.lista.filter(e => e.cifaId != id);
                this.listaCopia = this.lista.push(data);

                this.$swal.fire({
                  title: "Completado!",
                  text: "Registro exitoso",
                  icon: "success",
                  confirmButtonText: "Ok"
                });
              }
            }
            this.$apiService.index("cita/basedatospacientes/index/" + this.cita.coppId + "/" + this.cita.citaFecha).then(data => {
              if (data.length > 0) {
                this.bd = data;
              } else {
                this.bd = [];
              }

            });
            this.close();
          } catch (error) {
            this.$swal.fire({
              title: "Algo sucedio!",
              text: "contacta con el administrador",
              icon: "error"
            });
          }
        }
        this.loadingFactura = false;
      }
    },
    async agregarRecetarios() {
      this.$v.formRecetarios.$touch();
      if (!this.$v.formRecetarios.$error) {
        try {
          if (this.isNewRecetario) {
            let data = await this.$apiService.create("cita/asistenciales/atender/create/recetario" + ((this.cita.citaTipo == 4) ? '?tipo=CITA' : ''), this.formRecetarios).then(data => data);
            if (data) {

              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
              this.isNewRecetario = false;
              if (this.cita.citaTipo == 4) {
                this.cita.tblCitaRecetarios = data;
              } else {
                this.cita.tblCitaFacturaciones[0].tblCitaRecetarios = data;
              }
              this.$router.push({
                name: 'Entregafactura',
                params: {
                  cita: this.cita
                }
              });
            }
          } else {
            let data = await this.$apiService.update("cita/asistenciales/atender/update/recetario/" + ((this.cita.citaTipo == 4) ? this.cita.citaId : this.cita.tblCitaFacturaciones[0].cifaId) + ((this.cita.citaTipo == 4) ? '/?tipo=CITA' : ''), this.formRecetarios).then(data => data);
            if (data) {

              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
              if (this.cita.citaTipo == 4) {
                this.cita.tblCitaRecetarios = data;
              } else {
                this.cita.tblCitaFacturaciones[0].tblCitaRecetarios = data;
              }
              this.$router.push({
                name: 'Entregafactura',
                params: {
                  cita: this.cita
                }
              });
            }
          }
        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
      }
    },
    async eliminar(id) {
      try {
        var result = await this.$swal.fire({
          title: "Estas seguro?",
          text: "No podras revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, eliminar!"
        }).then(result => result.value);
        if (result) {
          let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
          if (data) {
            if (this.cita.citaTipo == 0) {
              this.cita = await this.$apiService.index("cita/citas/viewentregas/" + this.cita.citaId + '?tipo=NORMAL').then(data => data);
            } else {
              this.cita = await this.$apiService.index("cita/citas/viewentregas/" + this.cita.citaId).then(data => data);

            }
            this.listaCopia = this.lista = this.lista.filter(
              e => e.cifaId != id
            );

            this.$swal.fire(
              "Eliminado!",
              "ha sido eliminado con exito.",
              "success"
            );
          }
          this.$apiService.index("cita/basedatospacientes/index/" + this.cita.coppId + "/" + this.cita.citaFecha).then(data => {
            if (data.length > 0) {
              this.bd = data;
            } else {
              this.bd = [];
            }

          });
        }

      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async editar(id) {
      try {
          this.form = await this.$apiService.index(this.url + "view/" + id).then(data => data);
          this.isNew = false;
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async Gpdf(id) {
      try {
          var factura = await this.$apiService.index(this.url + "pdf/" + id).then(data => data);
          if (factura) {
            var datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + this.cita.citaId).then(data => data);

            this.datosdian = await this.$apiService.index(`sistema/maestra/index/TBL_DATOSDIAN/${factura.serialId}`).then(data => data);
            let empresas = await this.$apiService.index("sistema/personas/index/empresas").then(data => data);
            var tipoempresa = await this.$apiService.index("sistema/maestra/index/TBL_TIPOEMPRESA").then(data => data);
            var ips = empresas.find(e => e.tblConfPersonasempresas[0].coemPrincipal == 1 && e.tblConfPersonasempresas[0].coemTipoTipoempresa == (tipoempresa.find(a => a.comaNombrecorto == 'IPS').comaId));

            if (typeof window !== 'undefined') {
              let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,ips, this.$cookies.get("ENTIDAD"));
              let pdf = new jsPDF();
              if (this.tipo == 'EVENTO' && parseInt(this.datosdian.find(e => e.comaNombrecorto == 'CANTIDADFACTURAS').comaValor) > 1) {
                let rep = documento.getFactura(pdf, factura, true, datosPaciente, 2, this.datosdian);

                documento.getFactura(rep.pdf, factura, false, datosPaciente, rep.finalY + 10, this.datosdian);
              } else {
                documento.getFactura(pdf, factura, false, datosPaciente, 2, this.datosdian);
              }

            }
          }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    agregarRecetario() {
      this.formRecetarios.push({

        cireFrecuenciahoras: null,
        cireDetalles: null,
        cireDuraciondias: null,

        cireCantidadprescrita: null,
        mediId: null,
        cifaId: ((this.cita.citaTipo == 4) ? null : this.cita.tblCitaFacturaciones[0].cifaId),
        citaId: ((this.cita.citaTipo == 4) ? this.cita.citaId : null)
      });
    },

    eliminarRecetario(item) {
      this.formRecetarios = this.formRecetarios.filter(
        e =>
        this.formRecetarios.indexOf(e) !=
        this.formRecetarios.indexOf(item)
      );
    },
    agregarEntrega() {
      this.formSalidas.push({
        mesaFecha: this.currentDate(new Date()),
        mesaCantidadentregada: null,
        mesaCoopago: null,
        mereDireccionentrega: null,
        mereTipoEntrega: null,
        cireId: null,
        meenId: null,
        entradas: [],
        mesaFacturarecetario: null
      });
    },
    eliminarEntrega(item) {
      this.formSalidas = this.formSalidas.filter(
        e =>
        this.formSalidas.indexOf(e) !=
        this.formSalidas.indexOf(item)
      );
    },
    async limpiar() {
      this.formSalidas = [{
        mesaFecha: this.currentDate(new Date()),
        mesaCantidadentregada: null,
        mesaCoopago: null,
        mereDireccionentrega: null,
        mereTipoEntrega: null,
        cireId: null,
        meenId: null,
        entradas: [],
        mesaFacturarecetario: null
      }];
      this.form = {

        cifaFecha: new Date().yyyymmdd(),
        cifaFechareclamo: new Date().yyyymmdd(),
        cifaCopago: 0,
        cifaCodigoVale: null,
        cifaMedicoremitente: null,
        cifaNumFactura: 0,
        citaTipoMes: null,
        cifaTipoFinalidad: null,
        citaId: this.citaId,
        cifaNumeroAutorizacion: null,
        cifaFacturarecetario: null,
        serialId: null,
        cocoId: this.cita.cocoId,
        cbdpId: this.cita.cbdpId
      };
      /*if (this.cita.cocoId) {
          let serial = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSERIALES").then(data => data);
          serial = serial.filter(e => new Date(e.comaNombrelargo.split(';')[0].replace(/-+/g, '/')).getTime() <= new Date().getTime() && new Date(e.comaNombrelargo.split(';')[1].replace(/-+/g, '/')).getTime() >= new Date().getTime());

          serial.sort((a, b) => parseInt(a.comaValor) - parseInt(b.comaValor));
          this.form.serialId = serial[0].comaId;
          this.datosdian = await this.$apiService.index(`sistema/maestra/index/TBL_DATOSDIAN/${serial[0].comaId}`).then(data => data);
          this.form.cifaNumFactura = this.datosdian.find(e => e.comaNombrecorto == 'PREFIJO_SERIE').comaValor + (parseInt(this.datosdian.find(e => e.comaNombrecorto == 'NUMERO_ACTUAL').comaValor) + 1);

      }*/

    },
    close() {
      this.isNew = true;
      this.dialog = false;
      this.limpiar();
    }
  }
};

</script>
