<style>

</style>

<template>
    <v-container lighten-5>
        <v-data-iterator :items="lista" :page.sync="page" :items-per-page="10" hide-default-footer sort-desc sort-by="coinId" class="elevation-1">
            <template v-slot:header>
                <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                <v-toolbar dark color="primary darken-3" class="mb-1">
                    <v-toolbar-title class="white--text">Insumos</v-toolbar-title>
                    <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true" @click="agregar(null,'insumo')">
                        agregar
                    </v-btn>
                    <v-form @submit.prevent="subida" enctype="multipart/form-data">
                        <v-dialog v-model="dialogmasivo" persistent width="500">
                            <template v-slot:activator="{ on }">
                                <v-btn color="red lighten-2" dark v-on="on">
                                    Carga masiva
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="headline primary darken-3" primary-title>
                                    Cargar excel
                                </v-card-title>
                                <v-card-text>
                                    <v-row v-if="!loader">
                                        <v-col cols="12" sm="12" md="12">
                                            <v-file-input outlined :rules="nameRules" v-model="file" show-size label="Excel" @change="onFileChange"></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-col cols="12" sm="12" md="12" class="text-center">
                                            <span class="headline">CARGANDO INSUMOS....</span>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" class="text-center">
                                            <v-progress-circular :width="7" :size="150" color="primary" indeterminate></v-progress-circular>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-btn color="primary" text target="_blank" :href="plantilla">
                                        Descargar formato
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" v-if="!loader" text @click="subida">
                                        cargar
                                    </v-btn>
                                    <v-btn color="error" @click="dialogmasivo=false">
                                        Salir
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-form>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <div class="flex-grow-1"></div>
                </v-toolbar>
            </template>
            <template v-slot:default="props">
                <v-row align="center" justify="center">
                    <v-col v-for="item in props.items" :key="item.citaId" cols="12" sm="12" md="10" lg="10">
                        <v-card>
                            <v-card-title class="subtitle-1 font-weight-bold">
                                <v-row no-gutters>
                                    <v-col cols="8" sm="8" md="8" lg="8">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="7" md="7" lg="7">
                                                INSUMO <span class="primary--text">
                                                    {{ item.coinNombre }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                STOCK <span class="red--text" dark v-if="item.coinEstadostock== 'AGOTADO'">{{item.coinStock}}</span>
                                                <span class="yellow--text" dark v-else-if="item.coinEstadostock=='PRONTO A AGOTARCE'">{{item.coinStock}}</span>
                                                <span class="green--text" dark v-else>{{item.coinStock}}</span>
                                            </v-col>

                                            <v-col cols="12" sm="7" md="7" lg="7">
                                                CODIGO <span class="primary--text">
                                                    {{item.coinCodigo}}

                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                ESTADO <span class="red--text" dark v-if="item.coinEstadostock== 'AGOTADO'">{{item.coinEstadostock}}</span>
                                                <span class="yellow--text" dark v-else-if="item.coinEstadostock=='PRONTO A AGOTARCE'">{{item.coinEstadostock}}</span>
                                                <span class="green--text" dark v-else>{{item.coinEstadostock}}</span>
                                            </v-col>
                                            <v-col cols="12" sm="7" md="7" lg="7">
                                                <span class="green--text" dark v-if="item.coinActivo">ACTIVO</span>
                                                <span class="red--text" dark v-else>INACTIVO</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="4" sm="4" md="4" lg="4">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined color="indigo" class="mr-2" v-on="on" small :to="{ name: 'Insupanel',params: { id: item.coinId} }">
                                                    <v-icon>mdi-desktop-mac-dashboard</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Ver en panel</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined color="success" class="mr-2" v-on="on" small @click.stop="dialog = true" @click="agregar(item.coinId,'entrada')">
                                                    <v-icon>mdi-briefcase-plus-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Agregar</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined color="info" class="mr-2" v-on="on" small @click.stop="dialog = true" @click="editar(item.coinId)">
                                                    <v-icon>create</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined small color="error" v-on="on" @click="eliminar(item.coinId)">
                                                    <v-icon>delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row no-gutters>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
                <v-row class="mt-2" align="center" justify="center">
                    <v-col cols="10" sm="10" md="10" lg="10">
                        <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                            No se encontro nada.
                        </v-alert>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:footer>
                <v-row class="mt-2 pb-5" align="center" justify="center">
                    <span class="mr-4 grey--text">
                        Pagina {{ page }} de {{ pageCount }}, Total resultados: {{(Count)}}
                    </span>
                    <v-pagination circle v-model="page" @input="buscar(filtros)" @next="buscar(filtros)" @previous="buscar(filtros)" :total-visible="10" :length="pageCount"></v-pagination>
                </v-row>
            </template>
        </v-data-iterator>
        <v-form @submit.prevent="enviar">
            <v-dialog v-model="dialog" width="800px" max-width="800px">
                <v-card v-if="tipo=='insumo'">
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="8">
                                    <v-text-field outlined required label="Nombre" v-model="form.coinNombre" v-on:keyup="$data.form.coinNombre = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined required label="Stock" v-model="form.coinStock" v-on:keyup="$data.form.coinStock = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined required label="Codigo" v-model="form.coinCodigo" v-on:keyup="$data.form.coinCodigo = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-switch v-model="form.coinActivo" label="Activo"></v-switch>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="enviar">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
                <v-card v-if="tipo=='entrada'">
                    <v-card-title>
                        <span class="headline">Entrada</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="6">
                                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field required outlined :error="$v.formEntradas.inenFecha.$error" v-model="formEntradas.inenFecha" label="Fecha" append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker locale="ES" v-model="formEntradas.inenFecha" @input="menu = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field required outlined :error="$v.formEntradas.inenFechaVencimiento.$error" v-model="formEntradas.inenFechaVencimiento" label="Fecha Vencimiento" append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker locale="ES" v-model="formEntradas.inenFechaVencimiento" @input="menu2 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field outlined required label="Proveedor" v-model="form.inenProveedor" v-on:keyup="$data.form.inenProveedor = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field outlined required label="Lote" v-model="formEntradas.inenLote" v-on:keyup="$data.formEntradas.inenLote = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field outlined type="number" required label="Cantidad" v-model="formEntradas.inenCantidad" v-on:keyup="$data.formEntradas.inenCantidad = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field outlined required label="Existencia" v-model="formEntradas.inenExistencia" v-on:keyup="$data.formEntradas.inenExistencia = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider>
                    </v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="enviar">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>

    </v-container>
</template>
<script>
import plantilla from '@/assets/files/plantilla_insumo.xlsx';
import {
  required
}
from 'vuelidate/lib/validators';
export default {
  components: {

  },
  data() {
    return {
      plantilla: null,
      dialogmasivo: false,
      dialog: false,
      loader: false,
      tipo: "insumo",
      menu: false,
      menu2: false,
      page: 1,
      pageCount: 0,
      Count: 0,
      file: null,
      isNew: true,
      url: "sistema/insumos/",
      formEntradas: {
        inenId: null,
        inenFecha: null,
        inenFechaVencimiento: null,
        inenLote: null,
        inenCantidad: null,
        inenExistencia: null,
        inenProveedor: null,
      },
      form: {
        coinId: null,
        coinNombre: null,
        coinStock: null,
        coinCodigo: null,
        coinActivo: 1,
      },
      filtros: [{
        text: "Nombre",
        value: null,
        tipo: "text",
        search: true,
        isLoading: false,
        /*si es tipo lista descomentar esta parte*/
        /* lista: [],
        listatext: "nombre del campo que se mostrara",
        listavalue: "nombre del valor que se mandara"*/
        //para select y items personalizados
        /*  default: true,
        defaulttext: "tblConfPersonasnaturales",
        defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
        defaultsubtext: "copeIdentificacion",*/
      },  {
        text: "Codigo",
        value: null,
        tipo: "text",
        search: true,
        isLoading: false,
        /*si es tipo lista descomentar esta parte*/
        /* lista: [],
        listatext: "nombre del campo que se mostrara",
        listavalue: "nombre del valor que se mandara"*/
        //para select y items personalizados
        /*  default: true,
        defaulttext: "tblConfPersonasnaturales",
        defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
        defaultsubtext: "copeIdentificacion",*/
      }],
      lista: [],
      listaCopia: [],
      tipovia: [],
      tipopresentacion: [],
      tipounidad: [],
      tipoentregas: [],
    };
  },
  validations: {
    formEntradas: {
      inenFecha: {
        required,
      },
      inenFechaVencimiento: {
        required,
      },
      inenLote: {
        required,
      },
      inenCantidad: {
        required,
      },
      inenExistencia: {
        required,
      },
    },
    form: {
      coinNombre: {
        required,
      },
      coinStock: {
        required,
      },
    },
  },
  computed: {
    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created: async function() {
    this.plantilla = plantilla;
    await this.buscar(this.filtros)
    this.limpiar();
  },

  methods: {
    onFileChange(file) {
      this.file = file;
    },
    async searchMedicamento(searchP) {
      this.$store.commit('setCargaDatos', true);
      const data = await this.$apiService.index(`${this.url}buscar?${searchP}`).then(data => data);
      if (data) {
        this.lista = this.listaCopia = data.rows;
        this.Count = data.count;
        this.pageCount = Math.ceil((data.count) / 10);
      }
      this.$store.commit('setCargaDatos', false);
    },

    async buscar(filtros) {
      this.filtros = filtros;
      this.lista = this.listaCopia;
      var search = `offset=${(this.page-1)*10}&`;
      if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
        search = search + `coinNombre=${this.filtros[0].value}&`;
      }
      if (this.filtros[1].value != null && this.filtros[1].value.toString().replace(/ /g, "") != "") {
        search = search + `coinCodigo=${this.filtros[1].value}&`;
      }
      if (search != "") {
        await this.searchMedicamento(search);
      }

    },
    async subida() {
      try {
        if (this.file != null) {
          let formData = new FormData();
          formData.append('file', this.file);
          this.loader = true;
          let data = await this.$apiService.createFile(this.url + "masiva", formData).then(data => data);
          if (data) {
            this.loader = false;
            this.file = null
            await this.buscar(this.filtros);
            this.$swal.fire({
              title: "Completado!",
              text: "Subida exitosa",
              icon: "success",
              confirmButtonText: "Ok"
            });
          }

        } else {
          this.loader = false;
          this.$swal.fire({
            title: "Cuidado!",
            text: "Selecciona un archivo primero",
            type: "warnning",
            confirmButtonText: "Ok"
          });
        }

      } catch (error) {
        this.loader = false;
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
      this.dialogmasivo = false;

    },
    agregar(med, tipo) {
      switch (tipo) {
        case 'insumo':
          this.tipo = tipo;
          break;
        case 'entrada':
          this.formEntradas.coinId = med;
          this.tipo = tipo;
          break;
      }
    },
    async enviar() {
      if (this.tipo == 'insumo') {
        this.$v.form.$touch();
        if (!this.$v.form.$error) {
          try {
            if (this.isNew) {
              let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
              if (data) {
                await this.buscar(this.filtros);
                this.$swal.fire({
                  title: "Completado!",
                  text: "Registro exitoso",
                  icon: "success",
                  confirmButtonText: "Ok"
                });
              }
            } else {
              var id = this.form.coinId;
              let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);
              if (data) {
                await this.buscar(this.filtros);
                this.$swal.fire({
                  title: "Completado!",
                  text: "Registro exitoso",
                  icon: "success",
                  confirmButtonText: "Ok"
                });
              }
            }
          } catch (error) {
            this.$swal.fire({
              title: "Algo sucedio!",
              text: "contacta con el administrador",
              icon: "error"
            });
          }
        }
      }
      if (this.tipo == 'entrada') {
        this.$v.formEntradas.$touch();
        if (!this.$v.formEntradas.$error) {
          try {
            let data = await this.$apiService.create(this.url + "entradas/create", this.formEntradas).then(data => data);
            if (data) {
              await this.buscar(this.filtros);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          } catch (error) {
            this.$swal.fire({
              title: "Algo sucedio!",
              text: "contacta con el administrador",
              icon: "error"
            });
          }

        }
      }
      this.close();
    },
    async eliminar(id) {
      try {
        var result = await this.$swal.fire({
          title: "Estas seguro?",
          text: "No podras revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, eliminar!"
        }).then(result => result.value);
        if (result) {
          let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
          if (data) {
            await this.buscar(this.filtros);
            this.$swal.fire(
              "Eliminado!",
              "ha sido eliminado con exito.",
              "success"
            );
          }
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async editar(id) {
      try {
        this.form = await this.$apiService.index(this.url + "view/" + id).then(data => data);
        this.isNew = false;
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },
    limpiar() {
      this.form = {
        coinId: null,
        coinNombre: null,
        coinStock: null,
        coinCodigo: null,
        entradas: [],
        salidas: [],
        coinActivo: 1,
      };
      this.formEntradas = {
        inenId: null,
        inenFecha: null,
        inenFechaVencimiento: null,
        inenLote: null,
        inenCantidad: null,
        inenExistencia: null,
        inenProveedor: null,
        coinId: null
      };
    },
    close() {
      this.isNew = true;
      this.dialog = false;
      this.limpiar();
    }
  }
};
</script>
