<style>
</style>
<template>
<div>
    <v-tabs v-model="tab" background-color="grey darken-3 accent-4" centered dark icons-and-text v-if="!coppId">
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-1">
            Atenciones para hoy
            <v-icon>mdi-calendar-account</v-icon>
        </v-tab>
        <v-tab href="#tab-2">
            Historico
            <v-icon>mdi-history</v-icon>
        </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab"  style="background:rgb(220, 223, 224);" v-if="!coppId">
        <v-tab-item value="tab-1">
            <v-container lighten-5>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <span class="mx-2 secondary--text display-2 float-left">Atenciones</span>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-btn class="mx-2 float-right" fab small dark color="accent" @click="verFiltros=!verFiltros">
                            <v-icon dark>filter_list</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="tabla">
                    <v-card elevation="0" class="rounded-xl">
                        <v-data-iterator :items="lista" :page.sync="page" :loading="loadingTable" :item-key="tabla.itemKey" :items-per-page="10" single-expand hide-default-footer :sort-by="tabla.orderBy" :sort-desc="true">
                            <template v-slot:header>
                                <Dfiltros v-if="verFiltros" :onBuscar="search" v-bind:filtros="filtros" />
                                <v-toolbar dark class="mb-1">
                                    <v-form enctype="multipart/form-data" v-if="getPermiso('importarHistorias')">
                                        <v-dialog v-model="dialogMasivo" persistent width="800px" max-width="800px">
                                            <template v-slot:activator="{ on }">
                                                <v-btn color="red lighten-2" dark v-on="on">
                                                    Carga masiva
                                                </v-btn>
                                            </template>
                                            <v-card>
                                                <v-card-title>
                                                    <span class="headline">{{ formTitle }}</span>
                                                </v-card-title>
                                                <v-divider></v-divider>
                                                <v-card-text>
                                                    <v-container>
                                                        <v-row v-if="errores.length > 0">
                                                            <v-col cols="12" sm="12" md="12">
                                                                <span class="blue--text">Errores al guardar las siguientes historias</span><br>
                                                                <strong><span class="red--text" v-for="(item,i) in errores" :key="'ERROR_'+i">{{item.error}}</span> <br></strong>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row v-if="!loadingForm">
                                                            <v-col cols="12" sm="12" md="12">
                                                                <v-autocomplete outlined v-model="cocoId" :items="contratos" item-text="cocoNombre" item-value="cocoId" item-color="primary" label="Contrato" @change="getServicios" clearable>
                                                                    <template v-slot:selection="data">
                                                                        {{data.item.cocoNombre +" - "+data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}
                                                                    </template>
                                                                    <template v-slot:item="data">
                                                                        <template v-if="(typeof data.item !== 'object')">
                                                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                                                        </template>
                                                                        <template v-else>
                                                                            <v-list-item-content>
                                                                                <v-list-item-title>{{ data.item.cocoNombre}}</v-list-item-title>
                                                                                <v-list-item-subtitle> {{data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}</v-list-item-subtitle>
                                                                            </v-list-item-content>
                                                                        </template>
                                                                    </template>
                                                                </v-autocomplete>
                                                            </v-col>
                                                            <v-col cols="12" sm="12" md="12">
                                                                <v-autocomplete outlined  v-model="cocsId" :items="servicioscontratos" item-text="tblConfServicios[0].coseNombre" item-value="cocsId" item-color="primary" label="Servicio" clearable>
                                                                    <template v-slot:selection="data">
                                                                        <v-list-item-content>
                                                                            <v-list-item-title v-html="data.item.tblConfServicios[0].coseNombre"></v-list-item-title>
                                                                            <v-list-item-subtitle class="primary--text" v-html="data.item.tblConfContrato.tblConfPersonasempresas[0].coemNombre"></v-list-item-subtitle>
                                                                            <v-list-item-subtitle v-html="data.item.tblConfCup.cocuNombre"></v-list-item-subtitle>
                                                                            <v-list-item-subtitle v-html="data.item.tipo?data.item.tipo.comaNombrelargo:''"></v-list-item-subtitle>
                                                                        </v-list-item-content>
                                                                    </template>
                                                                    <template v-slot:item="data">
                                                                        <template v-if="(typeof data.item !== 'object')">
                                                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                                                        </template>
                                                                        <template v-else>
                                                                            <v-list-item-content>
                                                                                <v-list-item-title v-html="data.item.tblConfServicios[0].coseNombre"></v-list-item-title>
                                                                                <v-list-item-subtitle class="primary--text" v-html="data.item.tblConfContrato.tblConfPersonasempresas[0].coemNombre"></v-list-item-subtitle>
                                                                                <v-list-item-subtitle v-html="data.item.tblConfCup.cocuNombre"></v-list-item-subtitle>
                                                                                <v-list-item-subtitle v-html="data.item.tipo?data.item.tipo.comaNombrelargo:''"></v-list-item-subtitle>
                                                                            </v-list-item-content>
                                                                        </template>
                                                                    </template>
                                                                </v-autocomplete>
                                                            </v-col>
                                                            <v-col cols="12" sm="12" md="12">
                                                                <v-file-input outlined v-model="file" show-size label="Excel" @change="onFileChange"></v-file-input>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row v-else>
                                                            <v-col cols="12" sm="12" md="12" class="text-center">
                                                                <span class="headline">CARGANDO HISTORIAS....</span>
                                                            </v-col>
                                                            <v-col cols="12" sm="12" md="12" class="text-center">
                                                                <v-progress-linear v-model="porcentaje" color="primary" height="25">
                                                                    <template v-slot:default="{ value }">
                                                                        <strong>{{ Math.ceil(value) }}% de {{total}} historias..</strong>
                                                                    </template>
                                                                </v-progress-linear>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card-text>
                                                <v-divider></v-divider>
                                                <v-card-actions>
                                                    <div class="flex-grow-1"></div>
                                                    <v-btn color="primary darken-1" text v-if="!loadingForm" @click="dialogMasivo=!dialogMasivo">Cancelar</v-btn>
                                                    <v-btn color="primary darken-1" dark v-if="!loadingForm" @click="subida">Guardar</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-form>
                                </v-toolbar>
                            </template>
                            <template v-slot:default="{ items, isExpanded, expand }">
                                <v-container class="pt-0">
                                    <v-row align="center" justify="center" :class="($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'">
                                        <v-col v-for="item in items" :key="item.comaId" cols="12" sm="12" md="10" lg="10">
                                            <v-card elevation="0" class="rounded-xl">
                                                <v-card-title :class="`${($vuetify.breakpoint.mdAndUp)?'subtitle-1':'subtitle-2'}  font-weight-bold`">
                                                    <v-row no-gutters>
                                                        <v-col cols="10" sm="10" md="10" lg="10">
                                                            <v-row no-gutters>
                                                                <template v-for="(p,i) in tabla.primarios">
                                                                    <v-col v-if="p.show(item)" :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                        {{p.titulo}} <span :class="p.class">
                                                                            {{ p.valor(item) }}
                                                                        </span>
                                                                    </v-col>
                                                                </template>
                                                            </v-row>
                                                        </v-col>
                                                        <v-col cols="2" sm="2" md="2" lg="2">
                                                            <v-menu bottom left>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                        <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <v-list>
                                                                    <template v-for="(m, i) in tabla.menu">
                                                                        <template v-if="m.show(item)">
                                                                            <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(item)">
                                                                                <v-list-item-icon>
                                                                                    <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                </v-list-item-icon>
                                                                                <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                            </v-list-item>
                                                                            <v-list-item v-else :key="i" @click="m.action(item)">
                                                                                <v-list-item-icon>
                                                                                    <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                </v-list-item-icon>
                                                                                <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                            </v-list-item>
                                                                        </template>
                                                                    </template>
                                                                </v-list>
                                                            </v-menu>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-title>
                                                <v-divider></v-divider>
                                                <v-card-text>
                                                    <v-row no-gutters>
                                                        <template v-for="(p,i) in tabla.segundarios">
                                                            <v-col v-if="p.show(item)" :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                {{p.titulo}} <span :class="p.class">
                                                                    {{ p.valor(item) }}
                                                                </span>
                                                            </v-col>
                                                        </template>
                                                        <v-col cols="12" sm="12" md="12" lg="12" v-if="tabla.expandible">
                                                            <v-row>
                                                                <v-col cols="2" sm="2" md="1" lg="1">
                                                                    <v-btn color="grey" icon outlined @click="() => expand(item, !isExpanded(item))">
                                                                        <v-icon v-if="!isExpanded(item)">mdi-chevron-down</v-icon>
                                                                        <v-icon v-else>mdi-chevron-up</v-icon>
                                                                    </v-btn>
                                                                </v-col>
                                                                <v-col cols="10" sm="10" md="11" lg="11">
                                                                    <span class="headline primary--text">{{tabla.expandibleTitle(item)}}</span>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                                <template v-if="tabla.expandible">
                                                    <v-divider v-if="isExpanded(item)"></v-divider>
                                                    <v-card-text v-if="isExpanded(item)">
                                                        <v-container>
                                                            <template v-for="items in tabla.expandibleItems(item) ">
                                                                <v-sheet min-height="100%" width="100%" :key="items[tabla.expandibleKey]">
                                                                    <v-row no-gutters :class="`${($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'} mb-5 py-2 px-2`" >
                                                                        <template v-for="(p,i) in tabla.expanItem">
                                                                            <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                                {{p.titulo}} <span :class="p.class">
                                                                                    {{ p.valor(items) }}
                                                                                </span>
                                                                            </v-col>
                                                                        </template>
                                                                        <v-col cols="2" sm="2" md="2" lg="2" v-if="tabla.expanMenu.length > 0">
                                                                            <v-menu bottom left>
                                                                                <template v-slot:activator="{ on, attrs }">
                                                                                    <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                                        <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                                                    </v-btn>
                                                                                </template>
                                                                                <v-list>
                                                                                    <template v-for="(m, i) in tabla.expanMenu">
                                                                                        <template v-if="m.show(items)">
                                                                                            <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(items)">
                                                                                                <v-list-item-icon>
                                                                                                    <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                                </v-list-item-icon>
                                                                                                <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                            </v-list-item>
                                                                                            <v-list-item v-else :key="i" @click="m.action(items)">
                                                                                                <v-list-item-icon>
                                                                                                    <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                                </v-list-item-icon>
                                                                                                <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                            </v-list-item>
                                                                                        </template>
                                                                                    </template>
                                                                                </v-list>
                                                                            </v-menu>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-sheet>
                                                            </template>
                                                        </v-container>
                                                    </v-card-text>
                                                </template>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </template>
                            <template v-slot:no-data>
                                <v-row class="mt-2" align="center" justify="center">
                                    <v-col cols="10" sm="10" md="10" lg="10">
                                        <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                            No se encontro nada.
                                        </v-alert>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:loading>
                                <v-row class="mt-2" align="center" justify="center">
                                    <v-col cols="12" sm="12" md="12" lg="12">
                                        <Loader />
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:footer>
                                <Paginacion v-on:cambiarPagina="page = $event" :onSearch="search" v-bind:propiedades="{filtros,page,pageCount,Count}" />
                            </template>
                        </v-data-iterator>
                    </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-tab-item>
        <v-tab-item value="tab-2">
            <Historico />
        </v-tab-item>
    </v-tabs-items>
     <Historico v-if="coppId" :coppId="coppId" />
    <v-dialog v-model="dialogInasistencia" width="800px" max-width="800px">
        <v-card>
          <v-card-title class="text-h5 green lighten-2">CITA NO ATENDIDA</v-card-title>
          <v-card-text>
              <v-container>
                <v-row>
                <v-col cols="12" sm="12" md="12">
                      <v-textarea outlined  label="Motivos" :readonly="citaMotivoNoate.estado == 'NO_ATENDIDA'" v-model="citaMotivoNoate.citaMotivoNoate" ></v-textarea> 
                 </v-col>
              </v-row>
              </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="citaInasistida()" v-if="citaMotivoNoate.estado != 'NO_ATENDIDA'">guardar</v-btn>
            <v-btn color="primary" text @click="dialogInasistencia = false;citaMotivoNoate={cifaId:null,citaMotivoNoate:null,estado:null}"> Cacelar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</div>
</template>

<script>
import Historico from "./Historico";
import generales from '../../mixins/generales.js';
export default {
    mixins: [generales],
    props:{
        coppId:Number,
    },
    components: {
        Historico
    },
    data() {
        return {
            tab: "tab-1",
            menu: false,
            cancreate: false,
            isLoading: false,
            searchP: null,
            VERSION:this.$cookies.get("ENTIDAD"),
            url: "cita/asistenciales/",
            dialogInasistencia:false,
            //maestra: [],
            empleados: [],
            pacientes: [],
            servicios: [],
            servicios2: [],
            tipojornada: [],
            Peliminar: false,
            tipoturno: [],
            citaMotivoNoate:{
                cifaId:null,
                citaMotivoNoate:null,
                estado:null
            },
            tabla: {
                itemKey: 'citaId',
                orderBy: 'tblCitaFacturaciones[0].cifaFecha',
                expandible: false,
                expandibleKey: 'citaId',
                expandibleTitle: () => { return `` },
                expandibleItems: () => { return [] },
                primarios: [{
                        titulo: 'PACIENTE',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'primary--text',
                        valor: (item) => { return `${item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1} ${item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1}` },
                        show: (item) => { return item ? true : false }
                    }, {
                        titulo: 'FECHA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'green--text',
                        valor: (item) => { return item.citaFecha },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'IDENT.',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'EDAD',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return this.calcularEdad(item.tblConfPersonaspacientes[0].coppFechanacimiento) },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'ESTADO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.tblCitaFacturaciones[0].estado? item.tblCitaFacturaciones[0].estado.comaNombrecorto :  ''},
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'TELEFONO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'DETALLE',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.detalle? item.detalle.comaNombrelargo:''  },
                        show: (item) => { return item.detalle ? true : false }
                    },

                ],
                segundarios: [
                    {
                        titulo: 'SERVICIO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '6',
                            lg: '6'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.tblConfContratosxservicios?item.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre:'' },
                        show: (item) => { return item ? true : false }
                    },
                     {
                        titulo: 'EMPLEADO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '6',
                            lg: '6'
                        },
                        class: 'primary--text',
                        valor: (item) => {
                           return `${item.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 } ${item.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1 } - ${item.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}` 
                        },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'TELEMEDICINA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '3',
                            lg: '3'
                        },
                        class: 'primary--text',
                        valor: (item) => {
                           return `${item.tblCitaFacturaciones[0].cifaTelemedicina?'Si':'No'}` 
                        },
                        show: (item) => { return item ? item.tblCitaFacturaciones[0].cifaTelemedicina : false }
                    },
                     {
                        titulo: 'SALA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.tblCitaFacturaciones[0].sala) ? item.tblCitaFacturaciones[0].sala.comaNombrecorto : '' },
                        show: (item) => { return item.tblCitaFacturaciones[0].sala ? true : false }
                    },
                    {
                        titulo: 'TURNO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.tblCitaFacturaciones[0].cifaTipoTurno) ? item.tblCitaFacturaciones[0].cifaTipoTurno : '' },
                        show: (item) => { return item.tblCitaFacturaciones[0].cifaTipoTurno ? true : false }
                    },
                    {
                        titulo: 'HORA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.citaHora) ? item.citaHora : '' },
                        show: (item) => { return item.citaHora ? true : false }
                    }
                ],
                expanItem: [],
                expanMenu: [],
                menu: [
                    {
                        title: 'Ver',
                        icon: 'mdi-desktop-mac-dashboard',
                        color: 'success',
                        tipeAction: 'link',
                        action: (item) => { return { name: 'Atencionespanel',params: { id: item.citaId} } },
                        show: (item) => { return item ? (item.tblCitaFacturaciones[0].estado.comaNombrecorto != 'NO_ATENDIDA') : false }
                    },
                  {
                        title: 'Tramitar',
                        icon: 'mdi-ballot-outline',
                        color: 'primary',
                        tipeAction: 'link',
                        action: (item) => { return { name: 'Createhistoria',params: { id: item.tblCitaFacturaciones[0].cifaId,servicio:item.tblConfContratosxservicios[0].coseId } } },
                        show: (item) => { return item ? (item.tblCitaFacturaciones[0].estado.comaNombrecorto != 'NO_ATENDIDA' && (this.cancreate || this.user.role.indexOf('ADMIN') > -1 || this.user.role.indexOf('ENFERMERA') > -1)) : false }
                    },
                    {
                        title: 'Triaje',
                        icon: 'mdi-traffic-light-outline',
                        color: 'primary',
                        tipeAction: 'link',
                        action: (item) => { return `/crearhistoria/${item.tblCitaFacturaciones[0].cifaId}/${item.tblConfContratosxservicios[0].coseId}/1` },
                        show: (item) => { return item ? (item.tblCitaFacturaciones[0].estado.comaNombrecorto != 'NO_ATENDIDA' && (this.getPermiso("verTriage") || this.user.role.indexOf('ADMIN') > -1)) : false }
                    }, 
                    {
                        title: 'Asistir sin tramitar',
                        icon: 'mdi-layers-outline',
                        color: 'success',
                        tipeAction: 'funcion',
                        action: (item) => {
                            this.citaAsistida(item);
                        },
                        show: (item) => { return item ? (item.tblCitaFacturaciones[0].estado.comaNombrecorto != 'NO_ATENDIDA' && item.tblCitaFacturaciones[0].estado.comaNombrecorto != 'CERRADA' && (( this.cancreate ) || this.user.role.indexOf('ADMIN') > -1 || this.user.role.indexOf('ENFERMERA') > -1)) : false }
                    },
                    {
                        title: 'Inasistir',
                        icon: 'mdi-layers-off-outline',
                        color: 'error',
                        tipeAction: 'funcion',
                        action: (item) => {
                            this.dialogInasistencia = true;
                            this.citaMotivoNoate.cifaId = item.tblCitaFacturaciones[0].cifaId;
                            this.citaMotivoNoate.estado = item.tblCitaFacturaciones[0].estado.comaNombrecorto;
                            this.citaMotivoNoate.citaMotivoNoate = item.citaMotivoNoate;
                        },
                        show: (item) => { return item ? (item.tblCitaFacturaciones[0].estado.comaNombrecorto != 'CERRADA' && (( this.cancreate ) || this.user.role.indexOf('ADMIN') > -1 || this.user.role.indexOf('ENFERMERA') > -1)) : false }
                    },
                    {
                        title: 'Vaciar',
                        icon: 'delete',
                        color: 'error',
                        tipeAction: 'funcion',
                        action: (item) => { this.eliminar(item.tblCitaFacturaciones[0].cifaId) },
                        show: (item) => { return item ? this.Peliminar : false }
                    },
                ]
            },
            filtros: [{
                    campo:"citaFecha",
                    text: "Fecha",
                    value: null,
                    tipo: "fecha",
                    menu: false,
                    searchNormal: true,
                    isLoading: false,
                    /*si es tipo lista descomentar esta parte*/
                    /* lista: [],
                    listatext: "nombre del campo que se mostrara",
                    listavalue: "nombre del valor que se mandara"*/
                },

                {
                    campo:"citaTipoEstado",
                    text: "Estado",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    searchNormal: true,
                    isLoading: false,
                    lista: [],
                    listatext: "comaNombrelargo",
                    listavalue: "comaId"
                }, {
                    campo:"coppId",
                    text: "Paciente",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    lista: [],
                    listatext: "tblConfPersonasnaturales[0].copnNombre1",
                    listavalue: "tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId",
                    buscar: 'paciente',
                    isLoading: false,
                    search: null,
                    //filter: (item, queryText, itemText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
                    default: true,
                    defaultselect: (data) => {
                        return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
                    },
                    defaultbody: (data) => {
                        return {
                            title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
                            subtitle: data.item.copeIdentificacion
                        }
                    },
                },

                {
                    campo:"coprId",
                    text: "Empleado",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    searchNormal: true,
                    isLoading: false,
                    lista: [],
                    listatext: "tblConfPersonasnaturales[0].copnNombre1",
                    listavalue: "tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId",
                    filter: (item, queryText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
                    default: true,
                    defaultselect: (data) => {
                        return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
                    },
                    defaultbody: (data) => {
                        return {
                            title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
                            subtitle: data.item.copeIdentificacion
                        }
                    },
                }, {
                    campo:"coseId",
                    text: "Servicio",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    lista: [],
                    listatext: "coseNombre",
                    listavalue: "coseId",
                },
            ],
            tipoestado: [],
            tipoestadoH: [],
            cocsId:null,
            porcentaje: 0,
            total: 0,
            interval: {},
            servicioscontratos:[],
            contratos:[],
            cocoId:null
        };
    },
    sockets: {
        sendporcentajehistorias: function(data) {
        this.total = data.total;
        this.porcentaje = data.total > 0 ? ((data.porcentaje / data.total) * 100) : 0;
        },
    },

    computed: {
        formTitle() {
            return this.isNew === true ? "Nuevo" : "Actualizar";
        }
    },
    watch: {
        dialog(val) {
            val || this.close();
        }
    },
    created: async function () {
        if (this.getPermiso( "verHistorias")) {
            this.filtros[1].lista = this.tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_CITAS").then(data => data);
            this.filtros[3].lista = this.empleados = await this.$apiService.index("sistema/personas/index/empleados").then(data => data);
            this.filtros[4].lista = this.servicios = await this.$apiService.index("sistema/servicios/index/").then(data => data);
            this.contratos = await this.$apiService.index("sistema/contratos/index").then(data => data);
            this.contratos = this.contratos.filter(e => new Date(e.cocoFechainicio.replace(/-+/g, '/')).getTime() <= new Date().getTime() && new Date(e.cocoFechafinal.replace(/-+/g, '/')).getTime() >= new Date().getTime());
            await this.search(this.filtros);
        }
        if (this.getPermiso("eliminarHistorias")) {
            this.Peliminar = true;
        }
        if (this.getPermiso("crearHistorias")) {
            this.cancreate = true;
        }
        if (this.getPermiso("editarHistorias")) {
            this.cancreate = true;
        }

    },

    methods: {
        onFileChange(file) {
            this.file = file;
        },
        async search(filtros) {
            this.lista = this.listaCopia = [];
            this.loadingTable = true;
            if (!(this.user.role.indexOf('ADMIN') > -1|| this.user.role.indexOf('Administrador (IPS)') > -1)) {
                var appendLeadingZeroes = (n) => {
                    if (n <= 9) {
                        return "0" + n;
                    }
                    return n
                };
                let utc = new Date().toLocaleDateString();
                let f = utc.split('/')[2] + '-' + appendLeadingZeroes(utc.split('/')[1]) + '-' + appendLeadingZeroes(utc.split('/')[0]);
                filtros[0].value = f;
            }
            if (!(this.user.role.indexOf('ADMIN') > -1 || this.user.role.indexOf('Administrador (IPS)') > -1)) {
                filtros[1].value = this.tipoestado.filter(e=> e.comaNombrecorto == 'FACTURADA')[0].comaId;
            }
            if (!(this.user.role.indexOf('ADMIN') > -1 || this.user.role.indexOf('Administrador (IPS)') > -1 ||( this.$cookies.get("user").role.indexOf('ENFERMERA FULL') > -1) || this.VERSION == 'union')) {
                filtros[3].value = this.user.empleado;
            }
            const data = await this.$apiService.index(`cita/asistenciales/buscar/0?${this.filtrar(filtros,this.page)}`).then(data => data);
            if (data) {
                this.lista = this.listaCopia = data.rows;
                this.Count = data.count;
                this.pageCount = Math.ceil((data.count) / 10);
            }
            this.loadingTable = false;
        },
        async getServicios() {
            this.cocsId = null;
            this.servicioscontratos = [];
            if (this.cocoId) {
                let data = await this.$apiService.index("sistema/contratosxservicios/contrato/" + this.cocoId).then(data => data);
                this.servicioscontratos = data;
            } 
        },
        async subida() {
            try {
                if (this.file != null) {
                    this.errores = [];
                    let formData = new FormData();
                    formData.append("file", this.file);
                    formData.append("cocsId", this.cocsId);
                    formData.append("cocoId", this.cocoId);
                    formData.append("coseId", this.servicioscontratos.find(e => e.cocsId == this.cocsId).coseId);
                    this.loadingForm = true;
                    let data = await this.$apiService.createFile("sistema/historiasfacturaciones/importHistorias", formData).then(data => data);
                    if (data) {
                        this.file = null;
                        await this.search(this.filtros);
                        this.$swal.fire({
                        title: "Completado!",
                        text: "Subida exitosa " + data.errores.length > 0?`con errores: ${data.errores.length}`:'' ,
                        icon: "success",
                        confirmButtonText: "Ok"
                        });
                        if(data.errores.length > 0){
                            this.errores = data.errores
                        }else{
                            this.dialogMasivo = false;
                        }
                        this.loadingForm = false;
                    }
                } else {
                    this.loadingForm = false;
                    this.$swal.fire({
                        title: "Cuidado!",
                        text: "Selecciona un archivo primero",
                        type: "warnning",
                        confirmButtonText: "Ok"
                    });
                }
                
            } catch (error) {
                    this.loadingForm = false;
                    if (error.response && 403 == error.response.status) {
                        this.$router.push('/403');
                    } else {
                        if (error.response && error.response.data.errores) {
                            this.errores = error.response.data.errores;
                            this.$swal.fire({
                                title: "Error en el excel datos invalidos",
                                text: "Por favor corrija los errores en el excel",
                                icon: "error"
                            });
                        } else {
                            console.log(error)
                            this.$swal.fire({
                                title: "Algo sucedio!",
                                text: "contacta con el administrador",
                                icon: "error"
                            });
                        }
                    }
            }
            
        },

        async citaInasistida() {
                try {
                        if (this.cancreate) {
                            let data = await this.$apiService.update("cita/citas/citaInasistida/" + this.citaMotivoNoate.cifaId + "/",{citaMotivoNoate:this.citaMotivoNoate.citaMotivoNoate}).then(data => data);
                            if (data) {
                                if(this.lista.find( e => e.tblCitaFacturaciones[0].cifaId == this.citaMotivoNoate.cifaId )){
                                     this.lista.find( e => e.tblCitaFacturaciones[0].cifaId == this.citaMotivoNoate.cifaId ).tblCitaFacturaciones[0].estado.comaNombrecorto = 'NO_ATENDIDA';
                                     this.lista.find( e => e.tblCitaFacturaciones[0].cifaId == this.citaMotivoNoate.cifaId ).tblCitaFacturaciones[0].estado.comaNombrelargo = 'HISTORIA NO ATENDIDA';
                                     this.listaCopia = this.lista;
                                }
                                this.dialogInasistencia = false;
                                this.$swal.fire({
                                    title: "Completado!",
                                    text: "Registro exitoso",
                                    icon: "success",
                                    confirmButtonText: "Ok"
                                });
                                this.citaMotivoNoate={
                                    cifaId:null,
                                    citaMotivoNoate:null,
                                    estado:null
                                };
                            }
                        }

                } catch (error) {
                    console.log(error);
                    this.$swal.fire({
                        title: "Algo sucedio!",
                        text: "contacta con el administrador",
                        icon: "error"
                    });
                }
        },
        async citaAsistida(item) {
                try {
                        if (this.cancreate) {
                            let data = await this.$apiService.update("cita/citas/citaAsistida/" + item.tblCitaFacturaciones[0].cifaId ).then(data => data);
                            if (data) {
                                await this.search(this.filtros);
                                this.$swal.fire({
                                    title: "Completado!",
                                    text: "Registro exitoso",
                                    icon: "success",
                                    confirmButtonText: "Ok"
                                });
                            }
                        }

                } catch (error) {
                    console.log(error);
                    this.$swal.fire({
                        title: "Algo sucedio!",
                        text: "contacta con el administrador",
                        icon: "error"
                    });
                }
        },
        async eliminar(id) {
            try {
                if (this.getPermiso("eliminarHistorias")) {
                    var result = await this.$swal.fire({
                        title: "Estas seguro?",
                        text: "No podras revertir esto!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Si, eliminar!"
                    }).then(result => result.value);
                    if (result) {
                        let data = await this.$apiService.delete("sistema/historiasfacturaciones/delete/" + id + "/").then(data => data);
                        if (data) {
                            this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/orden");
                            this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/diagnostico");
                            this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/recetario");
                            this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/remision");
                            this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/incapacidadcertificado?tipo=CERTIFICADO");
                            this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/incapacidadcertificado?tipo=INCAPACIDAD");
                            this.listaCopia = this.lista = this.lista.filter(
                                e => e.tblCitaFacturaciones[0].cifaId != id
                            );
                            this.$swal.fire({
                                title: "Eliminado!",
                                text: "ha sido eliminado con exito.",
                                icon: "success"
                            });
                        }

                    }
                }
            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },

     /*  async exportarHistorias(){
            let resultados = await this.$apiService.index("sistema/historiasfacturaciones/generarPdf/" + this.form.citaId).then(data => data);
            if (!resultados.mensaje) {
              let Historia = resultados.Historia;
              let historial = resultados.historial;
              let secciones = resultados.secciones;
              let diagnosticos = resultados.diagnosticos;
              let ordenes = resultados.ordenes;
              let recetarios = resultados.recetarios;
              let remisiones = resultados.remisiones;
              let incapacidades = resultados.incapacidades;
              let certificados = resultados.certificados;
              let dientesplaca = resultados.dientesplaca;
              let dientesodontograma = resultados.dientesodontograma;
              let datosPaciente = resultados.datosPaciente;
          
            }
            this.LoadingExp = true;
      try {
        if (
          this.filtros[2].value != null &&
          this.filtros[2].value.toString().replace(/ /g, "") != ""
        ) {
          let secciones = await this.$apiService.index("sistema/historiasecciones/secciones/" + this.filtros[2].value).then(data => data);
          let causas = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCAUSAEXT").then(data => data);
          let tipodx = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSDIAGNOSTICOS").then(data => data);
          let clasedx = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSCLASEDX").then(data => data);
          let tipoord = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSORDENES").then(data => data);
          let tipoproc = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSPROCEDIMIENTOS").then(data => data);
          for (let item of secciones) {
            let servicios = await this.$apiService.index("sistema/historiasecciones/servicios/" + item.hiseId).then(data => data);
            item.servicios = servicios
          }
          import('@/vendor/Export2Excel').then(excel => {
            let data = [{sheet:"Historias",data:[]},{sheet:"Diagnosticos",data:[]},{sheet:"Ordenes",data:[]},{sheet:"Recetarios",data:[]},{sheet:"Remisiones",data:[]},{sheet:"Tipos",data:[]}];
            let head = [{sheet:"Historias",data:[]},{sheet:"Diagnosticos",data:[]},{sheet:"Ordenes",data:[]},{sheet:"Recetarios",data:[]},{sheet:"Remisiones",data:[]},{sheet:"Tipos",data:[],hidden:true}];
            let multiHeader =[{sheet:"Historias",data:[]}];
            let headerSecciones = ["","","","","","","",""];
            let campos=[];
            let merges = [{sheet:"Historias",data:[]}];
            let hiddens = [];
            let cantidadCols = 7;
            let cantidadRowTipos = 2;
            let mergesList = [];
            let sheets = ["Historias","Diagnosticos","Ordenes","Recetarios","Remisiones","Tipos"];
          
            let dataValidacions = [{sheet:"Historias",data:[]},{sheet:"Diagnosticos",data:[]},{sheet:"Ordenes",data:[]},{sheet:"Recetarios",data:[]},{sheet:"Remisiones",data:[]},{sheet:"Tipos",data:[]}];
            let colsNum = secciones.reduce(
              (accumulator, currentValue) => accumulator + currentValue.campos.length,
              0
            );
            colsNum = colsNum + 7;
            dataValidacions[0].data.push({columnNum:7,lista:[`$H$3:$H$`+(causas.length + 2)],allowBlank:false});
            dataValidacions[1].data.push({columnNum:3,lista:[`$F$2:$F$`+(tipodx.length + 1)],allowBlank:false});
            dataValidacions[1].data.push({columnNum:4,lista:[`$G$2:$G$`+(clasedx.length + 1)],allowBlank:false});
            dataValidacions[2].data.push({columnNum:3,lista:[`$G$2:$G$`+(tipoord.length + 1)],allowBlank:false});
            dataValidacions[2].data.push({columnNum:4,lista:[`$H$2:$H$`+(tipoproc.length + 1)],allowBlank:false});
            hiddens.push({sheet:"Historias",columns:['H'],rows:[]})
            hiddens.push({sheet:"Diagnosticos",columns:['F','G'],rows:[]})
            hiddens.push({sheet:"Ordenes",columns:['G','H'],rows:[]})
            for (let item of secciones) {
              headerSecciones.push(`${item.hiseNombre}(${item.hiseCodigo})`);
              cantidadCols= cantidadCols+1;
              let star = { r: 1, c: cantidadCols+1 };
              let index = 1;
              for (let itemCampo of item.campos) {
                if(index > 1){
                  headerSecciones.push("");
                  cantidadCols= cantidadCols+1;
                }
                let listaValidacion = [];
                if(itemCampo.tipodato && (itemCampo.tipodato.comaNombrecorto== 'LISTA' )){
                  let datosComentarios = itemCampo.hiseValores.split('|');
                   listaValidacion =datosComentarios.map(item => {
                    return item.replace(',',' ').split(';')[1].trim();
                  });
                }else if(itemCampo.tipodato && ( itemCampo.tipodato.comaNombrecorto== 'SI/NO')){
                   listaValidacion = itemCampo.hiseValores.replace(',',' ').replace(';',',').split(',').map(item => item.trim());
                }
                if(listaValidacion.length > 0){
                  data[5].data.push([(cantidadCols+1)]);
                  let inicialc = cantidadRowTipos;
                  for(let lv of listaValidacion){
                    data[5].data.push([lv]);
                  }
                  cantidadRowTipos = cantidadRowTipos + listaValidacion.length;
                  dataValidacions[0].data.push({columnNum:(cantidadCols+1),lista:[`Tipos!$A$${(inicialc + 1)}:$A$${cantidadRowTipos}`],allowBlank:!itemCampo.hiseRequerido == 1}) 
                  cantidadRowTipos = cantidadRowTipos + 1;
                }
                campos.push(`${itemCampo.hiseNombre}${itemCampo.hiseRequerido == 1? '(*)':''}${(itemCampo.tipodato) ? '('+itemCampo.tipodato.comaNombrecorto+')' : ''}(${itemCampo.hiseCodigo})`);
                index++;
              }
              let end = { r: 1, c: cantidadCols+1 };
              let merge = { s: star, e: end };
              mergesList.push(merge);
            }
            let isOdonto = ['ODONTOLOGÍA', 'HIGIENE ORAL', 'ORTODONCIA'];
            if(isOdonto.some(a => this.filtros[2].lista.find(e => e.coseId == this.filtros[2].value).coseNombre.indexOf(a) > -1)){
              sheets.push("Odontograma y control placa");
              head.push({sheet:"Odontograma y control placa",data:["ITEM","DIENTE","CARA","PLACA","CONVENCION","LISTA CONVENCIONES","LISTA NUMERO DIENTES","LISTA NUMERO CARAS"]});
              dataValidacions.push({sheet:"Odontograma y control placa",data:[]});
              dataValidacions[6].data.push({columnNum:2,lista:[`$G$2:$G$51`],allowBlank:false})
              dataValidacions[6].data.push({columnNum:5,lista:[`$F$2:$F$34`],allowBlank:false})
              dataValidacions[6].data.push({columnNum:3,lista:[`$H$2:$H$9`],allowBlank:false})
              data.push({sheet:"Odontograma y control placa",data:[
                ["","","","0","",'EXODONCIA_REALIZADA',"11","parte1"],
                ["","","","0","",'EXODONCIA_INDICADA',"12","parte2"],
                ["","","","0","",'ENDODONCIA_REALIZADA',"13","parte3"],
                ["","","","0","",'ENDODONCIA_INDICADA',"14","parte4"],
                ["","","","0","",'EXODONCIA_QUIRURGICA_IND',"15","parte5"],
                ["","","","0","",'EXODONCIA_SIMPLE_IND',"16","parte6"],
                ["","","","0","",'PROCEDIMIENTO_REALIZADO',"17","parte7"],
                ["","","","0","",'DIENTE_PROVISIONAL_BUEN_ESTADO',"18",""],
                ["","","","0","",'DIENTE_PROVISIONAL_MAL_ESTADO',"21",""],
                ["","","","0","",'DIENTE_SIN_ERUPCIONAR',"22",""],
                ["","","","0","",'ERUPCION_BUEN_ESTADO',"23",""],
                ["","","","0","",'ERUPCION_MAL_ESTADO',"24",""],
                ["","","","0","",'PROTESIS_BUEN_ESTADO',"25",""],
                ["","","","0","",'PROTESIS_MAL_ESTADO',"26",""],
                ["","","","0","",'PROTESIS_FIJA_BUEN_ESTADO',"27",""],
                ["","","","0","",'PROTESIS_FIJA_MAL_ESTADO',"28",""],
                ["","","","0","",'DIENTE_SANO',"31",""],
                ["","","","0","",'CORONA_BUEN_ESTADO',"32",""],
                ["","","","0","",'CORONA_MAL_ESTADO',"33",""],
                ["","","","0","",'NUCLEO_BUEN_ESTADO',"34",""],
                ["","","","0","",'NUCLEO_MAL_ESTADO',"35",""],
                ["","","","0","",'SELLANTE_INDICADO',"36",""],
                ["","","","0","",'SELLANTE_PRESENTE',"37",""],
                ["","","","0","",'EROCION_ARRIBA',"38",""],
                ["","","","0","",'EROCION_DOBLE',"41",""],
                ["","","","0","",'EROCION_ABAJO',"42",""],
                ["","","","0","",'FRACTURADO',"43",""],
                ["","","","0","",'CARIADO',"44",""],
                ["","","","0","",'OBTURADO',"45",""],
                ["","","","0","",'RESINA',"46",""],
                ["","","","0","",'VIDRIO',"47",""],
                ["","","","0","",'TEMPORAL',"48",""],
                ["","","","0","",'RESINA_RECURRENTE',"51",""],
                ["","","","0","",'OBTURADO_RECURRENTE',"52",""],
                ["","","","0","",'',"53",""],
                ["","","","0","",'',"54",""],
                ["","","","0","",'',"55",""],
                ["","","","0","",'',"61",""],
                ["","","","0","",'',"62",""],
                ["","","","0","",'',"63",""],
                ["","","","0","",'',"64",""],
                ["","","","0","",'',"65",""],
                ["","","","0","",'',"71",""],
                ["","","","0","",'',"72",""],
                ["","","","0","",'',"73",""],
                ["","","","0","",'',"74",""],
                ["","","","0","",'',"75",""],
                ["","","","0","",'',"81",""],
                ["","","","0","",'',"82",""],
                ["","","","0","",'',"83",""],
                ["","","","0","",'',"84",""],
                ["","","","0","",'',"85",""]
              ]});
              hiddens.push({sheet:"Odontograma y control placa",columns:['H','F','G'],rows:[]})
            }
            multiHeader[0].data = [headerSecciones];
            head[0].data =["ITEM","PROFECIONAL","PACIENTE","FECHA","HORA ATENCION","HORA FIN ATENCION","CAUSA EXTERNA","TIPOS CAUSA EXTERNA",...campos];
            head[1].data = ["ITEM","CIE10","TIPO","CLASE","OBSERVACION","TIPOS DX","CLASE DX"];
            head[2].data = ["ITEM","CUPS","TIPO DE ORDEN","TIPO DE PROCEDIMIENTO","CANTIDAD","OBSERVACIONES","TIPOS ORDEN","TIPOS PROC"];
            head[3].data = ["ITEM","MEDICAMENTO","FRECUENCIA DE HORAS","DIAS","DOSIS","CANTIDAD","DETALLE"];
            head[4].data = ["ITEM","CUPS","OBSERVACIONES"];
            head[5].data = ["TIPOS"]
            merges[0].data = mergesList;
            for (let i = 0; i <= 50; i++) {
              data[0].data.push(new Array(colsNum)); 
              for (let y = 0; y <= colsNum; y++) {
                if(y==7 && causas[i]){
                  data[0].data[i][y]=causas[i].comaNombrelargo;
                }else{
                  data[0].data[i][y]="";
                }
              }
              data[1].data.push(new Array(7)); 
              for (let y = 0; y <= 7; y++) {
                if(y==5 && tipodx[i]){
                  data[1].data[i][y]=tipodx[i].comaNombrelargo;
                }else if(y==6 && clasedx[i]){
                  data[1].data[i][y]=clasedx[i].comaNombrelargo;
                }else {
                  data[1].data[i][y]="";
                }
              }
              data[2].data.push(new Array(8)); 
              for (let y = 0; y < 8; y++) {
                if(y==6 && tipoord[i]){
                  data[2].data[i][y]=tipoord[i].comaNombrelargo;
                }else if(y==7 && tipoproc[i]){
                  data[2].data[i][y]=tipoproc[i].comaNombrelargo;
                }else{
                  data[2].data[i][y]="";
                }
              }
            }
            excel.export_json_to_excel({
              multiHeader:multiHeader,
              header: head, //Header Required
              data, //Specific data Required
              filename: this.filtros[2].lista.find(e => e.coseId == this.filtros[2].value).coseNombre + '-plantilla-' + new Date().toLocaleString(), //Optional
              autoWidth: true, //Optional
              bookType: 'xlsx', //Optional
              merges:merges,
              dataValidations:dataValidacions,
              sheets,
              hiddens
            })
          });
        } else {
          this.$swal.fire({
            title: "Escoja un servicio!",
            text: "Debe filtrar por servicio primero.",
            type: "info"
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
      this.LoadingExp = false;
        }*/

    }
};
</script>
