<template>
    <div>
        <v-tabs v-model="tab" background-color="grey darken-3 accent-4" centered dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1">
                Internas
                <v-icon>mdi-beaker-check</v-icon>
            </v-tab>
            <v-tab href="#tab-2">
                Externas
                <v-icon>mdi-beaker-plus</v-icon>
            </v-tab>

            <!-- <v-tab href="#tab-5" v-if="permisos.find(e=>e == 'verMedicamentos')">
            Medicamentos
            <v-icon>mdi-pill</v-icon>
        </v-tab> -->

        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
                <Medicamentos />
            </v-tab-item>
            <v-tab-item value="tab-2">
                <Medicamentos2 />
            </v-tab-item>

        </v-tabs-items>

    </div>
</template>

<script>
import Medicamentos from "./Medicamentos";
import Medicamentos2 from "./Medicamentos2";

export default {
  components: {
    Medicamentos,
    Medicamentos2,
    // Medicamentos
  },
  data() {
    return {
      tab: "tab-1",
      apiService: null
    }
  },
  created: async function() {
    if (this.$route.params.tab) {
      this.tab = this.$route.params.tab;
    }
  },
}

</script>

<style>

</style>
