<template>
    <div>
        <v-tabs v-model="tab" background-color="grey darken-3 accent-4" centered dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1" v-if="vistas.find(e=>e.p == 'verHistorias')">
                Atenciones
                <v-icon>mdi-calendar</v-icon>
            </v-tab>
            <v-tab href="#tab-2" v-if="vistas.find(e=>e.p == 'verLaboratorios')">
                Laboratorios
                <v-icon>mdi-format-color-fill</v-icon>
            </v-tab>
            <v-tab href="#tab-3" v-if="vistas.find(e=>e.p == 'verProcedimientos')">
                Procedimientos
                <v-icon>mdi-account-box</v-icon>
            </v-tab>
            <v-tab href="#tab-4" v-if="vistas.find(e=>e.p == 'verOrdenesservicios')">
                Orden de servicios
                <v-icon>mdi-mouse-variant</v-icon>
            </v-tab>
              <v-tab href="#tab-5" v-if="habanexo && vistas.find(e=>e.p == 'verAnexos')">
                  Anexos
                  <v-icon>mdi-folder-file</v-icon>
              </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" style="background:rgb(220, 223, 224);">
            <v-tab-item value="tab-1" v-if="vistas.find(e=>e.p == 'verHistorias')">
                <Atencion :coppId="coppId" />
            </v-tab-item>
            <v-tab-item value="tab-2" v-if="vistas.find(e=>e.p == 'verLaboratorios')">
                <Laboratorio :coppId="coppId" />
            </v-tab-item>
            <v-tab-item value="tab-3" v-if="vistas.find(e=>e.p == 'verProcedimientos')">
                <Procedimiento2 :coppId="coppId"/>
            </v-tab-item>
            <v-tab-item value="tab-4" v-if="vistas.find(e=>e.p == 'verOrdenesservicios')">
                <Ordenservicio :coppId="coppId" />
            </v-tab-item>
            <v-tab-item value="tab-5" v-if="habanexo && vistas.find(e=>e.p == 'verAnexos')">
                <Anexos :copeIdentificacion="copeIdentificacion" :copeId="copeId" />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import Procedimiento2 from "../procedimientos/Procedimiento2.vue";
import Laboratorio from "../laboratorios/Laboratorio.vue";
import Ordenservicio from "../ordenesservicios/Ordenservicio.vue";
//import Medicamentos from "./Medicamentos";
import Atencion from "./Atencion.vue";
import Anexos from "../../components/Anexos.vue";
import generales from '../../mixins/generales.js';
export default {
  props:{
    copeId:Number,
    coppId:Number,
    copeIdentificacion:String
  },
  components: {
    Atencion,
    Procedimiento2,
    Laboratorio,
    Ordenservicio,
    Anexos
    // Medicamentos
  },
  mixins: [generales],
  data() {
    return {
      tab: "tab-1",
      vistas: [],
      apiService: null,
      habanexo:false
    }
  },
  created: async function() {
    let tipoconfig = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCOFIG").then(data => data);
    if(tipoconfig.some(e => e.comaNombrecorto == 'HAB_ANEXOS')){
     this.habanexo = tipoconfig.find(e => e.comaNombrecorto == 'HAB_ANEXOS').comaValor == 'SI' ? true:false;
    }
      if(this.getPermiso('verAnexos') && this.habanexo)
      this.vistas.push({
        p: 'verAnexos',
        tab: 5
      });
      if(this.getPermiso('verOrdenesservicios'))
      this.vistas.push({
        p: 'verOrdenesservicios',
        tab: 4
      });
      if(this.getPermiso('verProcedimientos'))
      this.vistas.push({
        p: 'verProcedimientos',
        tab: 3
      });
      if(this.getPermiso('verLaboratorios'))
      this.vistas.push({
        p: 'verLaboratorios',
        tab: 2
      });
      if(this.getPermiso('verHistorias'))
      this.vistas.push({
        p: 'verHistorias',
        tab: 1
      });

      if (this.$route.params.tab) {
        this.tab = this.$route.params.tab;
      } else {
        if (this.vistas.length > 0) {
          this.tab = "tab-" + this.vistas[this.vistas.length - 1].tab;
        }
      }
  },

}

</script>

<style>

</style>