<template>
    <div>
        <v-toolbar dense flat class="light-blue darken-4" dark>
            <v-toolbar-title>Panel de  insumos</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn text class="white" color="black" :to="{name:'Insumos'}">
                <v-icon>mdi-chevron-left</v-icon>
                regresar
            </v-btn>
        </v-toolbar>
        <v-tabs v-model="tab" background-color="grey darken-3 accent-4" centered dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1">
                Entradas
                <v-icon>mdi-briefcase-plus-outline</v-icon>
            </v-tab>
            <v-tab href="#tab-2">
                Salidas
                <v-icon>mdi-briefcase-minus-outline</v-icon>
            </v-tab>
        </v-tabs>
        <v-card class="pa-2" outlined tile>
            <v-container>
                <v-row class="caption" no-gutters>
                    <v-col cols="12" sm="12" md="2">
                        <label class="primary--text">NOMBRE: </label>
                        <label class="font-weight-black">{{ form.coinNombre }}</label>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                        <label class="primary--text">STOCK: </label>
                        <label class="font-weight-black">{{ form.coinStock }}</label>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                        <label class="primary--text">CODIGO: </label>
                        <label class="font-weight-black">{{form.coinCodigo}}</label>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
                <insuentradas v-on:aumentar="form.coinStock = $event" v-bind:insumo="form" v-if="form.coinId" />
            </v-tab-item>
            <v-tab-item value="tab-2">
                <insusalidas v-bind:insumo="form" v-if="form.coinId" />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>
<script>
import Insusalidas from "./Insusalidas";
import Insuentradas from "./Insuentradas";
export default {
  components: {
    Insuentradas,
    Insusalidas
  },
  data() {
    return {
      tab: "tab-1",
      user: null,
      url: "sistema/insumos/",
      token: this.$cookies.get("token"),
      form: {
        coinId: null,
        coinNombre: null,
        coinStock: null,
        coinCodigo: null,
      }
    }
  },
  created: async function() {
    if (this.$route.params.id) {
      this.editar(this.$route.params.id);
    }
  },
  methods: {
    async editar(id) {
      try {
        this.form = await this.$apiService.index(this.url + "view/" + id).then(data => data);
        this.isNew = false;
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    limpiar() {
      this.form = {
        coinId: null,
        coinNombre: null,
        coinStock: null,
        coinCodigo: null,
      };
    },
  }
}
</script>
