<template>
<v-container id="cont" lighten-5>
    <v-row>
        <v-col cols="auto" md="12">
            <v-form @submit.prevent="agregar">
                <v-card class="mb-12">
                    <v-card-title>
                        <span class="headline">Procedimento {{$route.params.titulo}}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn color="primary darken-1" dark v-if="e1==1 && pag > 1" @click="atras">atras</v-btn>
                        <v-btn color="primary darken-1" dark v-if="e1==1 && pag < seccionesPaginado.length" @click="siguiente">siguiente</v-btn>
                        <div class="flex-grow-1"></div>
                        <v-btn color="red darken-1" text :to="{ name: 'Panelvacunas', params: { id: $route.params.coppId } }">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark :loading="loading" @click="agregar">Guardar</v-btn>
                    </v-card-actions>

                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container v-if="seccionesvista.length > 0">
                            <v-row v-for="seccion in  seccionesvista" :key="'row-'+seccion.foseId">
                                <template v-if="secciones.length > 0">
                                    <v-col cols="12" sm="12" md="12" class="primary--text">
                                        <h3>{{seccion.foseNombre}}</h3>
                                    </v-col>
                                    <template cols="12" sm="12" md="12" v-for="ca in  campos">
                                        <template v-if="ca.foseId == seccion.foseId">
                                            <v-col cols="12" sm="12" :md="c.foseCol" v-for="c in  ca.campos" :key="'col-'+c.foseId">
                                                <div v-for="(item,index) in  formFormulario" :key="'cam-'+item.foseId">
                                                    <template v-if="c.foseId == item.foseId">

                                                        <div v-if="c.foseRequerido">
                                                            <template v-if="c.tipodato.comaNombrecorto == 'TEXT'">
                                                                <v-text-field :id="c.foseCodigo" outlined v-model="item.ciffValor" :error="$v.formFormulario.$each[index].ciffValor.$error" :label="c.foseNombre" v-on:keyup="item.ciffValor = $event.target.value.toUpperCase()"></v-text-field>
                                                            </template>
                                                            <template v-if="c.tipodato.comaNombrecorto == 'TEXTAREA'">
                                                                <v-textarea :id="c.foseCodigo" outlined v-model="item.ciffValor" :error="$v.formFormulario.$each[index].ciffValor.$error" :label="c.foseNombre" v-on:keyup="item.ciffValor = $event.target.value.toUpperCase()"></v-textarea>
                                                            </template>
                                                            <template v-if="c.tipodato.comaNombrecorto == 'LISTA'">
                                                                <v-autocomplete v-if="c.foseConsulta && listasParametros.length > 0" :id="c.foseCodigo" outlined v-model="item.ciffValor" :error="$v.formFormulario.$each[index].ciffValor.$error" :items="listasParametros.find(e => e.foseId ==c.foseId).lista" item-text="texts" item-value="values" :label="c.foseNombre" item-color="primary" clearable></v-autocomplete>

                                                                <v-autocomplete v-else :id="c.foseCodigo" outlined v-model="item.ciffValor" :error="$v.formFormulario.$each[index].ciffValor.$error" :items="getValues(c.foseValores)" item-text="texts" item-value="values" item-color="primary" :label="c.foseNombre" clearable></v-autocomplete>
                                                            </template>
                                                            <template v-if="c.tipodato.comaNombrecorto == 'SI/NO'">
                                                                <v-switch :id="c.foseCodigo" v-model="item.ciffValor" :label="c.foseNombre" :error="$v.formFormulario.$each[index].ciffValor.$error" :true-value="c.foseValores.split(';')[0]" :false-value="c.foseValores.split(';')[1]"></v-switch>
                                                            </template>

                                                        </div>
                                                        <div v-else>
                                                            <template v-if="c.tipodato.comaNombrecorto == 'TEXT'">
                                                                <v-text-field :id="c.foseCodigo" outlined v-model="item.ciffValor" :label="c.foseNombre" v-on:keyup="item.ciffValor = $event.target.value.toUpperCase()"></v-text-field>
                                                            </template>
                                                            <template v-if="c.tipodato.comaNombrecorto == 'TEXTAREA'">
                                                                <v-textarea :id="c.foseCodigo" outlined v-model="item.ciffValor" :label="c.foseNombre" v-on:keyup="item.ciffValor = $event.target.value.toUpperCase()"></v-textarea>
                                                            </template>
                                                            <template v-if="c.tipodato.comaNombrecorto == 'LISTA'">
                                                                <v-autocomplete v-if="c.foseConsulta && listasParametros.length > 0" :id="c.foseCodigo" outlined v-model="item.ciffValor" :items="listasParametros.find(e => e.foseId ==c.foseId).lista" item-text="texts" item-value="values" :label="c.foseNombre" item-color="primary" clearable></v-autocomplete>

                                                                <v-autocomplete v-else :id="c.foseCodigo" outlined v-model="item.ciffValor" :items="getValues(c.foseValores)" item-text="texts" item-value="values" item-color="primary" :label="c.foseNombre" clearable></v-autocomplete>
                                                            </template>
                                                            <template v-if="c.tipodato.comaNombrecorto == 'SI/NO'">
                                                                <v-switch :id="c.foseCodigo" v-model="item.ciffValor" :label="c.foseNombre" :true-value="c.foseValores.split(';')[0]" :false-value="c.foseValores.split(';')[1]"></v-switch>
                                                            </template>

                                                        </div>

                                                    </template>
                                                </div>
                                            </v-col>

                                        </template>
                                    </template>
                                </template>
                                <div id="scripthistoria"> </div>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-form>
        </v-col>
    </v-row>

</v-container>
</template>

<script>
import postscribe from 'postscribe';
import generales from '../../mixins/generales.js';
import {
    required
}
from 'vuelidate/lib/validators'

export default {
    mixins: [generales],
    data() {
        return {
            e1: 1,
            menu: false,
            listasParametros: [],
            seccionesvista: [],
            seccionesPaginado: [],
            url: "sistema/formulariosfacturaciones/",
            secciones: [],
            campos: [],
            formFormulario: [],
            formFormularioValidado: [],
            script: ''
        };
    },

    validations: {

        formFormularioValidado: {
            $each: {
                ciffValor: {
                    required,
                },
            }
        },
        formFormulario: {
            $each: {
                ciffValor: {
                    required,
                },
            }
        },
    },
    created: async function () {
        

        this.$store.commit('setCargaDatos', true);

        if (this.$route.params.id) {
            var servicio = this.$route.params.servicio;
            try {
                    this.secciones = await this.$apiService.index("sistema/formulariosecciones/secciones/" + servicio + "?PROCEDIMIENTO").then(data => data);

                    this.formFormulario = await this.$apiService.index(this.url + "index/" + this.$route.params.id + "?tipo=factura").then(data => data);
                    if (this.formFormulario.length > 0) {
                        this.isNew = false;
                        for (let formu of this.formFormulario) {
                            formu.updatedBy = this.user.empleado;
                            formu.updatedAt = new Date();
                        }
                    } else {
                        this.isNew = true;
                    }
                    this.secciones.forEach(seccion => {
                        if (seccion.campos.length > 0) {
                            this.campos.push({
                                foseId: seccion.campos[0].foseIdDependencia,
                                campos: seccion.campos
                            });
                            seccion.campos.sort((a, b) => a.foseOrden - b.foseOrden);
                            seccion.campos.forEach(async data => {

                                if (data.foseConsulta) {
                                    let lista = await this.$apiService.create("reporte/reportes/consultar/", { consulta: data.foseConsulta }).then(data => data);
                                    this.listasParametros.push({ foseId: data.foseId, lista: lista });
                                }

                                if (this.formFormulario.filter(e => e.foseId == data.foseId).length == 0) {
                                    this.formFormulario.push({
                                        ciffId: null,

                                        ciffValor: (data.foseDefault) ? data.foseDefault : (data.tipodato.comaNombrecorto == 'SI/NO') ? 'NO' : (data.tipodato.comaNombrecorto == 'TEXTAREA') ? 'NORMAL' : null,

                                        cifaId: this.$route.params.id,

                                        foseId: data.foseId,
                                        createdBy: this.user.empleado
                                    });
                                }
                                if (data.foseRequerido) {
                                    this.formFormularioValidado.push({
                                        ciffValor: null,
                                        foseId: data.foseId
                                    });
                                }

                            });
                        }
                    });

                    let secciones = [];
                    for (let i = 0; i < this.secciones.length; i++) {
                        let seccion = this.secciones[i];

                        if ((i % 4) == 0 && i != 0) {
                            secciones.push(seccion);
                            this.seccionesPaginado.push({ pag: i / 4, secciones: secciones });
                            secciones = [];
                        } else {
                            if ((i + 1) == this.secciones.length) {
                                secciones.push(seccion);
                                this.seccionesPaginado.push({ pag: (this.seccionesPaginado.length + 1), secciones: secciones });
                                secciones = [];
                            } else {
                                secciones.push(seccion);
                            }

                        }
                    }
                    this.seccionesvista = this.seccionesPaginado.filter(e => e.pag == 1)[0].secciones;

            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        }

        this.$store.commit('setCargaDatos', false);

    },

    updated() {

        if (document.getElementById('scripthistoria') && this.secciones.length > 0) {
            if (document.getElementById('scripthistoria').innerHTML.length == 0) {
                let s = '';
                for (let i = 0; i < this.secciones.length; i++) {
                    const data = this.secciones[i].campos;
                    if (this.secciones[i].hiseScript) {
                        s = s + this.secciones[i].hiseScript;
                    }

                    if (data) {

                        for (let j = 0; j < data.length; j++) {
                            if (data[j].hiseScript) {
                                s = s + data[j].hiseScript;
                            }
                        }
                    }

                }
                this.script = `<script>${ s }<${"/"}script>`;
                postscribe('#scripthistoria', this.script);
            }

        }

    },

    methods: {

        atras() {

            if (this.pag != 1) {
                this.pag = this.pag - 1;
                this.seccionesvista = this.seccionesPaginado.find(e => e.pag == this.pag).secciones;

            }

        },
        siguiente() {

            if (this.pag < this.seccionesPaginado.length) {
                this.pag = this.pag + 1;
                this.seccionesvista = this.seccionesPaginado.find(e => e.pag == this.pag).secciones;

            }
        },
        getValues(values) {
            const lista = [];

            var datos = values.split('|');
            for (let i = 0; i < datos.length; i++) {
                let d = datos[i];
                lista.push({ texts: d.split(';')[0], values: d.split(';')[1] });
            }
            return lista;

        },

        async agregar() {
            this.loading = true;
            for (let i = 0; i < this.formFormulario.length; i++) {
                const element = this.formFormulario[i];
                for (let j = 0; j < this.formFormularioValidado.length; j++) {
                    const elementv = this.formFormularioValidado[j];
                    if (element.foseId == elementv.foseId) {
                        elementv.ciffValor = element.ciffValor;
                    }

                }
            }
            this.$v.formFormularioValidado.$touch();
            this.$v.formFormulario.$touch();

            if (!this.$v.formFormularioValidado.$error) {
                try {
                    this.$apiService.update(`cita/asistenciales/atender/cambiarEstadoOrd/${ this.$route.params.id}?tipo=PROC`, { ciorEstado: 'TRAMITADO'},this.token).then(data => data);
                    if (this.isNew) {
                        if (this.getPermiso("crearProcedimientos")) {

                            let data = await this.$apiService.create(this.url + "create?cifaId=" + this.$route.params.id, this.formFormulario).then(data => data);
                            if (data) {
                                this.$router.push('/vacunas/'+this.$route.params.coppId);
                                this.$swal.fire({
                                    title: "Completado!",
                                    text: "Registro exitoso",
                                    icon: "success",
                                    confirmButtonText: "Ok"
                                });
                            }
                        }

                    } else {

                        if (this.getPermiso("editarProcedimientos")) {

                            let data = await this.$apiService.update(this.url + "update/" + this.$route.params.id, this.formFormulario).then(data => data);
                            if (data) {
                                this.$router.push('/vacunas/'+this.$route.params.coppId);
                                this.$swal.fire({
                                    title: "Completado!",
                                    text: "Registro exitoso",
                                    icon: "success",
                                    confirmButtonText: "Ok"
                                });
                            }
                        }

                    }
                } catch (error) {
                    this.loading = false;
                    console.log(error);
                    this.$swal.fire({
                        title: "Algo sucedio!",
                        text: "contacta con el administrador",
                        icon: "error"
                    });
                }
            } else {
                this.$swal.fire({
                    title: "Campos vacios de la historia!",
                    text: "por favor diligenciarlos",
                    icon: "warning"
                });
            }
            this.loading = false;
        },

        limpiar() {
            this.formFormulario = [];
        }
    }
};
</script>

<style scoped>
#scroll-historia {
    max-height: 700px !important;
    overflow: auto;
}
</style>
