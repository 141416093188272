<style>

</style>

<template>
    <v-container lighten-5>
        <v-data-iterator :items="lista" :items-per-page="10" sort-desc sort-by="mediId" class="elevation-1">
            <template v-slot:header>
                <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                <v-toolbar dark color="primary darken-3" class="mb-1">
                    <v-toolbar-title class="white--text">Medicamentos</v-toolbar-title>
                    <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true" @click="agregar(null,'medicamento')">
                        agregar
                    </v-btn>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <div class="flex-grow-1"></div>
                </v-toolbar>

            </template>

            <template v-slot:default="props">
                <v-row align="center" justify="center">
                    <v-col v-for="item in props.items" :key="item.citaId" cols="12" sm="12" md="10" lg="10">
                        <v-card>
                            <v-card-title class="subtitle-1 font-weight-bold">
                                <v-row no-gutters>

                                    <v-col cols="8" sm="8" md="8" lg="8">
                                        <v-row no-gutters>

                                            <v-col cols="12" sm="6" md="6" lg="6">
                                                FECHA MOVIMIENTO <span class="primary--text">
                                                    {{ item.meenFechaMovimiento }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6" lg="6">
                                                FECHA VENCIMIENTO <span class="primary--text">
                                                    {{ item.meenFechaVencimiento }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                EXISTENCIA <span class="red--text" dark v-if="item.meenEstadostock== 'AGOTADO'">{{item.meenExistencia}}</span>
                                                <span class="yellow--text" dark v-else-if="item.meenEstadostock=='PRONTO A AGOTARCE'">{{item.meenExistencia}}</span>
                                                <span class="green--text" dark v-else>{{item.meenExistencia}}</span>
                                            </v-col>

                                            <v-col cols="12" sm="7" md="7" lg="7">
                                                ESTADO STOCK <span class="red--text" dark v-if="item.meenEstadostock== 'AGOTADO'">{{item.meenEstadostock}}</span>
                                                <span class="yellow--text" dark v-else-if="item.meenEstadostock=='PRONTO A AGOTARCE'">{{item.meenEstadostock}}</span>
                                                <span class="green--text" dark v-else>{{item.meenEstadostock}}</span>
                                            </v-col>
                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                VIGENCIA <span class="red--text" dark v-if="item.meenVigencia== 'VENCIDO'">{{item.meenVigencia}}</span>
                                                <span class="yellow--text" dark v-else-if="item.meenVigencia=='PRONTO A VENCER'">{{item.meenVigencia}}</span>
                                                <span class="green--text" dark v-else>{{item.meenVigencia}}</span>
                                            </v-col>

                                        </v-row>
                                    </v-col>
                                    <v-col cols="4" sm="4" md="4" lg="4">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined color="info" class="mr-2" v-on="on" small @click.stop="dialog = true" @click="editar(item.meenId)">
                                                    <v-icon>create</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined small color="error" v-on="on" @click="eliminar(item.meenId)">
                                                    <v-icon>delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>

                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row no-gutters>
                                    <v-col cols="12" sm="3" md="3" lg="3">
                                        LABORATORIO <span class="primary--text">{{item.meenLaboratorio}}</span>

                                    </v-col>
                                    <v-col cols="12" sm="3" md="3" lg="3">
                                        PROVEEDOR <span class="primary--text">
                                            {{item.meenProveedor}}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2" lg="2">
                                        LOTE <span class="primary--text">
                                            {{item.meenLote}}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3" lg="3">
                                        INVIMA <span class="primary--text">
                                            {{item.meenInvima}}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2" lg="2">
                                        CANTIDAD <span class="primary--text">
                                            {{item.meenCantidad}}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2" lg="2">
                                        PRECIO COMPRA <span class="primary--text">
                                            {{item.meenPreciocompra}}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2" lg="2">
                                        PRECIO VENTA <span class="primary--text">
                                            {{item.meenPrecioventa}}
                                        </span>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
                <v-row class="mt-2" align="center" justify="center">
                    <v-col cols="10" sm="10" md="10" lg="10">
                        <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                            No se encontro nada.
                        </v-alert>
                    </v-col>
                </v-row>
            </template>
        </v-data-iterator>
        <v-form @submit.prevent="agregar">
            <v-dialog v-model="dialog" width="800px" max-width="800px">

                <v-card>
                    <v-card-title>
                        <span class="headline">Entrada</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>

                            <v-row>

                                <v-col cols="12" sm="12" md="6">
                                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field required outlined :error="$v.form.meenFechaMovimiento.$error" v-model="form.meenFechaMovimiento" label="Fecha Movimiento" prepend-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker locale="ES" v-model="form.meenFechaMovimiento" @input="menu = false"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" sm="12" md="6">
                                    <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field required outlined :error="$v.form.meenFechaVencimiento.$error" v-model="form.meenFechaVencimiento" label="Fecha Vencimiento" prepend-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker locale="ES" v-model="form.meenFechaVencimiento" @input="menu2 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field outlined required label="Proveedor" v-model="form.meenProveedor" v-on:keyup="$data.form.meenProveedor = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field outlined required label="Laboratorio" v-model="form.meenLaboratorio" v-on:keyup="$data.form.meenLaboratorio = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field outlined required label="Lote" v-model="form.meenLote" v-on:keyup="$data.form.meenLote = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field outlined required label="Invima" v-model="form.meenInvima" v-on:keyup="$data.form.meenInvima = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field outlined type="number" required label="Cantidad" v-model="form.meenCantidad"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field outlined required label="Existencia" v-model="form.meenExistencia" v-on:keyup="$data.form.meenExistencia = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field outlined type="number" required label="Precio compra" v-model="form.meenPreciocompra"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field outlined type="number" required label="Precio venta" v-model="form.meenPrecioventa"></v-text-field>
                                </v-col>

                            </v-row>

                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="agregar">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>

    </v-container>
</template>

<script>
import {
  required
}
from 'vuelidate/lib/validators';
export default {
  components: {

  },
  props: {
    medicamento: Object,
  },
  data() {
    return {
      menu: false,
      menu2: false,
      dialog: false,
      isNew: true,
      url: "sistema/medicinas/entradas/",
      token: this.$cookies.get("token"),

      nameRules: [v => !!v || "Campo requirido"],

      form: {
        meenId: null,
        meenFechaMovimiento: null,
        meenFechaVencimiento: null,
        meenLote: null,
        meenInvima: null,
        meenLaboratorio: null,
        meenCantidad: null,
        meenExistencia: null,
        mediId: null,
        meenProveedor: null,
        meenPreciocompra: 0,
        meenPrecioventa: 0
      },

      filtros: [{
          text: "Fecha Movimiento",
          value: null,
          tipo: "fecha",
          menu: false
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
          //para select y items personalizados
          /*  default: true,
          defaulttext: "tblConfPersonasnaturales",
          defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
          defaultsubtext: "copeIdentificacion",*/
        }, {
          text: "Fecha Vencimiento",
          value: null,
          tipo: "fecha",
          menu: false
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
          //para select y items personalizados
          /*  default: true,
          defaulttext: "tblConfPersonasnaturales",
          defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
          defaultsubtext: "copeIdentificacion",*/
        }, {
          text: "Lote",
          value: null,
          tipo: "text",
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
          //para select y items personalizados
          /*  default: true,
          defaulttext: "tblConfPersonasnaturales",
          defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
          defaultsubtext: "copeIdentificacion",*/
        }, {
          text: "Laboratorio",
          value: null,
          tipo: "text",
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
          //para select y items personalizados
          /*  default: true,
          defaulttext: "tblConfPersonasnaturales",
          defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
          defaultsubtext: "copeIdentificacion",*/
        },
        {
          text: "Vigencia",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [{
            text: 'VIGENTE',
            value: 'VIGENTE'
          }, {
            text: 'PRONTO A VENCER',
            value: 'PRONTO A VENCER'
          }, {
            text: 'VENCIDO',
            value: 'VENCIDO'
          }],
          listatext: "text",
          listavalue: "value"
          //para select y items personalizados
          /*  default: true,
          defaulttext: "tblConfPersonasnaturales",
          defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
          defaultsubtext: "copeIdentificacion",*/
        },
        {
          text: "Estado stock",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [{
            text: 'EN EXISTENCIA',
            value: 'EN EXISTENCIA'
          }, {
            text: 'AGOTADO',
            value: 'AGOTADO'
          }, {
            text: 'PRONTO A AGOTARCE',
            value: 'PRONTO A AGOTARCE'
          }],
          listatext: "text",
          listavalue: "value"
          //para select y items personalizados
          /*  default: true,
          defaulttext: "tblConfPersonasnaturales",
          defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
          defaultsubtext: "copeIdentificacion",*/
        },

      ],

      lista: [],
      listaCopia: [],
    };
  },
  validations: {
    form: {
      meenFechaMovimiento: {
        required,
      },
      meenFechaVencimiento: {
        required,
      },
      meenLote: {
        required,
      },
      meenInvima: {
        required,
      },
      meenLaboratorio: {
        required,
      },
      meenCantidad: {
        required,
      },
      meenExistencia: {
        required,
      },
      meenPreciocompra: {
        required,
      },
      meenPrecioventa: {
        required,
      },
    },

  },
  computed: {
    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    },

  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created: async function() {
    this.$store.commit('setCargaDatos', true);
    this.listaCopia = this.lista = await this.$apiService.index(this.url + "index/" + this.medicamento.mediId).then(data => data);
    this.$store.commit('setCargaDatos', false);
    this.limpiar();
  },

  methods: {

    buscar(filtros) {
      this.filtros = filtros;
      this.lista = this.listaCopia;

      if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
        this.lista = this.lista.filter(
          e => e.meenFechaMovimiento == this.filtros[0].value
        );
      }
      if (this.filtros[1].value != null && this.filtros[1].value.toString().replace(/ /g, "") != "") {
        this.lista = this.lista.filter(
          e => e.meenFechaVencimiento == this.filtros[1].value
        );
      }
      if (this.filtros[2].value != null && this.filtros[2].value.toString().replace(/ /g, "") != "") {
        this.lista = this.lista.filter(
          e => e.meenLote.indexOf(this.filtros[2].value) != -1
        );
      }
      if (this.filtros[3].value != null && this.filtros[3].value.toString().replace(/ /g, "") != "") {
        this.lista = this.lista.filter(
          e => e.meenLaboratorio.indexOf(this.filtros[3].value) != -1
        );
      }
      if (this.filtros[4].value != null && this.filtros[4].value.toString().replace(/ /g, "") != "") {
        this.lista = this.lista.filter(
          e => e.meenVigencia.indexOf(this.filtros[4].value) != -1
        );
      }
      if (this.filtros[5].value != null && this.filtros[5].value.toString().replace(/ /g, "") != "") {
        this.lista = this.lista.filter(
          e => e.meenEstadostock.indexOf(this.filtros[5].value) != -1
        );
      }

    },
    async agregar() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        this.form.mediId = this.medicamento.mediId;
        try {
          if (this.isNew) {
            let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
            if (data) {
              this.listaCopia = this.lista.push(data);
              this.$emit('aumentar', parseInt(this.medicamento.mediStock) + parseInt(data.meenExistencia));
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          } else {
            var id = this.form.meenId;
            let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);
            if (data) {
              var viejo = this.lista.filter(e => e.meenId == id)[0];

              this.$emit('aumentar', (parseInt(this.medicamento.mediStock) - parseInt(viejo['meenExistencia'])) + parseInt(data.meenExistencia));
              this.lista = this.lista.filter(e => e.meenId != id);
              this.listaCopia = this.lista.push(data);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          }
          this.close();
        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }

      }

    },
    async eliminar(id) {
      try {
        var result = await this.$swal.fire({
          title: "Estas seguro?",
          text: "No podras revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, eliminar!"
        }).then(result => result.value);
        if (result) {
          let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
          if (data) {
            var viejo = this.lista.filter(e => e.meenId == id)[0];
            this.$emit('aumentar', (parseInt(this.medicamento.mediStock) - parseInt(viejo['meenCantidad'])));

            this.listaCopia = this.lista = this.lista.filter(
              e => e.meenId != id
            );
            this.$swal.fire(
              "Eliminado!",
              "ha sido eliminado con exito.",
              "success"
            );
          }
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async editar(id) {
      try {
        this.form = await this.$apiService.index(this.url + "view/" + id).then(data => data);
        this.isNew = false;
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },

    limpiar() {

      this.form = {
        meenId: null,
        meenFechaMovimiento: null,
        meenFechaVencimiento: null,
        meenLote: null,
        meenInvima: null,
        meenLaboratorio: null,
        meenCantidad: null,
        meenExistencia: null,
        mediId: null,
        meenPreciocompra: 0,
        meenProveedor: null,
        meenPrecioventa: 0
      };

    },
    close() {
      this.isNew = true;
      this.dialog = false;
      this.limpiar();
    }
  }
};

</script>
