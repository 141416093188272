<style>

</style>

<template>
<div>

    <v-container lighten-5>
        <v-form @submit.prevent="agregar" v-if="$route.params.coppId">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                    <v-col cols="12" sm="12" md="2">
                        <v-switch v-model="tipo" true-value="EVENTO" false-value="CAPITADO" :label="tipo"></v-switch>
                    </v-col>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <template v-if="form.coppId && tipo == 'CAPITADO'">
                            <v-alert text prominent dense type="success" v-if="bd.length > 0">
                                El paciente se encuentra en las base de datos de <template v-for="b in bd"> {{ ','+ b.cocoNombre  }}</template>
                            </v-alert>
                            <v-alert text prominent dense type="error" v-else>
                                El paciente no se encuentra en ninguna base de datos para el mes de {{ meses[ new Date().getMonth()]}}.
                            </v-alert>
                        </template>

                        <v-row v-if="bd.length > 0 || tipo=='EVENTO'">

                            <v-col cols="12" sm="12" md="6" v-if="tipo=='EVENTO'">
                                <v-autocomplete outlined v-model="form.cocoId" :items="contratos" item-text="cocoNombre" item-value="cocoId" item-color="primary" label="Contrato" clearable>
                                    <template v-slot:selection="data">
                                        {{data.item.cocoNombre +" - "+data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}
                                    </template>
                                    <template v-slot:item="data">
                                        <template v-if="(typeof data.item !== 'object')">
                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ data.item.cocoNombre}}</v-list-item-title>
                                                <v-list-item-subtitle> {{data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" v-else-if="bd.length > 0 ">
                                <v-autocomplete outlined v-model="form.cbdpId" :items="bd" auto-select-first item-text="cocoNombre" item-value="cbdpId" item-color="primary" label="EPS contrada" clearable>
                                    <template v-slot:item="data">
                                        <template v-if="(typeof data.item !== 'object')">
                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-content>
                                                <v-list-item-title>{{data.item.cocoNombre}}</v-list-item-title>
                                                <v-list-item-subtitle> {{data.item.coemNombre}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" v-if=" form.coppId ">
                                <v-autocomplete outlined :error="$v.form.coprId.$error" required v-model="form.coprId" :items="empleados" :filter="(item, queryText, itemText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' '+item.tblConfPersonasnaturales[0].copnNombre2 + ' '+item.tblConfPersonasnaturales[0].copnApellido1 + ' '+item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1 " item-text="tblConfPersonasnaturales[0].copnNombre1" item-value="tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId" item-color="primary" label="Encargado del procedimiento" clearable>
                                    <template v-slot:selection="data">
                                        {{data.item.tblConfPersonasnaturales[0].copnNombre1 + " "+data.item.tblConfPersonasnaturales[0].copnNombre2 + " "+data.item.tblConfPersonasnaturales[0].copnApellido1 + " "+data.item.tblConfPersonasnaturales[0].copnApellido2}}
                                    </template>
                                    <template v-slot:item="data">
                                        <template v-if="(typeof data.item !== 'object')">
                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.tblConfPersonasnaturales[0].copnNombre1 + ' '+data.item.tblConfPersonasnaturales[0].copnNombre2 + ' '+data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' +data.item.tblConfPersonasnaturales[0].copnApellido2"></v-list-item-title>
                                                <v-list-item-subtitle v-html="data.item.copeIdentificacion">

                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle v-html="data.item.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].cargo.comaNombrecorto">

                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" sm="12" md="4" v-if="form.coppId ">
                                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field required outlined :error="$v.form.citaFecha.$error" v-model="form.citaFecha" label="Fecha de la cita" prepend-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="ES"  v-model="form.citaFecha" @input="menu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row v-if="bd.length > 0 || tipo=='EVENTO'">
                            <v-col cols="auto" md="12">
                                <v-row justify="space-around">
                                    <v-col cols="auto" md="12">
                                        <v-toolbar flat>
                                            <v-toolbar-title>Procedimientos</v-toolbar-title>
                                            <v-divider class="mx-4" inset vertical></v-divider>
                                            <v-btn text @click="agregarProcedimiento">Agregar nuevo</v-btn>
                                            <v-divider class="mx-4" inset vertical></v-divider>

                                            <div class="flex-grow-1"></div>
                                        </v-toolbar>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <template v-for="(items,index) in cups">
                                    <v-row :key="index" v-if="cups.length > 0">
                                        <v-col cols="12" sm="10" md="10">
                                            <v-autocomplete outlined :error="$v.cups.$each[index].cup.$error" required v-model="items.cup" :loading="items.isLoading" :search-input.sync="items.search" hide-no-data hide-selected no-filter :items="items.cups" @keypress.enter="searchCup(items)" item-text="cocuNombre" item-value="cocuId" item-color="primary" label="Procedimiento" clearable></v-autocomplete>
                                        </v-col>
                                        <v-col cols="auto" md="1" v-if="index>0">
                                            <v-btn text icon color="error" @click="eliminarProcedimiento(items)">
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-col>
                        </v-row>
                        <!-- <v-row v-if="bd.length > 0 || tipo=='EVENTO'"> -->
                        <!-- <v-col cols="12" sm="12" md="4" v-if="tipo=='EVENTO'">
                                <v-text-field outlined required label="Numero de la factura" :error="$v.formFactura.cifaNumFactura.$error" v-model="formFactura.cifaNumFactura" readonly></v-text-field>
                            </v-col> -->
                        <!-- <v-col cols="12" sm="12" md="4">
                                <v-text-field outlined label="Numero de autorizacion" v-model="formFactura.cifaNumeroAutorizacion"></v-text-field>
                            </v-col> -->
                        <!-- <v-col cols="12" sm="12" md="4">
                                <v-text-field outlined label="Codigo vale" v-model="formFactura.cifaCodigoVale"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <v-text-field outlined label="Copago" v-model="formFactura.cifaCopago"></v-text-field>
                            </v-col> -->
                        <!-- <v-col cols="12" sm="12" md="8">
                                <v-autocomplete outlined required v-model="formFactura.cifaTipoFinalidad" :items="tipofinalidad" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de finalidad" clearable></v-autocomplete>
                            </v-col> -->

                        <!-- </v-row> -->
                        <!-- <v-row v-if="bd.length > 0 || tipo=='EVENTO'">
                            <v-col cols="auto" md="12">
                                <v-row justify="space-around">
                                    <v-col cols="auto" md="12">
                                        <v-toolbar flat>
                                            <v-toolbar-title>Diagnosticos</v-toolbar-title>
                                            <v-divider class="mx-4" inset vertical></v-divider>
                                            <v-btn text @click="agregarDiagnostico">Agregar nuevo</v-btn>
                                            <v-divider class="mx-4" inset vertical></v-divider>

                                            <div class="flex-grow-1"></div>
                                        </v-toolbar>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <template v-for="(items,index) in formDiagnosticos">
                                    <v-row :key="index" v-if="formDiagnosticos.length > 0">
                                        <v-col cols="12" sm="12" md="12" v-if="items.tipocie10">
                                            <v-autocomplete outlined required :error="$v.formDiagnosticos.$each[index].cidiTipoCie10.$error" v-model="items.cidiTipoCie10" :loading="items.tipocie10.isLoading" :search-input.sync="items.tipocie10.search" hide-no-data hide-selected no-filter :items="items.tipocie10.tipocie10" @keypress.enter="searchCIE10(items.tipocie10)" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de CIE 10" clearable>
                                                <template v-slot:selection="data">
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.comaNombrelargo"></v-list-item-title>
                                                        <v-list-item-subtitle v-html="data.item.comaNombrecorto"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>

                                                <template v-slot:item="data">
                                                    <template v-if="(typeof data.item !== 'object')">
                                                        <v-list-item-content v-text="data.item"></v-list-item-content>
                                                    </template>
                                                    <template v-else>
                                                        <v-list-item-content>
                                                            <v-list-item-title v-html="data.item.comaNombrelargo"></v-list-item-title>
                                                            <v-list-item-subtitle v-html="data.item.comaNombrecorto"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="6">
                                            <v-autocomplete outlined :error="$v.formDiagnosticos.$each[index].cidiTipoDiagnostico.$error" required v-model="items.cidiTipoDiagnostico" :items="tipodiagnostico" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de Diagnostico" clearable></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <v-autocomplete outlined :error="$v.formDiagnosticos.$each[index].cidiTipoClase.$error" required v-model="items.cidiTipoClase" :items="tipoclasedx" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de Clase" clearable></v-autocomplete>
                                        </v-col>
                                        <v-col cols="auto" md="1" v-if="index>0">
                                            <v-btn text icon color="error" @click="eliminarDiagnostico(items)">
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-col>
                        </v-row> -->
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary darken-1" text to="/vacunacion">Cancelar</v-btn>
                    <v-btn color="primary darken-1" :loading="isLoading" dark @click="agregar" v-if="form.coppId">Atender</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
        <div v-else>
            <v-alert text prominent dense type="error">
                Debe seleccionar un paciente desde el panel de vacunas.
            </v-alert>
        </div>
    </v-container>

</div>
</template>

<script>
import generales from '../../mixins/generales.js';
import {
    required
}
from 'vuelidate/lib/validators';
export default {
    mixins: [generales],
    data() {
        return {
            menu: false,
            tipo: this.$cookies.get("DEFAULT"),
            isLoading: false,
            searchP: null,
            meses: ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"],
            bd: [],
            url: "cita/citas/",
            pacientes: [],
            tipoestado: [],
            tipoestado2: [],
            cups: [{
                search: null,
                isLoading: false,
                cups: [],
                cup: null
            }],
            form: {
                citaFecha: null,
                citaTipoEstado: null,
                citaTipo: 2,
                cbdpId: null,
                coppId: null,
                cocoId: null,
                cocuId: null,
                coprId: null
            },
            formDiagnosticos: [
            ],
            formFactura: {

                cifaFecha: new Date().yyyymmdd(),
                cifaCopago: 0,
                cifaCodigoVale: null,
                cocoId: null,
                citaTipoMes: null,
                cifaNumFactura: 0,
                citaId: null,
                cifaTipoFinalidad: null,
                cbdpId: null,
                cifaNumeroAutorizacion: null,
                cifaTelemedicina: 0,
                cifaFechareclamo: new Date().yyyymmdd(),

            },
            tipofinalidad: [],
            tipocie10: {
                search: null,
                isLoading: false,
                tipocie10: []
            },
            tipodiagnostico: [],
            tipomes: [],
            contratos: [],
            empleados: [],
        };
    },
    validations: {
        formDiagnosticos: {
            $each: {

                cidiTipoCie10: {
                    required,
                },

                cidiTipoDiagnostico: {
                    required,
                },
                cidiTipoClase: {
                    required,
                },

            }
        },
        cups: {
            $each: {

                cup: {
                    required,
                },

            }
        },
        formFactura: {
            cifaFecha: {
                required,
            },

            cifaCopago: {
                required,
            },
            cifaNumFactura: {
                required,
            },

            citaTipoMes: {
                required,
            },

        },
        form: {

            citaFecha: {
                required,
            },

            citaTipoEstado: {
                required,
            },

            coppId: {
                required,
            },
            coprId: {
                required,
            },
        },
    },
    computed: {

        formTitle() {
            return this.isNew === true ? "Nuevo" : "Actualizar";
        }
    },
    watch: {
        dialog(val) {
            val || this.close();
        }
    },
    created: async function () {
            if (this.$route.params.coppId) {
                this.$store.commit('setCargaDatos', true);
                this.tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_CITAS").then(data => data);
                this.tipoestado2 = await this.$apiService.index("sistema/maestra/index/ESTADOS_HISTORIAS").then(data => data);
                this.contratos = await this.$apiService.index("sistema/contratos/index").then(data => data);
                this.tipofinalidad = await this.$apiService.index("sistema/maestra/index/TBL_TIPOFINALIDADPRO").then(data => data);
                this.tipomes = await this.$apiService.index("sistema/maestra/index/TBL_TIPOMES").then(data => data);
                this.tipodiagnostico = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSDIAGNOSTICOS").then(data => data);
                this.tipoclasedx = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSCLASEDX").then(data => data);
                this.bd = await this.$apiService.index("cita/basedatospacientes/index/" + this.$route.params.coppId + "/" + new Date().yyyymmdd()).then(data => data);
                this.empleados = await this.$apiService.index("sistema/personas/index/empleados").then(data => data);
                
                this.empleados = this.empleados.filter(e => e.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].estado.comaNombrecorto == 'ACTIVO');
                if (this.user.role.indexOf('ADMIN') == -1) {
                    this.empleados = this.empleados.filter(e => e.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId == this.user.empleado);
                    this.form.coprId = this.empleados[0].tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId;
                }
                this.form.coppId = this.$route.params.coppId;
                this.form.citaFecha = this.currentDate(new Date());
                this.form.citaTipoEstado = this.tipoestado.find(e => e.comaNombrecorto == 'ACTIVA').comaId;
                this.formFactura.citaTipoMes = this.tipomes.filter(e => e.comaNombrelargo.indexOf(this.meses[new Date().getMonth()]) != -1)[0].comaId;
                this.formFactura.cifaTipoFinalidad = this.tipofinalidad.find(e => e.comaNombrecorto == '3').comaId;
                let edad = this.$route.params.edad;
                if (this.bd.length > 0) {
                    this.form.cbdpId = this.bd[0].cbdpId

                }
                var cie10 = await this.$apiService.index(`sistema/maestra/buscar3/TBL_TIPOSCIE10?comaNombrecorto=${(edad>17)?'Z000':'Z001'}&comaNombrelargo=${(edad>17)?'Z000':'Z001'}`).then(data => data);
                this.formDiagnosticos.push({
                    cidiTipoCie10: cie10[0].comaId,

                    cidiTipoDiagnostico: this.tipodiagnostico.find(e => e.comaNombrecorto == 'IMPRESIÓN_DIAGNOSTICA').comaId,

                    cidiTipoClase: this.tipoclasedx.find(e => e.comaNombrecorto == 'DX PRINCIPAL').comaId,
                })
                this.$store.commit('setCargaDatos', false);
            }
    },

    methods: {
        async searchCup(item) {
            item.isLoading = true;
            if (item.search.length > 0 && item.search.toString().replace(/ /g, "") != "") {
                this.$apiService.index("sistema/cups/buscar/" + item.search.toUpperCase()).then(data => {
                    item.isLoading = false;
                    item.cups = data;

                });
            }

        },
        async searchCIE10(tipocie10) {
            tipocie10.isLoading = true;
            if (tipocie10.search.length > 0 && tipocie10.search.toString().replace(/ /g, "") != "") {
                tipocie10.tipocie10 = await this.$apiService.index(`sistema/maestra/buscar3/TBL_TIPOSCIE10?comaNombrecorto=${tipocie10.search}&comaNombrelargo=${tipocie10.search}`).then(data => data);
            }
            tipocie10.isLoading = false;
        },
        async agregar() {
            this.isLoading = true;
            this.formFactura.cbdpId = this.form.cbdpId;
            this.formFactura.cocoId = this.form.cocoId;
            this.formFactura.cifaFecha = this.form.citaFecha;
            this.$v.form.$touch();
            this.$v.formDiagnosticos.$touch();
            this.$v.formFactura.$touch();
            this.$v.cups.$touch();
            if (!this.$v.form.$error && !this.$v.formFactura.$error && !this.$v.formDiagnosticos.$error && !this.$v.cups.$error) {
                try {
                   
                    if (this.isNew) {
                               
                            for (let cup of this.cups) {
                                this.form.cocuId = cup.cup;

                                let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);

                                if (data) {
                                    this.formFactura.citaId = data.citaId;
                                    this.formFactura.cifaTipoEstado = this.tipoestado2.filter(e => e.comaNombrelargo == 'HISTORIA ABIERTA PERMITE EDITAR')[0].comaId;
                                    let data2 = await this.$apiService.create("cita/facturaciones/create", this.formFactura).then(data => data);
                                    if (data2) {
                                        this.formDiagnosticos.forEach((d) => d.cifaId = data2.cifaId);
                                        this.$apiService.create("cita/asistenciales/atender/create/diagnostico", this.formDiagnosticos).then(data => data);

                                    }

                                }
                            }
                            this.$swal.fire({
                                title: "Completado!",
                                text: "Registro exitoso",
                                icon: "success",
                                confirmButtonText: "Ok"
                            });
                            this.$router.push({ name: 'Panelvacunas', params: { id: this.form.coppId } });
                    }
                    this.close();
                } catch (error) {
                    console.log(error);
                    this.$swal.fire({
                        title: "Algo sucedio!",
                        text: "contacta con el administrador",
                        icon: "error"
                    });
                }

            }
            this.isLoading = false;
        },
        agregarDiagnostico() {
            this.formDiagnosticos.push({

                cidiTipoCie10: null,

                cidiTipoDiagnostico: null,

                cidiTipoClase: null,

                cifaId: null,
                tipocie10: {
                    search: null,
                    isLoading: false,
                    tipocie10: []
                }

            });
        },
        eliminarDiagnostico(item) {
            this.formDiagnosticos = this.formDiagnosticos.filter(
                e =>
                this.formDiagnosticos.indexOf(e) !=
                this.formDiagnosticos.indexOf(item)
            );
        },
        agregarProcedimiento() {
            this.cups.push({
                search: null,
                isLoading: false,
                cups: [],
                cup: null

            });
        },
        eliminarProcedimiento(item) {
            this.cups = this.cups.filter(e => this.cups.indexOf(e) != this.cups.indexOf(item));
        },
        async limpiar() {
            this.form = {

                citaFecha: this.currentDate(new Date()),

                citaTipoEstado: this.tipoestado.find(e => e.comaNombrecorto == 'ACTIVA').comaId,
                citaTipo: 2,
                cbdpId: null,
                coppId: null,
                coprId: null,
                cocuId: null,
                cocoId: null
            };
            this.formFactura = {

                cifaFecha: new Date().yyyymmdd(),
                cifaFechareclamo: new Date().yyyymmdd(),
                cifaCopago: 0,
                cifaCodigoVale: null,
                cifaNumFactura: 0,
                citaTipoMes: this.tipomes.filter(e => e.comaNombrelargo.indexOf(this.meses[new Date().getMonth()]) != -1)[0].comaId,
                cifaTipoFinalidad: null,
                citaId: null,
                cocoId: null,
                cbdpId: null,
                cifaNumeroAutorizacion: null,
                cifaTelemedicina: 0,
            };
            this.formDiagnosticos = [{

                cidiTipoCie10: null,

                cidiTipoDiagnostico: null,

                cidiTipoClase: null,

                cifaId: null,
                tipocie10: {
                    search: null,
                    isLoading: false,
                    tipocie10: []
                }

            }];
        },
        close() {

            this.isNew = true;
            this.limpiar();
        }
    }
};
</script>
